<div *ngFor="let faq of faqList; index as fi">
  <h3 *ngIf="!paeExplaination" class="faq-title" (click)="toggleFaq(fi)" id="{{faq.id}}">{{faq.question}}
    <i *ngIf="!openFaqs.includes(fi)" class="icon icon-common faq-toggle-icon" data-icon="&#xf078;"></i>
    <i *ngIf="openFaqs.includes(fi)" class="icon icon-common faq-toggle-icon" data-icon="&#xf077;"></i>
  </h3>

  <h3 *ngIf="paeExplaination" class="paeTitle" (click)="toggleFaq(fi)" id="{{faq.id}}">{{faq.question}}
    <i *ngIf="!openFaqs.includes(fi)" class="icon icon-common faq-toggle-icon-pae" data-icon="&#xf078;"></i>
    <i *ngIf="openFaqs.includes(fi)" class="icon icon-common faq-toggle-icon-pae" data-icon="&#xf077;"></i>
  </h3>

  <p class="faq-content" [ngClass]="openFaqs.includes(fi) ? 'open-faq' :' '">
    <span class="faq-content-space">&nbsp;</span>
    <span *ngIf="!paeExplaination" [innerHTML]="faq.answer"></span>

    <span *ngIf="paeExplaination" [ngClass]="openFaqs.includes(fi) ? 'open-faq' :' '">
    <div class="faq-pae">
      
      <p>
        The Predicted Aligned Error (PAE) measures the confidence in the relative position of two residues within the predicted structure, providing insight into the reliability of relative position and orientations of different domains. Consider the human protein encoded by the gene <i>GNE</i> (<a [routerLink]="['/entry', 'Q9Y223']"  >Q9Y223</a>).
      </p>
  
      <div class="faq-pae-img mutli-image">
        <span><img src="../../assets/img/pae/Q9Y223.png" style="width: 450px;"></span>
        <span><img class="modal-small-img" src="../../assets/img/pae/Model confidence.png" style="width: 150px;"></span>
      </div>
    
      <p>
        GNE has two distinct domains according to experimentally determined structures in the Protein Data Bank (<a href="https://www.ebi.ac.uk/pdbe/pdbe-kb/proteins/Q9Y223/structures" target="_blank" >PDBe-KB</a>). Does AlphaFold confidently predict their relative positions? We can use the interactive Predicted Aligned Error (PAE) plot to answer this question.
      </p>
      <div class="faq-pae-img"><img src="../../assets/img/pae/Q9Y223 PAE.png" style="width: 300px;"></div>
    
      <p>
        The PAE plot is <b>not</b> an inter-residue distance map or a contact map. Instead, the shade of green indicates the expected distance error in Ångströms (Å), ranging from 0 Å to an arbitrary cut-off of 31 Å. The colour at (x, y) corresponds to the expected distance error in the residue x’s position when the predicted and the true structures are aligned on residue y.
        <br /> <br />
        A dark green tile corresponds to a good prediction (low error), whereas a light green tile indicates  poor prediction (high error). For example, when aligning on residue 300:  <br>
      </p>
      <ul>
        <li>We’re confident in the relative position of residue 200</li>
        <li>We’re not confident in the relative position of residue 600</li>
      </ul>
      
      <div class="faq-pae-img"><img src="../../assets/img/pae/Q9Y223 PAE-2.png" style="width: 300px;"></div>
  
      <p>
        The two low-error, dark green squares correspond to the two domains. By clicking and dragging, you can highlight these squares on the structure. If you want to remove the highlighting, click the cross icon.
      </p>
      <div class="faq-pae-img mutli-image">
        <img src="../../assets/img/pae/Q9Y223 domain.png" style="width: 367px;">
        <img src="../../assets/img/pae/Q9Y223 domain PAE.png" style="width: 321px;">
      </div>

      <p>
        When selecting an off-diagonal region, the plot visually represents the relationship between the selected ranges on the sequence and structure. The x range corresponds to the selection for scored residues, highlighted in orange, while the y range of aligned residues is highlighted in emerald green.
        <br /><br />
        The high PAE values across the whole inter-domain region indicate that for this particular protein, AlphaFold does not reliably predict the relative position of the domains.
      </p>

      <div class="faq-pae-img mutli-image">
        <img src="../../assets/img/pae/Q9Y223 domains colours.png" style="width: 367px;">
        <img src="../../assets/img/pae/Q9Y223 domains colours PAE.png" style="width: 321px;">
      </div>


      <p>Let’s consider another inter-domain example, the human protein encoded by <i>DIP2B</i> (<a href="./entry/Q9P265">Q9P265</a>).
      </p>

      <div class="faq-pae-img mutli-image">
        <img src="../../assets/img/pae/Q9P265.png" style="width: 367px;">
        <span><img class="modal-middle-img" src="../../assets/img/pae/Model confidence.png" style="width: 150px;"></span>
      </div>

      <p>In this case, we have confidence in the relative position of scored residues around 1450 when aligned with residues around 850, suggesting a packing between the small central domains.</p>

      <div class="faq-pae-img mutli-image">
        <img src="../../assets/img/pae/Q9P265 domains.png" style="width: 367px;">
        <img src="../../assets/img/pae/Q9P265 PAE.png" style="width: 321px;">
      </div>
      
      

      <p>Note that the PAE scores are asymmetrical, meaning there might be variations in PAE values between (x,y) and (y,x) positions. This is particularly relevant for loop regions with highly uncertain orientations, as seen on the DNA topoisomerase 3 (<a [routerLink]="['/entry', 'Q8T2T7']"  >Q8T2T7</a>).
      </p>

      <div class="faq-pae-img mutli-image" style="position: relative;">
        <span><img src="../../assets/img/pae/Q8T2T7.png" style="width: 400px;"></span>
        <span><img src="../../assets/img/pae/Q8T2T7 PAE.png" style="width: 334px;"></span>
      </div>

    </div>
    </span>

  <span class="faq-content-space">&nbsp;</span>
  </p>
  <hr class="faq-border"/>
</div>
