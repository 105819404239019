<div class="row column" *ngIf="data">

  <div class="row">
    <div class="columns small-12">
      <div style="display:flex">
        <h2 class="entryTitle">{{data.title}}</h2>
        <app-entry-status [isSummary]="true" [isProteome]="data.isReferenceProteome" [isReviewed]="data.isReviewed"></app-entry-status>
      </div>
      
      <p class="entrySubtitle">{{data.afdb_accession}}</p>
    </div>

    <div class="columns small-12 entryDownloads">
      <span>Download</span>
      <a class="vf-button vf-button--primary vf-button--sm" style="color: #FFFFFF"
         (click)="downloadClick($event, 'download', 'pdb')"
         href="{{entryData.pdbUrl}}">PDB file
      </a>
      <a class="vf-button vf-button--primary vf-button--sm" style="color: #FFFFFF"
         (click)="downloadClick($event, 'download', 'cif')"
         href="{{entryData.cifUrl}}">mmCIF file
      </a>
      <a class="vf-button vf-button--primary vf-button--sm" style="color: #FFFFFF"
         (click)="downloadClick($event, 'download', 'pae')"
         href="{{entryData.paeDocUrl}}" download>Predicted aligned error
      </a>
      
      <br>
      <br>
      <span>
        Share your feedback on structure with Google DeepMind
      </span>
      
        <a class="vf-button vf-button--secondary vf-button--sm" style="color: #3B6FB6"
           (click)="downloadClick($event, 'feedback', 'survey-positive-feedback-from-entry')"
           href="https://docs.google.com/forms/d/e/1FAIpQLSel21_fnkopZuGGDiPF5T-fe-q4glwA17eW1WsMx2QLi0PX3Q/viewform?usp=pp_url&entry.62243469={{accession}}"
          target="_blank">
          Looks great
        </a>

        <a class="vf-button vf-button--secondary vf-button--sm" style="color: #3B6FB6"
           (click)="downloadClick($event, 'feedback', 'survey-negative-feedback-from-entry')"
           href="https://docs.google.com/forms/d/e/1FAIpQLScI4yiIyHQzpxNDO4Y47Kw-hyjsjCerb-w8jdCXFki2OW9Kxw/viewform?usp=pp_url&entry.62243469={{accession}}"
          target="_blank">
          Could be improved
        </a>
    

    </div>
  </div>

  <div class="row">
    <div class="columns small-12" style="position: relative;">
      <h3 class="entrySectionHeading" (click)="toggleInfoSection()">Information
        <svg id = "summaryToggleIcon" class="summaryToggleIcon" viewBox="0 0 24 24">
          <path *ngIf="infoSectionHeight.maxHeight === '0px'" fill="currentColor"
                d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
          <path *ngIf="infoSectionHeight.maxHeight !== '0px'" fill="currentColor"
                d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"/>
        </svg>
      </h3>
    </div>
  </div>

  <div class="row column entrySectionContent" [ngStyle]="infoSectionHeight">
    <div class="row entryInfoRow" *ngFor="let text of data.texts">
      <div class="columns small-5 medium-2 entryInfoCol1">{{text.label}}</div>
      <div class="columns small-7 medium-10 entryInfoCol2">
         <span *ngIf="text.label != 'Status'">
          <span *ngIf="text.show_long">
            <span [innerHTML]="text.text"></span>
            <a *ngIf="getShortenedText(text.text) != text.text" class="showMoreLink" (click)="switchText(text)">
              <i class="icon icon-functional" data-icon="-"></i> [show less]</a>
          </span>
          <span *ngIf="!text.show_long">
            <span [innerHTML]="getShortenedText(text.text)"></span>
            <a class="showMoreLink" *ngIf="checkIfHasToShowMore(text)" (click)="switchText(text)">
              <i class="icon icon-functional" data-icon="+"></i> [show more]
            </a>
          </span>
        </span> 

        <!-- <span *ngIf="text.label == 'Status'">
          <app-entry-status [isProteome]="text.data.isReferenceProteome" [isReviewed]="text.data.isReviewed"></app-entry-status>
        </span>
         -->

        <a *ngIf="text.source" target="_blank" href="{{text.source_url}}" class="vf-link ext"> go to {{text.source}}</a>
        <span *ngIf="text.italic"><a class="vf-link ext"
                                     href="./search/organismScientificName/{{commonService.escapeValue(text.searchText)}}"
                                     target="_blank">go to search</a></span>

      </div>
    </div>
  </div>

</div>
