import { Component } from '@angular/core';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent  {

  findOutMore: any;

  constructor(public gaService: GoogleAnalyticsService) {

    this.findOutMore = [
      [
        { label: 'Methodology', url: 'https://www.nature.com/articles/s41586-021-03819-2'},
        { label: 'Human proteome predictions', url: 'https://www.nature.com/articles/s41586-021-03828-1'},
        { label: 'Downloads', url: '/download', isRoute: true},
        { label: 'Online training course', url: 'https://www.ebi.ac.uk/training/online/courses/alphafold/'}
      ],
      [
        { label: 'About AlphaFold DB', url: '/about', isRoute: true},
        { label: 'Google DeepMind', url: 'https://deepmind.google/'},
        { label: 'EMBL-EBI', url: 'https://www.ebi.ac.uk/about'}
      ]
    ];
  }
}
