


<div class="row threedViewer">
  <div class="columns structureTitleBox">
    <div class="filledBox ">
      <span class="title">Structure similarity cluster</span>
      <span class="paeDescription">Predicted structures in the AlphaFold Protein Structure Database clustered using <a (click)= "gaService.eventEmitter(
        'clusters', 'structure_similarity', 'click', 'cluster_urls', 'Clicks on the MMseqs2, Foldseek, AFDB Clusters and Stenegger et al URLs in the cluster members table')" href="https://github.com/soedinglab/mmseqs2" target="_blank">MMseqs2</a> and 
        <a (click)= "gaService.eventEmitter(
          'clusters', 'structure_similarity', 'click', 'cluster_urls', 'Clicks on the MMseqs2, Foldseek, AFDB Clusters and Stenegger et al URLs in the cluster members table')" href="https://github.com/steineggerlab/foldseek" target="_blank">Foldseek</a>.
         This data is provided by the 
        <a (click)= "gaService.eventEmitter(
          'clusters', 'structure_similarity', 'click', 'cluster_urls', 'Clicks on the MMseqs2, Foldseek, AFDB Clusters and Stenegger et al URLs in the cluster members table')" href="https://cluster.foldseek.com/" target="_blank">AFDB Clusters</a>. </span>
    </div>
  </div>  
</div>

<div class="row threedViewer structureContainer">
  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab class="mat-tab-title" label="AFDB50/MMseqs2 ({{ totalMmSeq }})">
      <p class="paeDescription tabDesc">
        AlphaFold database protein sequences clustered by the MMseqs2 algorithm
        (<a
          class=""
          href="https://www.nature.com/articles/s41467-018-04964-5"
          target="_blank"
          >Steinegger M. and Soeding J., Nat. Commun. 9, 2018</a
        >). Each cluster is comprised of sequences that fulfil two criteria:
        maintaining a maximum sequence identity of 50% and achieving a 90%
        bi-directional sequence overlap with the longest sequence of the cluster
        representative.
      </p>
  
      <div class="row threedViewer structureContainer filterWrapper">
        <div style="position: relative">
          <input
            #filterBox
            class="vf-form__input searchLine"
            autocomplete="off"
            placeholder="Search species"
            (keyup.enter)="filterTable(filterBox.value, 'mmseq')"
            id="filteritem"
            [(value)]="filteritem"
          />
          <span
            *ngIf="filterBox.value === ''"
            class="filtericon"
            (click)="filterTable(filterBox.value, 'mmseq')"
          >
            <img src="assets/img/Search.svg" />
          </span>
  
          <span
            *ngIf="filterBox.value !== ''"
            class="filtericon"
            (click)="clearFilter('mmseq')"
          >
            <img src="assets/img/crossicon.svg" />
          </span>
        </div>
      </div>
  
      <div class="mat-elevation-z4">
        <table
          mat-table
          [dataSource]="mmSeqDataSource"
          class="mat-elevation-z8 mmseqTable"
          matSort
          matSortActive="averagePlddt"
          matSortDirection="asc"
          (matSortChange)="sortTable($event, 'mmseq')"
          matSortDisableClear
        >
          <ng-container matColumnDef="afdbAccessions">
            <th class="first" mat-header-cell *matHeaderCellDef>
              AFDB accession
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mobile-view mobile-view-title">AFDB accession</span>
              <span *ngIf="element.afdbAccessions === 'Unavailable'"
                >{{ element.afdbAccessions }} 
              </span>
  
              <span
                class="titleBadges"
                *ngIf="element.afdbAccessions !== 'Unavailable'">
                <a (click)= "gaService.eventEmitter(
             'clusters', 'structure_similarity', 'click', 'cluster_clickthrough', 'Clicks on and AFDB accession URL in the cluster members table
             ')" class='accessLink' href="/entry/{{element.afdbAccessions.split('-')[1]}}" target="_blank">{{ element.afdbAccessions.split('*')[0] }} </a>
                <app-entry-status [isSummary]="false" [clusterTable]="true" [isProteome]="element.afdbAccessions.split('*')[1]" [isReviewed]="element.afdbAccessions.split('*')[2]"></app-entry-status>
              </span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="uniprotDescriptions">
            <th class="second" mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">
              <span class="mobile-view mobile-view-title">Description</span>
              <span class="mobile-view desc-mobile"> {{ element.uniprotDescriptions }} </span>
            
              <span class="nonMobile">
                <div class="label-tooltip" *ngIf="element.uniprotDescriptions.length > 35">
                  <p class="descEllipse"> {{ element.uniprotDescriptions }} ...</p>
                    <span class="label-tooltip-text">
                      {{ element.uniprotDescriptions }}
                    </span>
                </div>
    
                <div *ngIf="element.uniprotDescriptions.length <= 35">
                    {{ element.uniprotDescriptions }}
                </div>
              </span>
  
             
            </td>
          </ng-container>
  
          <ng-container matColumnDef="speciesNames">
            <th class="third" mat-header-cell *matHeaderCellDef>Species</th>
            <td mat-cell *matCellDef="let element">
              <!-- <span class="mobile-view mobile-view-title">Species</span>
              <i>{{ element.speciesNames }} </i> -->
  
              <span class="mobile-view mobile-view-title">Species</span>
              <span class="mobile-view desc-mobile"> <i>{{  element.speciesNames }}</i> </span>
            
              <span class="nonMobile">
                <div class="label-tooltip" *ngIf="element.speciesNames.length > 35">
                  <!-- <span class="descSmall"> <i>{{  element.speciesNames.slice(0,38) }} ...</i></span> -->
                  <p class="descEllipse"><i>{{ element.speciesNames }}... </i> </p>
                    <span class="label-tooltip-text">
                      {{  element.speciesNames }}
                    </span>
                </div>
    
                <div *ngIf="element.speciesNames.length <= 35">
                   <i> {{  element.speciesNames }}</i>
                </div>
              </span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="sequenceLength">
            <th class="fourth" mat-header-cell *matHeaderCellDef mat-sort-header>
              Sequence length
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mobile-view mobile-view-title">Sequence length</span>
              {{ element.sequenceLength }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="averagePlddt">
            <th class="fifth" mat-header-cell *matHeaderCellDef mat-sort-header>
              Average pLDDT
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mobile-view mobile-view-title"> Average pLDDT</span>
              {{ element.averagePlddt }}
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
  
        <div class="row threedViewer structureContainer">
          <p *ngIf="hasMMSeqData === -1" class="noRecMsg">{{ errorMmSeq }}</p>
        </div>
  
        <mat-paginator
          [length]="totalMmSeq"
          [pageSize]="recordsmmseq"
          [pageIndex]="currentmmseqPage"
          [pageSizeOptions]="[5, 10, 25, 50, 100]"
          aria-label="Select page"
          (page)="handlePageEvent($event,'mmseq')"
        >
        </mat-paginator>
      </div>
    </mat-tab>
    <mat-tab class="mat-tab-title" label="AFDB/Foldseek ({{ totalfoldseek }})">
      <p class="paeDescription tabDesc">
        Structural clustering of the protein structure with the highest pLDDT for
        each AFDB50 cluster using Foldseek Cluster (<a
          href="https://www.nature.com/articles/s41586-023-06510-w"
          target="_blank"
          >Barrio-Hernandez & Yeo et al., Nature, 2023</a
        >). Each cluster is comprised of structures that fulfil two criteria:
        maintaining an E-value threshold below 0.01 and ensuring a 90%
        bi-directional structure overlap to the largest structure of a cluster
        representative.
      </p>
  
      <div class="row threedViewer structureContainer filterWrapper">
        <div style="position: relative">
          <input
            #filterBox2
            class="vf-form__input searchLine"
            autocomplete="off"
            placeholder="Search species"
            (keyup.enter)="filterTable(filterBox2.value, 'foldseek')"
            id="filteritem2"
            [(value)]="filteritem2"
          />
          <span
            *ngIf="filterBox2.value === ''"
            class="filtericon"
            (click)="filterTable(filterBox2.value, 'foldseek')"
          >
            <img src="assets/img/Search.svg" />
          </span>
  
          <span
            *ngIf="filterBox2.value !== ''"
            class="filtericon"
            (click)="clearFilter('foldseek')"
          >
            <img src="assets/img/crossicon.svg" />
          </span>
        </div>
      </div>
  
      <div class="mat-elevation-z4">
        <table
          mat-table
          [dataSource]="foldSeekDataSource"
          class="mat-elevation-z8 mmseqTable"
          matSort
          matSortActive="averagePlddt"
          matSortDirection="asc"
          (matSortChange)="sortTable($event, 'foldseek')"
          matSortDisableClear
        >
          <ng-container matColumnDef="afdbAccessions">
            <th class="first" mat-header-cell *matHeaderCellDef>
              AFDB accession
            </th>
  
            <td mat-cell *matCellDef="let element">
              <span class="mobile-view mobile-view-title">AFDB accession</span>
              <span *ngIf="element.afdbAccessions === 'Unavailable'"
                >{{ element.afdbAccessions }}
              </span>
              <span
                class="titleBadges"
                *ngIf="element.afdbAccessions !== 'Unavailable'"
              >
              <a (click)= "gaService.eventEmitter(
                'clusters', 'structure_similarity', 'click', 'cluster_clickthrough', 'Clicks on and AFDB accession URL in the cluster members table
                ')" class='accessLink' href="/entry/{{element.afdbAccessions.split('-')[1]}}" target="_blank">{{ element.afdbAccessions.split('*')[0] }} </a>
                   <app-entry-status [isSummary]="false" [clusterTable]="true" [isProteome]="element.afdbAccessions.split('*')[1]" [isReviewed]="element.afdbAccessions.split('*')[2]"></app-entry-status>
                  </span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="uniprotDescriptions">
            <th class="second" mat-header-cell *matHeaderCellDef>Description</th>
  
            <td mat-cell *matCellDef="let element">
              <span class="mobile-view mobile-view-title">Description</span>
              <span class="mobile-view desc-mobile"> {{ element.uniprotDescriptions }} </span>
            
              <span class="nonMobile">
                <div class="label-tooltip" *ngIf="element.uniprotDescriptions.length > 35">
                  <p class="descEllipse"> {{ element.uniprotDescriptions }}...</p>
                    <span class="label-tooltip-text">
                      {{ element.uniprotDescriptions }}
                    </span>
                </div>
    
                <div *ngIf="element.uniprotDescriptions.length <= 35">
                    {{ element.uniprotDescriptions }}
                </div>
              </span>
          </ng-container>
  
          <ng-container matColumnDef="speciesNames">
            <th class="third" mat-header-cell *matHeaderCellDef>Species</th>
  
            <td mat-cell *matCellDef="let element">
              <!-- <span class="mobile-view mobile-view-title">Species</span>
              <i>{{ element.speciesNames }} </i> -->
              <span class="mobile-view mobile-view-title">Species</span>
              <span class="mobile-view desc-mobile"> <i>{{  element.speciesNames }}</i> </span>
            
              <span class="nonMobile">
                <div class="label-tooltip" *ngIf="element.speciesNames.length > 35">
                  <p class="descEllipse"> <i>{{ element.speciesNames }}...</i> </p>
                    <span class="label-tooltip-text">
                      {{  element.speciesNames }}
                    </span>
                </div>
    
                <div *ngIf="element.speciesNames.length <= 35">
                   <i> {{  element.speciesNames }}</i>
                </div>
              </span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="sequenceLength">
            <th class="fourth" mat-header-cell *matHeaderCellDef mat-sort-header>
              Sequence length
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mobile-view mobile-view-title">Sequence length</span>
              {{ element.sequenceLength }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="averagePlddt">
            <th class="fifth" mat-header-cell *matHeaderCellDef mat-sort-header>
              Average pLDDT
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="mobile-view mobile-view-title"> Average pLDDT</span>
              {{ element.averagePlddt }}
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
  
        <div class="row threedViewer structureContainer">
          <p *ngIf="hasFoldseek === -1" class="noRecMsg">{{ errorfoldseek }}</p>
        </div>
  
        <mat-paginator
          [length]="totalfoldseek"
          [pageIndex]="currentfoldseekPage"
          [pageSize]="recordsfoldseek"
          [pageSizeOptions]="[5, 10, 25, 50, 100]"
          aria-label="Select page"
          (page)="handlePageEvent($event, 'foldseek')"
        >
        </mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

