<div class="columns viewTitleBox">
  <div class="columns modalspace mobileModal-view" *ngIf="csvAmData">
    <mat-button-toggle-group class="tab" name="molstar-coloring" aria-label="Molstar Color">
      <mat-button-toggle value="plddt" class="titleModel" checked (change)="applyMolstarColoring($event.value)">Model Confidence
      </mat-button-toggle>
        
      <mat-button-toggle value="missense" class="titleModel" (change)="applyMolstarColoring($event.value)">AlphaMissense Pathogenicity
      </mat-button-toggle>
    </mat-button-toggle-group>

  </div>
  <div class="columns modalspace mobileModal-view" *ngIf="isModalConf == true">
    <span class="titleModelConf" *ngIf="!csvAmData">Model Confidence 
      <div class="label-tooltip" style="display: inline; margin-left: 8px;">
        <svg style="width:15px;height:15px" viewBox="0 0 24 24"
            (mouseover)="gaService.eventEmitter('molstar-tooltip', 'tooltip', 'mouseover', accession, undefined)">
          <path fill="#3B6FB6"
                d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
        </svg>
        <span class="label-tooltip-text modelconfPara" style="font-size: 14px; font-weight: 400; text-align: left;  white-space: break-spaces;">
          pLDDT corresponds to the model’s prediction of its score on the local Distance Difference Test (<a
          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/" target="_blank"
          class="licenseLink">lDDT-Cα</a>).
          It is a measure of local accuracy - for interpreting larger scale features like relative domain positions see the “predicted aligned error” plot and corresponding tutorial. Confidence bands are used to colour-code the residues in the 3D viewer. The exact pLDDT value is shown when you mouseover the structure or the sequence. It can also be found in the B-factor fields of the downloadable coordinate files.
        </span>
      </div>
      <br/>
    </span>

    <div class="modelInfo"> 
      <div class="confidenceWrapperConf">
        <div class="column legendRow" *ngFor="let legend of viewerLegend">
          <span class="legendColor" [ngStyle]="legend.style">&nbsp;</span><span
          class="legendlabel">{{legend.label}}</span>
        </div>
      </div>
      <div class="column legendDesc">
        AlphaFold produces a per-residue model confidence score (pLDDT) between 0 and 100. Some regions below 50 pLDDT may be unstructured in isolation.
      </div>
    </div>
  </div>

  <div class="columns modalspace mobileModal-view" *ngIf="isAlphaMis == true">
    <div class="modelInfo"> 
      <div class="confidenceWrapperAM">
        <div class="column legendRow" *ngFor="let alpha of alphamissenseLegends">
          <span class="legendColor" [ngStyle]="alpha.style">&nbsp;</span><span
          class="legendlabel">{{alpha.label}}</span>
        </div>
      </div>
      <div class="column legendDesc">
        The displayed colour for each residue is the average AlphaMissense pathogenicity score across all possible amino acid substitutions at that position.
      </div>
    </div>
  </div>

  <div class="columns small-12 medium-9 molstarView">
    <div class="small-12 columns molstar-container"
        (click)="gaService.eventEmitter('molstar-click', 'molstar', 'click', accession, undefined)">
    </div>
  </div>
  <div class="columns modalspace bigModal-view" *ngIf="csvAmData">
    <mat-button-toggle-group class="tab" name="molstar-coloring" aria-label="Molstar Color">
      <mat-button-toggle value="plddt" class="titleModel" checked (change)="applyMolstarColoring($event.value)">Model Confidence

        <div class="label-tooltip" style="display: inline; margin-left: 8px;">
          <svg style="width:15px;height:15px" viewBox="0 0 24 24"
              (mouseover)="gaService.eventEmitter('molstar-tooltip', 'tooltip', 'mouseover', accession, undefined)">
            <path fill="#1A1C1A"
                  d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
          </svg>
          <span class="label-tooltip-text modelconfPara" style="font-size: 14px; font-weight: 400; text-align: left; white-space: break-spaces;">
            pLDDT corresponds to the model’s prediction of its score on the local Distance Difference Test (<a
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/" target="_blank"
            class="licenseLink" (click)="stopChangePropagation($event,'plddt')">lDDT-Cα</a>).
            It is a measure of local accuracy - for interpreting larger scale features like relative domain positions see the “predicted aligned error” plot and corresponding tutorial.
            
            <p class="modelconfPara">Confidence bands are used to colour-code the residues in the 3D viewer. The exact pLDDT value is shown when you mouseover the structure or the sequence. It can also be found in the B-factor fields of the downloadable coordinate files.</p>
          </span>
        </div>
        
      </mat-button-toggle>
        
      <mat-button-toggle value="missense" class="titleModel" (change)="applyMolstarColoring($event.value)">AlphaMissense Pathogenicity

        <div class="label-tooltip" style="display: inline; margin-left: 8px;">
          <svg style="width:15px;height:15px" viewBox="0 0 24 24"
              (mouseover)="gaService.eventEmitter('alphmissense-tooltip', 'tooltip', 'mouseover', accession, '')">
            <path fill="#1A1C1A"
                  d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
          </svg>
          <span class="label-tooltip-text modelconfPara" style="height:fit-content; font-size: 14px; font-weight: 400; text-align: left;  white-space: break-spaces;">
            The pathogenicity score classifies variants into 3 categories: Likely benign, Uncertain and Likely pathogenic. The cutoffs were chosen such that variants classified as likely pathogenic or likely benign have 90% expected precision estimated from ClinVar for both classes (<a class="licenseLink" href="https://doi.org/10.1126/science.adg7492" target="_blank" (click)="stopChangePropagation($event,'missense')">Cheng J. et al., 2023</a>).     
          </span>
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>

  </div>

  <div class="columns modalspace bigModal-view" *ngIf="isModalConf == true && isShowModelLegends">
    <span class="titleModelConf" *ngIf="!csvAmData">Model Confidence 
      <div class="label-tooltip" style="display: inline; margin-left: 8px;">
        <svg style="width:15px;height:15px" viewBox="0 0 24 24"
            (mouseover)="gaService.eventEmitter('molstar-tooltip', 'tooltip', 'mouseover', accession, undefined)">
          <path fill="#3B6FB6"
                d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
        </svg>
        <span class="label-tooltip-text modelconfPara" style="font-size: 14px; font-weight: 400; text-align: left;  white-space: break-spaces;">
          pLDDT corresponds to the model’s prediction of its score on the local Distance Difference Test (<a
          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/" target="_blank"
          class="licenseLink">lDDT-Cα</a>).
          It is a measure of local accuracy - for interpreting larger scale features like relative domain positions see the “predicted aligned error” plot and corresponding tutorial. Confidence bands are used to colour-code the residues in the 3D viewer. The exact pLDDT value is shown when you mouseover the structure or the sequence. It can also be found in the B-factor fields of the downloadable coordinate files.
        </span>
      </div>
     
    </span>

    <div class="modelInfo"> 
      <div class="confidenceWrapper">
        <div class="column legendRow" *ngFor="let legend of viewerLegend">
          <span class="legendColor" [ngStyle]="legend.style">&nbsp;</span><span
          class="legendlabel">{{legend.label}}</span>
        </div>
      </div>
      <div class="column legendDesc">
        AlphaFold produces a per-residue model confidence score (pLDDT) between 0 and 100. Some regions below 50 pLDDT may be unstructured in isolation.
      </div>
    </div>
     
  </div>

  <div class="columns modalspace bigModal-view" *ngIf="isAlphaMis == true  && isShowModelLegends && csvAmData">
    <div class="modelInfo"> 
      <div class="confidenceWrapperAM">
        <div class="column legendRow" *ngFor="let alpha of alphamissenseLegends">
          <span class="legendColor" [ngStyle]="alpha.style">&nbsp;</span><span
          class="legendlabel">{{alpha.label}}</span>
        </div>
      </div>
      <div class="column legendDesc">
        The displayed colour for each residue is the average AlphaMissense pathogenicity score across all possible amino acid substitutions at that position.
      </div>
    </div>
  </div>

  <div class="columns modalspace bigModal-view">
    <h3 *ngIf="isShowModelLegendsText && csvAmData" (click)="toggleShowModel()" class="showLegengds">Show colour legend
      <i *ngIf="isShowModelLegendsText && csvAmData" class="icon icon-common" data-icon="&#xf078;"></i>
    </h3>
    <h3 *ngIf="!isShowModelLegendsText && csvAmData" (click)="toggleShowModel()" class="showLegengds">Hide colour legend
      <i *ngIf="!isShowModelLegendsText && csvAmData" class="icon icon-common" data-icon="&#xf077;"></i>
    </h3>
  </div>
 
</div>