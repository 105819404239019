<div class="training-wrapper">
    <mat-tab-group>
        <mat-tab class="mat-tab-title" label="" [disabled]="isLiveTrainingLoading">
            <ng-template mat-tab-label>
                Live training&nbsp;
                
                <!-- Spinner shown only when isLiveTrainingLoading is true -->
                <span *ngIf="isLiveTrainingLoading"  style="display: inline-block; width: 20px; margin-left: 8px;">
                <svg  width="21" height="21" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="spinner_aj0A" stroke="#707372" fill="#707372" />
                </svg>
                </span>
            </ng-template>
            <section class="vf-tabs__section" id="vf-tabs__section--2">
                <!-- Live training tab content -->
                <div class="vf-grid vf-grid__col-2 space" *ngIf="!isLiveTrainingLoading && hasLiveTrainingData === 1 && !liveTrainingAPIError">
    
                    <div class="vf-summary vf-summary--event" *ngFor="let liveItem of liveTrainingData; let i = index" ng-repeat="item in liveTrainingData">
                        <p class="vf-summary__date">{{ liveItem.fields.type[0] }} </p>
                        <h3 class="vf-summary__title live-h3"><a href="{{ liveItem.fields.url[0] }}" target="_blank" class="vf-summary__link"> {{ liveItem.fields.title[0] }}</a></h3>
                        <div>
                        <div class="vf-summary__text">
                            {{ formatDesc(liveItem.fields.description[0]) }}
                        </div>
                        </div>
                        <div class="vf-summary__location">
                            <div class="vf-u-margin__top--400"></div>
                            <span>{{ liveItem.fields.status[0] }}</span> <span class="space-lr">|</span>
                            <span><i class="icon icon-common icon-calendar-alt"></i> {{ liveItem.fields.date_time_clean[0] }} </span> 
                            <span> <span class="space-lr">|</span> <i class="icon icon-common icon-location"></i> {{ liveItem.fields.venue[0] == 'null' ? 'Online' : liveItem.fields.venue[0] }}</span>
                        </div>
                    </div>
                </div>
                <div class="vf-grid" >
                    <div class="vf-summary vf-summary--event" *ngIf="!isLiveTrainingLoading && hasLiveTrainingData === 0 && !liveTrainingAPIError">
                        <p class="vf-summary__date"> </p>
                        <div class="vf-summary__text space">
                            Currently there are no upcoming events - please see our on-demand training which includes materials from past courses.
                        </div>
                        
                    </div>
                </div>
                <div class="vf-grid" >
                    <div class="vf-summary vf-summary--event" *ngIf="!isLiveTrainingLoading && hasLiveTrainingData === -1 && liveTrainingAPIError">
                        <p class="vf-summary__date"> </p>
                        <div class="vf-summary__text space">
                            Currently, we are experiencing an issue fetching data. Please try again later.
                        </div>
                        
                    </div>
                </div>
            </section>
            <section class="vf-tabs__section space-top" id="vf-tabs__section--2">
                <a  class="alllinks" href="https://www.ebi.ac.uk/training/services/alphafold-db/live-events" target="_blank">View all live training</a>
            </section>
                            
        </mat-tab>
        <mat-tab class="mat-tab-title" label="" [disabled]="isOndemandTrainingLoading">
            <ng-template mat-tab-label>
                On-demand training&nbsp;
                <!-- Spinner shown only when isLiveTrainingLoading is true -->
                <span *ngIf="isOndemandTrainingLoading"  style="display: inline-block; width: 20px; margin-left: 8px;">
                <svg  width="21" height="21" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="spinner_aj0A" stroke="#707372" fill="#707372" />
                </svg>
                </span>
            </ng-template>
            <section class="vf-tabs__section" id="vf-tabs__section--2">
                <!-- On-demand training tab content -->
                <div class="vf-grid vf-grid__col-2 space" *ngIf="!isOndemandTrainingLoading && hasOndemandTrainingData === 1 && !ondemandTrainingAPIError">
                    <div class="vf-summary vf-summary--event" *ngFor="let ondemandItem of ondemandTrainingData; let i = index" ng-repeat="item in ondemandTrainingData">
                        <p class="vf-summary__date">{{ ondemandItem.fields.type[0] }} </p>
                        <h3 class="vf-summary__title"><a href="{{ ondemandItem.fields.url[0] }}" target="_blank" class="vf-summary__link">{{ ondemandItem.fields.title[0]
                        }} </a></h3>
                        
                        <div>
                        <div class="vf-summary__text">
                            {{ formatDesc(ondemandItem.fields.description[0]) }}
                        </div>
                        </div>
                    </div>
                </div>
                <div class="vf-grid" >
                    <div class="vf-summary vf-summary--event" *ngIf="!isOndemandTrainingLoading && hasOndemandTrainingData === 0 && !ondemandTrainingAPIError">
                        <p class="vf-summary__date"> </p>
                        
                        <div class="vf-summary__text space">
                            Currently there are no on-demand training available.
                        </div>
                        
                    </div>
                </div>
                <div class="vf-grid" >
                    <div class="vf-summary vf-summary--event" *ngIf="!isOndemandTrainingLoading && hasOndemandTrainingData === -1 && ondemandTrainingAPIError">
                        <p class="vf-summary__date"> </p>
                        <div class="vf-summary__text space">
                            Currently, we are experiencing an issue fetching data. Please try again later.
                        </div>
                        
                    </div>
                </div>
            </section>
            <section class="vf-tabs__section space-top" id="vf-tabs__section--2">
                <a class="alllinks" href="https://www.ebi.ac.uk/training/services/alphafold-db/on-demand" target="_blank">View all on-demand training</a>
            </section>
        </mat-tab>
    </mat-tab-group>
</div>
