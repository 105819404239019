import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {GoogleAnalyticsService} from '../google-analytics.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent  {

  menuOpen: boolean;
  homePage: boolean;
  routeSubscribe: Subscription;
  constructor(private router: Router, public gaService: GoogleAnalyticsService) {
    this.menuOpen = false;
    this.homePage = false;

    this.routeSubscribe = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribe(e => {
      this.homePage = false;
      if(e.url && (e.url === '/' || e.url === '/#news' )) this.homePage = true;
    });

  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

}
