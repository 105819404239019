import {Component} from '@angular/core';
import { ConfigService } from './config.service';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(router: Router, private configService: ConfigService) {
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag(
        'config',
        this.configService.getConfig().googleAnalyticsTag,
        {page_path: e.urlAfterRedirects});
    });
    const headerScript = document.createElement('script');
    headerScript.async = true;
    headerScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.configService.getConfig().googleAnalyticsTag;
    document.head.prepend(headerScript);
  }
}
