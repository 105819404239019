<div class="columns medium-6 find-more-content" *ngFor="let fmData of findOutMore">
    <span *ngFor="let fmDataRec of fmData">
        <span *ngIf="!fmDataRec.isRoute">
          <a class="vf-section-header__heading vf-section-header__heading--is-link"
            target="_blank"
            href="{{fmDataRec.url}}" (click)="clickOnLinks(fmDataRec.label)">{{fmDataRec.label}}
          
            <svg aria-hidden="true" class="vf-section-header__icon | vf-icon vf-icon-arrow--inline-end" width="24px"
                height="24px" style="padding-top: 0;" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0C5.376.008.008 5.376 0 12zm13.707-5.209l4.5 4.5a1 1 0 010 1.414l-4.5 4.5a1 1 0 01-1.414-1.414l2.366-2.367a.25.25 0 00-.177-.424H6a1 1 0 010-2h8.482a.25.25 0 00.177-.427l-2.366-2.368a1 1 0 011.414-1.414z"
                fill="" fill-rule="nonzero"></path>
            </svg>
          </a>
        </span>
        
        <span *ngIf="fmDataRec.isRoute">
          <a class="vf-section-header__heading vf-section-header__heading--is-link"
            [routerLink]="[fmDataRec.url]">{{fmDataRec.label}}
            <svg aria-hidden="true" class="vf-section-header__icon | vf-icon vf-icon-arrow--inline-end" width="24px"
                height="24px" style="padding-top: 0;" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0C5.376.008.008 5.376 0 12zm13.707-5.209l4.5 4.5a1 1 0 010 1.414l-4.5 4.5a1 1 0 01-1.414-1.414l2.366-2.367a.25.25 0 00-.177-.424H6a1 1 0 010-2h8.482a.25.25 0 00.177-.427l-2.366-2.368a1 1 0 011.414-1.414z"
                fill="" fill-rule="nonzero"></path>
            </svg>
          </a>
        </span>
    </span>
</div>