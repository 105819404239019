import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, inject } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/google-analytics.service';

@Directive({
  selector: '[appResidueRange]',
})
export class ResidueRangeDirective {
  @Input() residueData!: any;

  private readonly el = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  constructor( public gaService: GoogleAnalyticsService,){}

  ngOnChanges(changes: SimpleChanges): void {
    const residues = changes['residueData'].currentValue;
    if(residues.start){
      const length = residues.start.length;
      for(let index = 0; index < length; index++){
        const span = this.renderer.createElement('span');
        span.textContent = `${residues.start[index]} - ${residues.end[index]}`;
        const a = this.renderer.createElement('a');
        a.href = `https://www.ebi.ac.uk/pdbe/pdbe-kb/proteins/${residues.targetAccessions[index]}`;
        
        a.textContent = `${residues.targetAccessions[index]}`;
        this.renderer.setAttribute(a, 'target', '_blank');
        this.renderer.listen(a, 'click', (event) => this.onAnchorClick(event, residues.targetAccessions[index]));

        const img = this.renderer.createElement('img');
        img.src = '../../assets/img/pdb-icon.png';
 
        const p = this.renderer.createElement('p');
        this.renderer.appendChild(p, span)
        this.renderer.appendChild(p, a)
        this.renderer.appendChild(a, img);
        
        this.renderer.appendChild(this.el.nativeElement, p)
        
      }
    }else{
      const p = this.renderer.createElement('p');
      p.textContent = `N/A`;
      this.renderer.appendChild(this.el.nativeElement, p)
    }
  }

  private onAnchorClick(event: Event, targetAccession: string): void {
    this.gaService.eventEmitter('foldseek_table_action', 'FS_similar_structures', 'click', 'foldseek_uniprot_id_clickthrough', 'Clicks on the UniProt ID that leads to KB');
  }
}
