import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, inject } from '@angular/core';

@Directive({
  selector: '[appDescription]',
})
export class DescriptionDirective implements OnChanges {

  @Input() descriptionData!: string[];
  @Input() isMobileDesc!: any;
  
  private readonly el = inject(ElementRef);
  private readonly renderer = inject(Renderer2);


  ngOnChanges(changes: SimpleChanges): void {
    const descriptions = changes['descriptionData'].currentValue;
    
    for(const description of descriptions){
      if(changes['isMobileDesc'].currentValue){
        const p = this.renderer.createElement('p');
        p.textContent = description;
        this.renderer.appendChild(this.el.nativeElement, p);
      }else {
        if(description.length <= 27){
          const p = this.renderer.createElement('p');
          p.textContent = description;
          this.renderer.appendChild(this.el.nativeElement, p);
        } else {
          const div = this.renderer.createElement('div');
          this.renderer.addClass(div, 'label-tooltip');
          const p = this.renderer.createElement('p');
          p.textContent = `${description.slice(0,27)}...`;
          this.renderer.appendChild(div, p);
          // this.renderer.addClass(p,'label-tooltip');
          const span = this.renderer.createElement('span');
          this.renderer.addClass(span, 'label-tooltip-text');
          span.textContent = description;
          this.renderer.appendChild(div, span);
          this.renderer.appendChild(this.el.nativeElement, div);
        }
      }
      
    }
  }
}
