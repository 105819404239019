import {Component, OnInit} from '@angular/core';
import {Download} from './download.models';
import {GoogleAnalyticsService} from '../google-analytics.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  public downloadList: any;
  public proteomeDownloadList: Download[][];
  public swissprotDownloadList: Download[];
  public maneDownloadList: Download[];
  private fileUrl = 'https://alphafold.ebi.ac.uk/files/download_metadata_v4.json';

  constructor(public gaService: GoogleAnalyticsService, private httpClient: HttpClient) {
    this.proteomeDownloadList = [[], []]; // 0 = "type": "proteome", 1 = "type": "ntd"
    this.swissprotDownloadList = [];
    this.maneDownloadList = [];
  }

  ngOnInit(): void{
    this.httpClient.get(this.fileUrl).subscribe(data => {
      this.downloadList = data;
      if (data){
        this.filterData();
      }
    });
  }

  filterData(): void{
    this.downloadList.forEach( item => {
      if (item.type === 'proteome') {
        this.proteomeDownloadList[0].push(item);
      } else if (item.type === 'swissprot') {
        this.swissprotDownloadList.push(item);
      } else if (item.type === 'global_health') {
        this.proteomeDownloadList[1].push(item);
      } else if ( item.type === 'mane') {
        this.maneDownloadList.push(item)
      }
    });
  }

  formatNumber(n: number): string {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatDataSize(n: number): string {
    return this.formatNumber((Math.ceil(n / 1024 / 1024)));
  }

}
