<div class="row">
  <div class="columns small-12 medium-10 medium-offset-1">
    <div class="download-summary">
      <h2>Downloads</h2>
      <nav class="about-nav">
        <ul>
          <li>
            <a href="/download#proteomes-section">Model organism proteomes</a>
          </li>
          <li>
            <a href="/download#global-health-section">Global health proteomes</a>
          </li>
          <li>
            <a href="/download#swissprot-section">Swiss-Prot</a>
          </li>
          <li>
            <a href="/download#mane-section">MANE Select</a>
          </li>
          <li>
            <a href="/download#full-dataset-section">Full dataset</a>
          </li>

        </ul>
      </nav>
      <p>The AlphaFold DB website currently provides bulk downloads for the 48 organisms listed below, as well as the majority of
        <a href="https://www.expasy.org/resources/uniprotkb-swiss-prot" target="_blank">Swiss-Prot.</a>
        <br><br>
        You can download a prediction for an individual UniProt accession by visiting the corresponding structure page
        (example: <a [routerLink]="['entry','F4HVG8']">https://www.alphafold.ebi.ac.uk/entry/F4HVG8</a>).
        <br><br>
        For downloading all predictions for a given species, use the download links below. Note that this option
        is only available on the desktop version of the site.
        <br><br>
        The uncompressed archive files (.tar) contain all the available compressed PDB and mmCIF files (.gz) for a
        reference proteome. In the case of proteins longer than 2700 amino acids (aa), AlphaFold provides 1400aa long,
        overlapping fragments. For example, Titin has predicted fragment structures named as Q8WZ42-F1 (residues 1–1400),
        Q8WZ42-F2 (residues 201–1600), etc. These fragments are currently only available for the human proteome in these proteome
        archive files, not on the website.
        <br><br>
        For downloading predictions for all the species in the tables below, visit the FTP site:
        <a href="https://ftp.ebi.ac.uk/pub/databases/alphafold"
           (click)="gaService.eventEmitter(
      'link_visit',
      'download_href_click',
      'click',
      'ftp_area',
      '')">https://ftp.ebi.ac.uk/pub/databases/alphafold</a>
      </p>

    </div>
  </div>
</div>

<div class="row" *ngFor="let proteomeData of proteomeDownloadList; let i = index">
  <div class="columns small-12 large-9 large-offset-1">
    <h3 id="{{ i == 0 ? 'proteomes-section' : 'global-health-section'}}">
      {{ i == 0 ?
        'Compressed prediction files for model organism proteomes: ' :
        'Compressed prediction files for global health proteomes:'
      }}
    </h3>
  </div>
  <div class="columns small-12">
    <table class="download-table vf-table">
      <thead class="vf-table__header">
      <tr class="vf-table__row">
        <th class="vf-table__heading">Species</th>
        <th class="vf-table__heading common-name-col">Common Name</th>
        <th class="vf-table__heading cust-width-col ref-proteome-col">Reference Proteome</th>
        <th class="vf-table__heading right-align">Predicted Structures</th>
        <th class="vf-table__heading cust-width-col download-col">Download</th>
      </tr>
      </thead>
      <tbody class="vf-table__body">
      <tr *ngFor="let data of proteomeDownloadList[i]" class="vf-table__row">
        <td class="vf-table__cell species-name">{{data.species}}</td>
        <td *ngIf="data.latin_common_name" class="vf-table__cell common-name-col species-name">{{data.common_name}}</td>
        <td *ngIf="!data.latin_common_name" class="vf-table__cell common-name-col">{{data.common_name}}</td>
        <td class="vf-table__cell ref-proteome-col"><a
          href="https://www.uniprot.org/proteomes/{{data.reference_proteome}}"
          target="_blank">{{data.reference_proteome}} <i class="icon icon-generic" data-icon="x"></i></a></td>
        <td class="vf-table__cell right-align">{{formatNumber(data.num_predicted_structures)}}</td>
        <td class="vf-table__cell download-col"><a
          href="https://ftp.ebi.ac.uk/pub/databases/alphafold/latest/{{data.archive_name}}"
          (click)="gaService.eventEmitter(
      'bulk_download',
      'bulk_download',
      'click',
      data.archive_name,
      data.archive_name)">
          Download
          ({{formatDataSize(data.size_bytes)}} MB)</a></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row">
  <div class="columns small-12 large-9 large-offset-1">
    <h3 id="swissprot-section">Compressed prediction files for Swiss-Prot:</h3>
  </div>
  <div class="columns small-12">
    <table class="download-table vf-table">
      <thead class="vf-table__header">
      <tr class="vf-table__row">
        <th class="vf-table__heading">File type</th>
        <th class="vf-table__heading right-align">Predicted Structures</th>
        <th class="vf-table__heading cust-width-col download-col">Download</th>
      </tr>
      </thead>
      <tbody class="vf-table__body">
      <tr *ngFor="let data of swissprotDownloadList" class="vf-table__row">
        <td class="vf-table__cell">{{data.label}}</td>
        <td class="vf-table__cell right-align">{{formatNumber(data.num_predicted_structures)}}</td>
        <td class="vf-table__cell download-col"><a
          href="https://ftp.ebi.ac.uk/pub/databases/alphafold/latest/{{data.archive_name}}"
          (click)="gaService.eventEmitter(
      'bulk_download',
      'bulk_download',
      'click',
      data.archive_name,
      data.archive_name)">
          Download
          ({{formatDataSize(data.size_bytes)}} MB)</a></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
  <div class="row">
    <div class="columns small-12 large-9 large-offset-1">
      <h3 id="mane-section">MANE Select dataset:</h3>
    </div>
    <div class="columns small-12">
      <table class="download-table vf-table">
        <thead class="vf-table__header">
        <tr class="vf-table__row">
          <th class="vf-table__heading">File type</th>
          <th class="vf-table__heading right-align">Predicted Structures</th>
          <th class="vf-table__heading cust-width-col download-col">Download</th>
        </tr>
        </thead>
        <tbody class="vf-table__body">
        <tr *ngFor="let data of maneDownloadList" class="vf-table__row">
          <td class="vf-table__cell">{{data.label}}</td>
          <td class="vf-table__cell right-align">{{formatNumber(data.num_predicted_structures)}}</td>
          <td class="vf-table__cell download-col"><a
            href="https://ftp.ebi.ac.uk/pub/databases/alphafold/latest/{{data.archive_name}}"
            (click)="gaService.eventEmitter(
      'bulk_download',
      'bulk_download',
      'click',
      data.archive_name,
      data.archive_name)">
            Download
            ({{formatDataSize(data.size_bytes)}} MB)</a></td>
        </tr>
        </tbody>
      </table>
    </div>

</div>
  <div class="row licenseSection" id="full-dataset-section">
    <div class="columns small-12 large-9 large-offset-1">
      <h3 id="full-dataset-section">Full dataset download for AlphaFold Database - UniProt (214M):</h3>
      <p class="licenseText">
        The full dataset of all predictions is available at no cost and under a
        <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/">CC-BY-4.0</a> licence from
        Google Cloud Public Datasets.
        We've grouped this by single-species for ease of downloading subsets or all of the data. We suggest
        that you only download the full dataset if you need to process all the data with local computing
        resources (the size of the dataset is 23 TiB, ~1M tar files).
        Please see <a href="https://github.com/deepmind/alphafold/blob/main/afdb/README.md"
                      target="_blank"
                      (click)="gaService.eventEmitter(
      'link_visit',
      'home_href_click',
      'click',
      'gcp_readme',
      '')">our guide</a>
        for how to best download and query the data or reach out to
        <a href="mailto:alphafold@deepmind.com">alphafold&#64;deepmind.com</a> for further assistance.
      </p>
    </div>
  </div>


<div class="row licenseSection">
  <div class="columns small-12 large-9 large-offset-1">
    <h3 class="licenseHeading">Licence and attribution</h3>
    <p class="licenseText">
      Data is available for academic and commercial use, under a <a class="licenseLink"
                                                                    href="https://creativecommons.org/licenses/by/4.0/"
                                                                    target="_blank">CC-BY-4.0</a> licence.
      <br><br>
      EMBL-EBI expects attribution (e.g. in publications, services or products) for any of its online services, databases or software in accordance with good scientific practice.
      <br><br>
      If you make use of an AlphaFold prediction, please cite the following papers:<br class="hide-for-small-only">
      <a class="licenseLink" (click)="gaService.eventEmitter(
      'link_visit',
      'home_href_click',
      'click',
      'methods_paper',
      '')" href="https://www.nature.com/articles/s41586-021-03819-2" target="_blank">
        Jumper, J <i>et al</i>. Highly accurate protein structure prediction with AlphaFold. <i>Nature</i> (2021).</a><br>
        <a class="licenseLink" (click)="gaService.eventEmitter(
          'link_visit',
          'home_href_click',
          'click',
          'nar_paper',
          '')" href="https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkad1011/7337620"
             target="_blank" style="display:block; margin-top: 10px;">
            Varadi, M <i>et al</i>. 
            AlphaFold Protein Structure Database in 2024: providing structure coverage for over 214 million protein sequences. <i>Nucleic Acids Research</i> (2024).
            </a>
            <br>
            If you use data from AlphaMissense in your work, please cite the following paper:<br class="hide-for-small-only">
            <a class="licenseLink" (click)="gaService.eventEmitter(
            'link_visit',
            'home_href_click',
            'click',
            'methods_paper',
            '')" href="https://www.science.org/doi/10.1126/science.adg7492" target="_blank">
            Cheng, J et al. Accurate proteome-wide missense variant effect prediction with AlphaMissense. Science (2023).</a>
                 
      <br><br>
      AlphaFold Data Copyright (2022) DeepMind Technologies Limited.<br>
      AlphaMissense Copyright (2023) DeepMind Technologies Limited.
    </p>
  </div>
</div>

<div class="row licenseSection">
  <div class="columns small-12 large-9 large-offset-1">
    <h3 class="licenseHeading">Feedback and questions</h3>
    <p class="licenseText">
      If you want to share feedback on an AlphaFold structure prediction, please consider using the feedback buttons at the top of each structure page.
      If you have any questions that are not covered in the <a [routerLink]="'/faq'" target="_blank">FAQs</a>, please contact
      <a href="mailto:alphafold@deepmind.com">alphafold&#64;deepmind.com</a>. If you
      have feedback on the website or experience any bugs, please contact
      <a href="mailto:afdbhelp@ebi.ac.uk">afdbhelp&#64;ebi.ac.uk</a>.
    </p>
  </div>
</div>

<div class="row licenseSection">
  <div class="columns small-12 large-9 large-offset-1">
    <h3 class="licenseHeading">Disclaimer</h3>
    <p class="licenseText">
      The AlphaFold and AlphaMissense Data and other information provided on this site contain predictions with varying levels of confidence, is for theoretical modelling only and caution should be exercised in its use. It is provided 'as-is' without any warranty of any kind, whether expressed or implied. For clarity, no warranty is given that use of the information shall not infringe the rights of any third party. The information is not intended to be a substitute for professional medical advice, diagnosis, or treatment, and does not constitute medical or other professional advice. The AlphaFold and AlphaMissense Data have not been validated for, and are not approved for, any clinical use.
      <br><br>
      Use of the AlphaFold Protein Structure Database is subject to EMBL-EBI
      <a href="https://www.ebi.ac.uk/about/terms-of-use/" target="_blank">Terms of Use</a>.
    </p>
  </div>
</div>
