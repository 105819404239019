import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SimilarStructure } from '../entry/structure-data.models';
import { MatPaginator } from '@angular/material/paginator';
import { ConfigService } from '../config.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../common.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { EntryStatusComponent } from '../entry-status/entry-status.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-cluster-table',
  templateUrl: './cluster-table.component.html',
  styleUrls: ['./cluster-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClusterTableComponent implements OnInit {
  @Input() accession: boolean;
  hasMMSeqData: number;
  totalMmSeq: number;
  displayedColumns: string[] = ['afdbAccessions', 'uniprotDescriptions', 'speciesNames', 'sequenceLength','averagePlddt'];
  mmSeqDataSource = new MatTableDataSource<SimilarStructure>();
  errorMmSeq: string;
  errorfoldseek: string;
  isFetching: boolean = true;

  hasFoldseek: number;
  totalfoldseek: number;
  foldSeekDataSource = new MatTableDataSource<SimilarStructure>();

  records="10";
  start="0";

  sort_direction_mmseq='DESC'; 
  sort_column_mmseq='averagePlddt'; 

  sort_direction_foldseek='DESC'; 
  sort_column_foldseek='averagePlddt';

  species_name_filter="";
  currentmmseqPage = 0;
  currentfoldseekPage = 0;

  recordsmmseq = "10";
  recordsfoldseek = "10";

  filteritem = "";
  filteritem2 = "";

  @ViewChild('paginator1',{ static: true }) paginator1!: MatPaginator;
  @ViewChild('paginator2',{ static: true }) paginator2!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private sanitizer:DomSanitizer,
    private route: ActivatedRoute,
    private commonService: CommonService,
    public gaService: GoogleAnalyticsService,
    private configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef) {
      this.hasMMSeqData = -1;
      this.hasFoldseek = -1;
  }
   
  ngOnInit(): void {
    this.getClusterMmSeqData(this.recordsmmseq, this.start, this.sort_direction_mmseq, this.sort_column_mmseq, this.species_name_filter);
    this.getClusterFoldseekData(this.recordsfoldseek, this.start, this.sort_direction_foldseek, this.sort_column_foldseek, this.species_name_filter);
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  sortTable(sortState: Sort, flag) {
    let tag = "";
    let gtagmsg = "";
    if(sortState.direction == "asc" && sortState.active == "sequenceLength") {
      tag = "cluster_sort_length_asc";
      gtagmsg = "Clicks on the sort be ascending order for sequence length in the cluster members table";
    }
    if(sortState.direction == "desc" && sortState.active == "sequenceLength") {
      tag = "cluster_sort_length_desc";
      gtagmsg = "Clicks on the sort be descending order for sequence length in the cluster members table";
    }

    if(sortState.direction == "asc" && sortState.active == "averagePlddt") { 
      tag = "cluster_sort_plddt_asc";
      gtagmsg = "Clicks on the sort be ascending order for average pLDDT in the cluster members table";
    }
    if(sortState.direction == "desc" && sortState.active == "averagePlddt") {
      tag = "cluster_sort_plddt_desc";
      gtagmsg = "Clicks on the sort be descending order for average pLDDT in the cluster members table ";
    }

    const directions = {
      "ASC": "DESC",
      "DESC": "ASC"
    }

    if(flag === "mmseq"){
      this.sort_direction_mmseq = directions[sortState.direction.toUpperCase()];
      this.sort_column_mmseq = sortState.active
      let species_name_filter_mmseq;
      if(this.filteritem !== ""){
        species_name_filter_mmseq = this.filteritem;
      }
      this.getClusterMmSeqData(this.recordsmmseq, this.start, this.sort_direction_mmseq, this.sort_column_mmseq, species_name_filter_mmseq);
    }
    if(flag === "foldseek"){
      this.sort_direction_foldseek = directions[sortState.direction.toUpperCase()];
      this.sort_column_foldseek = sortState.active
      let species_name_filter_foldseek;
      if(this.filteritem2 !== ""){
        species_name_filter_foldseek = this.filteritem2;
      }
      this.getClusterFoldseekData(this.recordsfoldseek, this.start, this.sort_direction_foldseek, this.sort_column_foldseek, species_name_filter_foldseek);
    }
    this.gaService.eventEmitter('clusters', 'structure_similarity', 'click', tag, gtagmsg);
  }

  filterTable(filterby, flag){
    if(flag === "mmseq"){
      this.currentmmseqPage = 0;
      this.filteritem = filterby;
      this.start = "0";
      this.getClusterMmSeqData(this.recordsmmseq, "0", this.sort_direction_mmseq, this.sort_column_mmseq, filterby);
    }
    if(flag === "foldseek"){
      this.currentfoldseekPage = 0;
      this.filteritem2 = filterby;
      this.start = "0";
      this.getClusterFoldseekData(this.recordsfoldseek, "0", this.sort_direction_foldseek, this.sort_column_foldseek, filterby);
    }
    this.gaService.eventEmitter('clusters', 'structure_similarity', 'click', 'cluster_tax_filter', 'Clicks on the "Taxonomy filter" input box on the cluster members table');
  }

  clearFilter(flag){
    if(flag === "mmseq"){
      this.currentmmseqPage = 0;
      this.filteritem = "";
      this.getClusterMmSeqData(this.recordsmmseq, "0", this.sort_direction_mmseq, this.sort_column_mmseq, "");
    }
    if(flag === "foldseek"){
      this.currentfoldseekPage = 0;
      this.filteritem2 = "";
      this.getClusterFoldseekData(this.recordsfoldseek, "0", this.sort_direction_foldseek, this.sort_column_foldseek, "");
    }
  }

  handlePageEvent(e, flag){
    const start = ((e.pageIndex * e.pageSize)).toString();
    this.records = e.pageSize.toString();
    this.start = start;
    if(flag === "mmseq"){
      this.recordsmmseq = e.pageSize.toString();
      this.currentmmseqPage = e.pageIndex;
      let species_name_filter_mmseq = "";
      if(this.filteritem !== ""){
        species_name_filter_mmseq = this.filteritem;
      }
      this.gaService.eventEmitter(
        'clusters', 'structure_similarity', 'click', 'cluster_paginate_mmseqs', 'Clicks on the "MMseqs2 pagination" in the cluster members table'
      );
      this.getClusterMmSeqData(this.recordsmmseq, start, this.sort_direction_mmseq, this.sort_column_mmseq, species_name_filter_mmseq);
    }
    if(flag === "foldseek"){
      this.recordsfoldseek = e.pageSize.toString();
      this.currentfoldseekPage = e.pageIndex;
      let species_name_filter_foldseek = "";
      if(this.filteritem2 !== ""){
        species_name_filter_foldseek = this.filteritem2;
      }
      this.gaService.eventEmitter(
        'clusters', 'structure_similarity', 'click', 'cluster_paginate_foldseek', 'Clicks on the "Foldseek pagination" button in the cluster members table'
      );
      this.getClusterFoldseekData(this.recordsfoldseek, start, this.sort_direction_foldseek, this.sort_column_foldseek, species_name_filter_foldseek);
    }
  }

  getApiUrl(flag, records, start, sort_direction, sort_column, species_name_filter){
    let apiUrl = "";
    const configUrl = this.configService.getConfig().clusterApiUrl ? this.configService.getConfig().clusterApiUrl : "https://staging.alphafold.ebi.ac.uk/api/cluster/members/";
    
   // const configUrl = "https://af-api-cluster-members-branch-bhzm55mtsq-nw.a.run.app/cluster/members/";

    apiUrl = `${configUrl}${this.accession}?cluster_flag=${flag}`;
    
    if(records){
      apiUrl = `${apiUrl}&records=`+records;
    }

    if(start){
      apiUrl = `${apiUrl}&start=${start}`;
    }

    if(sort_direction && sort_direction != ""){
      apiUrl = apiUrl + '&sort_direction='+sort_direction;
    }

    if(sort_column && sort_column != ""){
      apiUrl = apiUrl + '&sort_column='+sort_column;
    }

    if(species_name_filter && species_name_filter != ""){
      apiUrl = apiUrl + '&species_name_filter='+species_name_filter;
    }

    return apiUrl;
  }

  getClusterMmSeqData(records="", start="", sort_direction, sort_column, species_name_filter="") {

    const auth = this.configService.getConfig().apiKey ? `&key=${this.configService.getConfig().apiKey}` : "";
    const apiUrl = this.getApiUrl("AFDB50%2FMMseqs2", records, start, sort_direction, sort_column,species_name_filter);

    this.commonService.getApiData(apiUrl + auth, { isInternal: true })
      .subscribe(data => {
        if(data.clusterTotal > 0){
          this.hasMMSeqData = 1;
          this.totalMmSeq = data.clusterTotal;
          const tempMmSeqData = data.clusterMembers ? data.clusterMembers : {};
          const similarStructure = this.transformClusterData(tempMmSeqData);
          this.mmSeqDataSource = new MatTableDataSource<SimilarStructure>(similarStructure);
          this.changeDetectorRef.detectChanges();          
          this.mmSeqDataSource.paginator = this.paginator1;
        }else{
          this.handlemmSeqError(species_name_filter);
        }   
      },
      err => {
        this.handlemmSeqError(species_name_filter);
      });
  }

  getClusterFoldseekData(records="", start="", sort_direction, sort_column, species_name_filter=""){
    const auth = this.configService.getConfig().apiKey ? `&key=${this.configService.getConfig().apiKey}` : "";
   
    const apiUrl = this.getApiUrl("AFDB%2FFoldseek", records, start, sort_direction, sort_column,species_name_filter);
   
    this.commonService.getApiData(apiUrl + auth, { isInternal: true })
    .subscribe(data => {
      if(data.clusterTotal > 0){
        this.hasFoldseek = 1;
        this.totalfoldseek = data.clusterTotal;
        const tempFoldseekData = data.clusterMembers ? data.clusterMembers : {};
        const similarStructureFoldSeek = this.transformClusterData(tempFoldseekData);
        this.foldSeekDataSource = new MatTableDataSource<SimilarStructure>(similarStructureFoldSeek);
        this.changeDetectorRef.detectChanges();  
        this.foldSeekDataSource.paginator = this.paginator2;
      }else{
        this.handleFoldseekError(species_name_filter);
      }   
    },
    err => {
      this.handleFoldseekError(species_name_filter);
    });
  }

  handlemmSeqError(species_name_filter){
    this.hasMMSeqData = -1;
    this.totalMmSeq = 0;
    this.mmSeqDataSource = null;
    this.changeDetectorRef.detectChanges();
    if(species_name_filter != ""){
      this.errorMmSeq = "No species match your search";
    }else{
      this.errorMmSeq = "This structure has no cluster members";
    }
  }

  handleFoldseekError(species_name_filter){
    this.hasFoldseek = -1;
    this.totalfoldseek = 0;
    this.foldSeekDataSource = null;
    this.errorfoldseek = "This structure has no cluster members.";
    this.changeDetectorRef.detectChanges();
    if(species_name_filter != ""){
      this.errorfoldseek = "No species match your search";
    }else{
      this.errorfoldseek = "This structure has no cluster members";
    }
  }

  transformClusterData(data) {
    const similarClusterData = [];
    if(data){
      for (let i = 0; i < data["afdbAccessions"].length; i++) {
        if(data["afdbAccessions"][i]) {
          similarClusterData.push({
            "afdbAccessions": this.getTitle(data["afdbAccessions"][i], data["reviewedStatus"][i], data["referenceLabel"][i]),
            "uniprotDescriptions": data["uniprotDescriptions"][i] ? data["uniprotDescriptions"][i] : "Unavailable",
            "speciesNames": data["speciesNames"][i] ? data["speciesNames"][i] : "Unavailable",
            "sequenceLength": data["sequenceLength"][i] ? data["sequenceLength"][i] : "Unavailable",
            "averagePlddt": data["averagePlddt"][i] ? data["averagePlddt"][i] : "Unavailable"
          });
        }
      }
    }
    return similarClusterData;
  }

  getTitle(accession, reviewedStatus, referenceLabel) {
    let tempTitle = "Unavailable";
      
    if(accession){
      //tempTitle = accession ;
      if(reviewedStatus && referenceLabel) {
        tempTitle =  `${accession}*${referenceLabel}*${reviewedStatus}`;
      }
      if(reviewedStatus && !referenceLabel){
        tempTitle =  `${accession}*${referenceLabel}*${reviewedStatus}`;
      }
      if(!reviewedStatus && referenceLabel){
        tempTitle =  `${accession}*${referenceLabel}*${reviewedStatus}`;
      }
      if(!reviewedStatus && !referenceLabel){
        tempTitle =  `${accession}*${referenceLabel}*${reviewedStatus}`;
      }
    }

    return tempTitle;
  }

  createAccessionWithImg(accession){
    return this.sanitizer.bypassSecurityTrustHtml(accession);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    const tabLable = tabChangeEvent.tab.textLabel;
    if(tabLable.includes("AFDB50/MMseqs2")){
      this.gaService.eventEmitter(
        'clusters', 'structure_similarity', 'click', 'mmseqs_tab', 'Clicks on the "AFDB50/MMseqs2" tab in the cluster members table'
      );
    }
    if(tabLable.includes("AFBD/Foldseek")){
      this.gaService.eventEmitter(
        'clusters', 'structure_similarity', 'click', 'foldseek_tab', 'Clicks on the "AFDB/Foldseek" tab in the cluster members table'
      );
    }
  }
}

