import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-sequence-card',
  templateUrl: './sequence-card.component.html',
  styleUrls: ['./sequence-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SequenceCardComponent {
  @Input() hsps: any;
  @Input() isExpandStructure: boolean;

  isShowingAll: boolean = false;
  islinkShow: boolean = false;
  isLinkedClicked: boolean = false;
  canShowSingle: boolean = true;
  canShowAll: boolean = false;
  showfullclass: string = '';
  showlessclass: string = '';
  res: any;
  match_sequence: string = '';
  sequenceSliceLimit = 60;
  structureSliceLimit = 80;
  constructor(   private commonService: CommonService,) { }

  ngOnInit() {
    this.res = this.hsps;
    if(this.res && this.res.query_sequence.length > this.sequenceSliceLimit){
      this.isShowingAll = true;
    }
    this.match_sequence = this.res.match_sequence;
    if(this.isExpandStructure){
      this.match_sequence = this.commonService.formatAlnDiff(this.res.query_sequence, this.res.match_accession);
    }
  }

  getLabelValue(label, stats) {
    let valueoflable = null; 
    for (const stat of stats) {
      // Check if the label matches the label of the current match stat
      if (stat.label === label) {
        valueoflable = stat.value;
      }
    }
    return valueoflable;
  }

  switchTexttoFull(){
    this.isLinkedClicked ? this.isLinkedClicked = false : this.isLinkedClicked = true;
    this.canShowSingle = false;
    this.canShowAll = true;
    if(this.isShowingAll) {
      this.isShowingAll = false;
    }else{
      this.isShowingAll = true;
    }
  }

  switchTexttoLess(){
    this.isLinkedClicked ? this.isLinkedClicked = false : this.isLinkedClicked = true;
    this.canShowSingle = true;
    this.canShowAll = false;
    if(this.isShowingAll) {
      this.isShowingAll = false;
    }else{
      this.isShowingAll = true;
    }
  }

  greaterThanLimit(query){
    const isGreater = query.length <= this.sequenceSliceLimit ? false : true;
    return isGreater;

  }

  countDashes(query: string, flag): number {
    if(flag === "single"){
      return (query.match(/-/g) || []).length;
    }
    else {
      const sliceQuery = query.slice(0,this.sequenceSliceLimit)
      return (sliceQuery.match(/-/g) || []).length;
    }
  }

  queryLengthWithoutDash(query: string): number {
    const gaps = this.countDashes(query, "single");
    return query.length-gaps;
  }

  getStringsChunksForStructure(qstart, query, tstart, targetquery, chunk_size = this.sequenceSliceLimit) {
    if(query.length < this.sequenceSliceLimit){
      this.isShowingAll = true;
      this.islinkShow = true;
    }

    let chunks = [];
    let currentQStart = qstart;
    let currentTStart = tstart;
    let index = 1;
    // Instead of using the `while` loop, we can use the `for` loop to iterate over the `queries` array.
    for (let i = 0; i < query.length; i += chunk_size) {

      let query_chunk = query.slice(i, i + chunk_size);
      let target_chunk = targetquery.slice(i, i + chunk_size);
      let dashCount = (query_chunk.match(/-/g) || []).length;
      let qcounttoshow;

      if (query_chunk.length < chunk_size) {
        qcounttoshow = currentQStart + (query_chunk.length - dashCount) - 1;
      } else {
        qcounttoshow = currentQStart + (chunk_size - dashCount) - 1;
      }

      let tcounttoshow;
      let tdashCount = (target_chunk.match(/-/g) || []).length;
      if (target_chunk.length < chunk_size) {
        tcounttoshow = currentTStart + (target_chunk.length - tdashCount) - 1;
      } else {
        tcounttoshow = currentTStart + (chunk_size - tdashCount) - 1;
      }

      let counttoshow;
      if (query.length < chunk_size) {
        counttoshow = query.length
      } else {
        counttoshow = chunk_size
      }
      
      chunks.push({
        qstart: currentQStart,
        query_chunk: query_chunk,
        qcounttoshow: qcounttoshow,
        tstart: currentTStart,
        target_chunk: target_chunk,
        tcounttoshow: tcounttoshow,
        "start": index * chunk_size - chunk_size + 1,
        "counttoshow": (index-1)*chunk_size + counttoshow,
      });
      index += 1
      currentQStart = qcounttoshow + 1;
      currentTStart = tcounttoshow + 1;
    }
    return chunks;
  }

  getStringsChunks(query, chunk_size = this.sequenceSliceLimit) {
    if(query.length < this.sequenceSliceLimit){
      this.isShowingAll = true;
      this.islinkShow = true;
    }

    let chunks = [];
    let index = 1;
    let queries;
    if(this.sequenceSliceLimit === 60){
       queries = query.match(/.{1,60}/g);
    }
    // Instead of using the `while` loop, we can use the `for` loop to iterate over the `queries` array.
    for (let query of queries) {
      let counttoshow;
      if (query.length < chunk_size) {
        counttoshow = query.length
      } else {
        counttoshow = chunk_size
      }
      chunks.push({
        "start": index * chunk_size - chunk_size + 1,
        "query_chunk": query,
        "counttoshow": (index-1)*chunk_size + counttoshow,
      })
      index += 1
    }
    return chunks;
  }
  
}
