<div class="row">
  <div class="columns small-12 medium-10 medium-offset-1">
    <h2 class="page-heading">About</h2>
  </div>
</div>

<div class="row">
  <div class="columns small-12 medium-10 medium-offset-1">
    <nav class="about-nav">
      <ul>
        <li><a href="./about#nav-content-1">What are proteins?</a></li>
        <li><a href="./about#nav-content-2">What are protein structures?</a></li>
        <li><a href="./about#nav-content-3">Why does being able to predict a protein's 3D structure matter?</a></li>
        <li><a href="./about#nav-content-4">How can AI and AlphaFold help?</a></li>
        <li><a href="./about#nav-content-5">How can we make the most of the data?</a></li>
        <li><a href="./about#nav-content-6">About Google DeepMind</a></li>
        <li><a href="./about#nav-content-7">About EMBL-EBI</a></li>
      </ul>
    </nav>
  </div>
</div>

<section class="about-intro">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <p class="large-text">
        Predicting the 3D structure of proteins is one of the
        <a href="https://en.wikipedia.org/wiki/Protein_folding" target="_blank">
          fundamental grand challenges in biology</a>. By solving this challenge, we can
         dramatically deepen our understanding of human health, disease, and our environment, especially within areas like drug design and sustainability.
      </p>
      <p class="normal-text"><br>AlphaFold, the state-of-the-art AI system developed by Google DeepMind, is able to
        computationally predict protein structures with unprecedented accuracy and speed. Working in partnership with
        <a (click)="gaService.eventEmitter(
        'link_visit', 'about_href_click', 'click', 'embl', '')"
           href="https://www.ebi.ac.uk" target="_blank">EMBL’s European
          Bioinformatics Institute (EMBL-EBI)</a>, we’ve released over 200 million protein structure
        predictions by AlphaFold that are freely and openly available to
        the global scientific community.
        Included are nearly all catalogued proteins known to science – with the potential to increase humanity’s understanding of biology by orders of magnitude. </p>
        <p class="normal-text"><a (click)="gaService.eventEmitter(
        'link_visit', 'about_href_click', 'click', 'unfolded', '')" href="https://unfolded.deepmind.com/" target="_blank">Explore stories of AlphaFold’s impact</a></p>
    </div>
  </div>
</section>

<section class="about-paper">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3 class="section-heading">Paper & methodologies</h3>
      <p>
        The paper describing the AlphaFold method is:<br>
        <a href="https://www.nature.com/articles/s41586-021-03819-2" target="_blank"
           (click)="gaService.eventEmitter(
      'link_visit',
      'about_href_click',
      'click',
      'methods_paper',
      '')">
          Jumper, J <i>et al</i>. Highly accurate protein structure prediction with AlphaFold (<i>Nature</i> 2021).</a>
        <br><br><br>
        For a discussion of AlphaFold’s output when applied to a whole proteome, see:<br>
        <a href="https://www.nature.com/articles/s41586-021-03828-1" target="_blank">Tunyasuvunakool, K <i>et al</i>. Highly accurate protein structure prediction for the human proteome
          (<i>Nature</i> 2021).</a>
      </p>
    </div>
  </div>
</section>

<section class="nav-content-section" id="nav-content-1">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3 class="nav-content-heading">What are proteins?</h3>

      <div class="row video-row">
        <div class="small-12 medium-10" style="position: relative;">
          <iframe class="about-video-embed"
                  src="https://www.youtube.com/embed/KpedmJdrTpY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  (click)="gaService.eventEmitter(
      'video',
      'about_video_click',
      'click',
      'video',
      '')">
          </iframe>
        </div>
      </div>

      <p class="large-text">
        Inside every cell in your body, billions of tiny molecular machines are hard at work. They’re what allow your
        eyes to detect light, your neurons to fire, and the ‘instructions’ in your DNA to be read, which make you the
        unique person you are.
        <br><br>
        These <a href="https://www.youtube.com/watch?v=y-uuk4Pr2i8" target="_blank" (click)="gaService.eventEmitter(
        'video', 'about_video_click', 'click', 'video', '')">exquisite, intricate machines</a>
        are
        <a href="https://en.wikipedia.org/wiki/Protein" target="_blank">proteins</a>. They underpin not just the
        biological processes in your body but
        every biological process in every living thing. They’re the building blocks of life.
      </p>
    </div>
  </div>
</section>

<section class="nav-content-section" id="nav-content-2">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3 class="nav-content-heading">What are protein structures?</h3>
      <div class="row">
        <div class="columns small-12 medium-6 medium-push-6 nav-col">
          <p class="large-text">
            Currently, there are around <a href="https://www.ebi.ac.uk/uniprot/TrEMBLstats" target="_blank">230 million
            proteins</a> in UniProt, with millions more catalogued every year. Each one has a
            unique 3D shape that determines how it works and what it does. AlphaFold DB now offers a prediction of what
            each of the over 200 millions known proteins in UniProt might look like.
          </p>
        </div>
        <div class="columns small-12 medium-6 medium-pull-6 nav-col">
          <img src="assets/img/about1.png">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="nav-content-section">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <div class="row">
        <div class="columns small-12 medium-6 nav-col">
          <p>
              <span class="large-text">
              If you could unravel a protein you would see that it’s like a string of beads made of a sequence of
                different chemicals known as amino acids. These sequences are assembled according to the genetic
                instructions of an organism's DNA.
              <br>
              <br>
              </span>
            <span class="normal-text">
              Attraction and repulsion between the 20 different types of amino acids cause the string to fold in a
              feat of ‘spontaneous origami’, forming the intricate curls, loops, and pleats of a protein’s 3D structure.
              </span>
          </p>
        </div>
        <div class="columns small-12 medium-6 nav-col">
          <img src="assets/img/about2.png">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="nav-content-section">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <div class="row">
        <div class="columns small-12 medium-6 medium-push-6 nav-col">
          <p>
            <span class="normal-text">
              For decades, scientists have been trying to find a method to reliably predict a protein’s
              structure just from its sequence of amino acids.<br><br>
            </span>
            <span class="normal-text">
              This grand scientific challenge is known as the protein folding problem.
            </span>
          </p>
        </div>
        <div class="columns small-12 medium-6 medium-pull-6 nav-col">
          <img src="assets/img/about3.png">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="nav-content-section" id="nav-content-3">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3 class="nav-content-heading">Why does being able to predict a protein’s 3D structure matter?</h3>
      <p class="large-text">
        In the last 60 years, the scientific community has been using advanced experimental methods to determine the
        structures of over 180,000 proteins in atomic detail. This work has already improved our understanding of many
        fundamental processes in health and disease:
        <br><br>
      </p>
      <ul class="normal-text">
        <li class="text_li">
          Determining the structure of haemoglobin, the protein in red blood cells responsible for carrying oxygen around
          the body, helped researchers understand how a single mutation can cause sickle-cell anaemia, helping develop
          treatments for the condition.
        </li>
        <li class="text_li">Determining the structure of the SARS-CoV-2 viral proteins enabled scientists to understand how it operates
          and to identify treatments and develop new vaccines.
        </li>
        <li class="text_li">Determining the structure of the photosynthetic reaction centre improved researchers’ understanding of how
          photosynthesis works in plants.
        </li>
      </ul>
      <p class="normal-text">
        <br>
        But figuring out the exact structure of a protein remains an expensive and often time-consuming process,
        meaning we only know the exact 3D structure of a tiny fraction of the proteins known to science.
      </p>
    </div>
  </div>
</section>

<section class="nav-content-section" id="nav-content-4">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3 class="nav-content-heading">How can AI and AlphaFold help?</h3>
      <p>
        <span class="large-text">
          Finding a way to close this rapidly expanding gap and predict the structure of millions of unknown proteins
          could not only help us tackle disease and more quickly find new medicines but ultimately better understand
          life itself. This is why biologists are turning to AI methods: the ability to predict a protein’s shape
          computationally from its genetic code alone – as a complementary alternative to determining it through
          costly and time-consuming experimentation – could help dramatically accelerate research.
          <br><br>
        </span>
        <span class="normal-text">
          AlphaFold is an AI system developed by Google DeepMind that makes state-of-the-art accurate predictions of a protein’s structure
          from its amino-acid sequence. In 2020, AlphaFold was recognised as a solution to the protein folding problem by
          the organisers of the <a href="https://predictioncenter.org/casp14/zscores_final.cgi" target="_blank">CASP14
          benchmark</a>, a biennial challenge for research groups to test the accuracy of their
          predictions against real experimental data.
          <br><br>
          AlphaFold’s unprecedented accuracy and speed has enabled the creation of an extensive database of structure
          predictions, and opens up the potential for scientists to use computational structure prediction as a core
          tool in their research.
        </span>
        <br><br>
        <span class="normal-text">
          <a class="search-help-link vf-section-header__heading--is-link" href="https://www.ebi.ac.uk/training/online/courses/alphafold/ " target="_blank"
           (click)="gaService.eventEmitter('link_visit', 'training_href_click', 'click', 'training_about_page_link', 'Clicks on the training link on the about page')">
          <span style="display: inline-block; vertical-align: middle;">Visit our online training course</span>
          <span style="display: inline-block; vertical-align: middle; padding-top: 2px">
            <svg aria-hidden="true" class="vf-section-header__icon | vf-icon vf-icon-arrow--inline-end" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0C5.376.008.008 5.376 0 12zm13.707-5.209l4.5 4.5a1 1 0 010 1.414l-4.5 4.5a1 1 0 01-1.414-1.414l2.366-2.367a.25.25 0 00-.177-.424H6a1 1 0 010-2h8.482a.25.25 0 00.177-.427l-2.366-2.368a1 1 0 011.414-1.414z" fill="#3B6FB6" fill-rule="nonzero"></path>
            </svg>
          </span>
        </a>
        </span>
      </p>
    </div>
  </div>
</section>

<section class="nav-content-section" id="nav-content-5">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3 class="nav-content-heading">How can we make the most of the data?</h3>
      <p>
        <span class="large-text">
          A ‘mountain’ of new scientific data is not necessarily useful. To extract knowledge from
          scientific data you have to be able to search it in a variety of ways, to compare it and to analyse it.
          <br><br>
        </span>
        <span class="normal-text">
          EMBL-EBI is a global leader in the storage, analysis and dissemination of large biological research datasets.
          The institute manages over 40 biological data resources, used by the global scientific community.
          On any given day, millions of scientists use these data resources to advance their work.
          <br><br>
          Building on its big-data expertise, EMBL-EBI adds value to the AlphaFold dataset by curating and organising it,
          and linking it to other biological data resources such as the <a href="https://www.pdbe.org" target="_blank"
                                                                           (click)="gaService.eventEmitter(
      'link_visit',
      'about_href_click',
      'click',
      'visit_pdbe',
      '')">
          PDB</a> and <a
          href="https://www.uniprot.org/" target="_blank">UniProt</a>. This way, scientists can explore the structure
          of their protein of interest in a wider biological context. Curating the data in this way increases its
          value and usefulness - similar to the difference between a tonne of bricks and a house.
          <br><br>
          The AlphaFold Protein Structure Database will continue to be improved and expanded in the future,
          adding more protein structures and functionalities. If you would like to learn more,
          you can read our
          <a href="https://deepmind.com/blog/article/putting-the-power-of-alphafold-into-the-worlds-hands"
             target="_blank" (click)="gaService.eventEmitter(
      'link_visit',
      'about_href_click',
      'click',
      'dm_blog',
      '')">blog post about the AlphaFold DB</a>.
        </span>
      </p>
    </div>
  </div>
</section>

<section class="nav-content-section" id="nav-content-6">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3 class="nav-content-heading">About Google DeepMind</h3>
      <p>
        <span class="large-text">
          Google DeepMind is a scientific discovery company, committed to ‘building AI responsibly to benefit humanity.’ Achieving this goal requires a diverse and interdisciplinary team working closely together – from scientists and designers, to engineers and ethicists – to pioneer the development of advanced artificial intelligence.
          <br><br>
        </span>
        <span class="normal-text">
          The company’s breakthroughs include <a
          href="https://deepmind.com/research/case-studies/alphago-the-story-so-far" target="_blank">AlphaGo</a>,
          <a href="https://deepmind.com/research/case-studies/alphafold" target="_blank">AlphaFold</a>, more than <a href="https://deepmind.google/research/publications/" target="_blank">1,000 publications. </a>
          By solving some of the hardest scientific and engineering challenges of our time, GDM is working to create breakthrough technologies that could advance science, transform work, serve diverse communities — and improve billions of people’s lives.
        </span>
      </p>
    </div>
  </div>
</section>

<section class="nav-content-section" id="nav-content-7">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3 class="nav-content-heading">About EMBL-EBI</h3>
      <p>
        <span class="normal-text">
          EMBL-EBI is a not-for-profit international institute that helps scientists realise the potential of big data.
          The institute collaborates with scientists and engineers all over the world, and provides the infrastructure
          needed to share data openly and fairly in the life sciences. It also performs computational research and
          delivers bioinformatics training for the global scientific community. EMBL-EBI is part of the <a
          href="https://www.embl.org/" target="_blank" (click)="gaService.eventEmitter(
      'link_visit',
      'about_href_click',
      'click',
      'embl',
      '')">European Molecular Biology
          Laboratory (EMBL)</a>.
        </span>
      </p>
    </div>
  </div>
</section>
