import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {

  expandAll: any;
  defaultOpen: number[]; // = [0, 1, 2, 3];

  constructor() {
    this.expandAll = {
      state: true,
      expandAllBtn: false
    };
  }

  toggleAll(): void {
    this.expandAll = {
      state: !this.expandAll.state,
      expandAllBtn: true
    };
  }

  updateExpand(status): void {
    this.expandAll = {
      state: status,
      expandAllBtn: false
    };
  }

}
