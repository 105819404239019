import { Component, EventEmitter, Output } from '@angular/core';
import {GoogleAnalyticsService} from '../google-analytics.service';

@Component({
  selector: 'app-news-popup',
  templateUrl: './news-popup.component.html',
  styleUrls: ['./news-popup.component.css']
})
export class NewsPopupComponent {

  @Output() showLinkClick:EventEmitter<string> = new EventEmitter();

  seenInfo: string = "false";

  constructor(public gaService: GoogleAnalyticsService) {}
  ngOnInit(): void {
    this.seenInfo = localStorage.getItem('newsSeen');
  }
  dismissModal() {
    localStorage.setItem("newsSeen", "true");
    this.seenInfo = 'true';
    this.gaService.eventEmitter(
      'new_feature_popup_close',
      'new_feature_popup',
      'click',
      'new_feature_popup_close',
      'Click on cross icon to close the pop-up');
  }

  showClick(e) {
    e.preventDefault();
    this.gaService.eventEmitter(
      'new_feature_popup_click',
      'new_feature_popup',
      'click',
      'Foldseek_new_feature_popup_click',
      'Click on link button Scroll down to explore');
    this.showLinkClick.emit();
  }
}
