import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'app-home-links',
  templateUrl: './home-links.component.html',
  styleUrls: ['./home-links.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeLinksComponent{
  @Input() findOutMore: any;
  constructor(public gaService: GoogleAnalyticsService) {}

  clickOnLinks(eventname){
    if(eventname === "Online training course"){
      this.gaService.eventEmitter('link_visit', 'training_href_click', 'click', 'training_main_page_link', 'Clicks on the training link on the main page');
    }
  }
}
