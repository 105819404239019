import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckboxService {
  private checkboxState = new BehaviorSubject<{ [key: string]: boolean }>({});
  checkboxState$ = this.checkboxState.asObservable();

  private disabledState = new BehaviorSubject<{ [key: string]: boolean }>({});
  disabledState$ = this.disabledState.asObservable();

  setCheckboxState(id: string, state: boolean) {
    const currentState = { ...this.checkboxState.value };
    currentState[id] = state;
    this.checkboxState.next(currentState);
  }

  resetAllCheckboxes() {
    this.checkboxState.next({});
  }

  setCheckboxDisabledState(id: string, state: boolean) {
    const currentDisabledState = { ...this.disabledState.value };
    currentDisabledState[id] = state;
    this.disabledState.next(currentDisabledState);
  }

  resetAllDisabledStates() {
    this.disabledState.next({});
  }
}