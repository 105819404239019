import { Component, OnInit } from '@angular/core';
import * as SwaggerUIBundle from 'swagger-ui-dist/swagger-ui-bundle';
import * as SwaggerUIStandalonePreset from 'swagger-ui-dist/swagger-ui-standalone-preset';


@Component({
  selector: 'app-swagger',
  templateUrl: './swagger.component.html',
  styleUrls: ['./swagger.component.css']
})
export class SwaggerComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
    const hostname = window.location.hostname
    // const hostname: string = 'test.alphafold.ebi.ac.uk'
    const apiKeyValue: string = 'AIzaSyCeurAJz7ZGjPQUtEaerUkBZ3TaBkXrY94';

    function HideInfoUrlPlugin() {
      return {
        components: {
          InfoUrl: function() { return null }
        }
      }
    }

    const ui = SwaggerUIBundle({
      url: `https://${ hostname }/api/openapi.json?key=${apiKeyValue}`,
      dom_id: '#swagger-ui',
      deepLinking: true,
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIStandalonePreset,
      ],
      syntaxHighlight: {
        activate: true,
        theme: "nord"
      },
      plugins: [
        SwaggerUIBundle.plugins.DownloadUrl,
        HideInfoUrlPlugin
      ],
      layout: "BaseLayout",
      requestInterceptor: (req) => {
        if ('method' in req) {
          const [urlPath, urlParams] = req.url.split('?');
          const apiUrl = urlPath.replace(hostname, `${ hostname }/api`);
          const queryParams = new URLSearchParams(urlParams);
          queryParams.set('key', apiKeyValue);

          const newParams = queryParams.toString();

          // req.url = newParams ? `${apiUrl}?${newParams}` : apiUrl;
          req.url =`${apiUrl}?${newParams}`;
        }
        return req;
      },
    });
  }
}
