<div class="pae-box">
  <span class="pae-x-label">Scored residue</span>
  <span class="pae-y-label">Aligned residue</span>
  <div class="pae-axis-box"></div>
  <div class="pae-img-box">
    <span class="pae-clear-icon" title="Remove selection" (click)="clearSelection()">
      <svg style="width:15px;height:15px;margin:1.5px 0 0 1.5px;" viewBox="0 0 24 24">
        <path fill="#000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
      </svg>
    </span>
    <div class="pae-img-box-border"></div>
    <img class="pae-img" [src]="imageUrl" />
  </div>
  <img class="pae-scale-img-h" src="../../assets/img/horizontal_colorbar.png">
  <ul class="pae-scale-ticks-h">
    <li>0</li>
    <li>5</li>
    <li>10</li>
    <li>15</li>
    <li>20</li>
    <li>25</li>
    <li>30</li>
  </ul>
  <span class="pae-scale-label">Expected position error (&Aring;ngstr&ouml;ms)</span>
</div>