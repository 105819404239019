<div class="row">
  <div class="columns small-12 medium-10 medium-offset-1">
    <div class="faq-section">
      
        <h2 class="page-heading">Frequently asked questions 
        <span (click)="toggleAll()">{{ expandAll.state ? 'Collapse all' : 'Expand all'}}</span>
        </h2>
      
      <app-faqs-list [defaultOpen]="defaultOpen" [expandAll]="expandAll" (expandAllState)="updateExpand($event)"></app-faqs-list>
    </div>
  </div>
</div>
