
<div *ngIf="hasData == -1" class="row" id="loading">
  <div class="small-12 columns" style="text-align: center; margin: 90px 0">
    <img src="../../assets/img/loader.gif" />
  </div>
</div>

<div *ngIf="hasData == 0" class="row" id="not-found">
  <div class="small-11 small-offset-1 columns">
    <h1>Error: 404</h1>
    <h2>We’re sorry - we can’t find the page or file you requested.</h2>
    <p>
      We’re sorry - we can’t find the page or file you requested.<br />
      It may have been removed, had its name changed, or be temporarily
      unavailable.<br />
      You might try searching for it or go <a href="/">back to homepage</a>.
    </p>
  </div>
</div>

<div *ngIf="hasData == 1" #newsbox class="news-box">
  <app-news-popup (showLinkClick)="newsShowLinkClick($event)"></app-news-popup>
</div>

<div class="row entrySummary" *ngIf="hasData == 1">
  <div class="columns small-12">
    <app-summary-text [accession]="accession" [data]="summaryData" [csvAmData]="csvAmData" [csvAMFileUrl]="csvAMFileUrl" [entryData]="entryData" (click)="
        gaService.eventEmitter('summary', 'summary', 'click', accession, undefined)"
    ></app-summary-text>
  </div>
</div>

<div class="row threedViewer" *ngIf="hasData == 1">
  <div class="columns structureTitleBox">
    <div class="filledBox structurestart">
      <span class="title" id="structurestart">Structure viewer</span>
    </div>
  </div>  
</div>

<div class="row threedViewer" *ngIf="hasData == 1">
  <app-structure-viewer (selectedToggle)="toggleUpdateFlags($event)" [isModalConf]="isModalConf" [isAlphaMis]="isAlphaMis" [accession]="accession" [csvAmData]="csvAmData" [isShowModelLegendsText]="isShowModelLegendsText" [isShowModelLegends]="isShowModelLegends" [pdbeMolstar]="pdbeMolstar"> </app-structure-viewer>
  <div class="columns viewTitleBox2pae">
    <app-pae-view *ngIf="isSummaryLoaded && entryData.paeImageUrl" [accession]="accession"
                  [imageUrl]="entryData.paeImageUrl" [length]="entryData.uniprotEnd"
                  (selectedRegion)="cmSelectionUpdate($event)"
                  (click)="gaService.eventEmitter('pae-click', 'pae', 'click', accession, undefined)">
    </app-pae-view>
    <div class="columns paeDescription modalspace">
      <p class="title-pae">Predicted aligned error (PAE)</p>
      <p class="desc">Click and drag a box on the PAE viewer to select regions of the structure and highlight them on the 3D viewer. </p>
      <p class="desc">PAE data is useful for assessing inter-domain accuracy – 
        <a [routerLink]="['./']" fragment="help" target="_self">go to Help section below</a> for more information.</p>
    </div>
  </div>
</div>

<div id="heatmapends"></div>
<app-heatmap [accession]="accession" [amAnnotationsHg19Url]="amAnnotationsHg19Url" [amAnnotationsHg38Url]="amAnnotationsHg38Url" [csvAMFileUrl]="csvAMFileUrl" [heatmapSequence]="heatmapSequence" [csvAmData]="csvAmData" (selectedCategory)="amSelectionCategory($event)" (selectedRegion)="amSelectionUpdate($event)"(clearRegion)="amSelectionClear()" *ngIf="csvAmData"></app-heatmap>
<app-structure-table [afdb_accession]="afdb_accession" [accession]="accession" *ngIf="hasData == 1"></app-structure-table>

<app-cluster-table [accession]="accession" *ngIf="hasData == 1"></app-cluster-table>

<app-entry-help [accession]="accession"  *ngIf="hasData == 1"></app-entry-help>