import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent} from './about/about.component';
import { DownloadComponent } from './download/download.component';
import { EntryComponent } from './entry/entry.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import {SwaggerComponent} from "./swagger/swagger.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'download', component: DownloadComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'api-docs', component: SwaggerComponent},
  {path: 'entry/:id/:test', component: EntryComponent},
  {path: 'entry/:id', component: EntryComponent},
  {path: 'search/:field/:term', component: SearchComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: "top", anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
