<div *ngIf="!loadComplete" class="row" id="loading">
  <div class="small-12 columns" style="text-align: center; margin: 90px 0;">
    <img src="../../assets/img/loader.gif" alt="">
  </div>
</div>
<div *ngIf="loadComplete">

  <!-- Title -->
  <div class="row" *ngIf="!isSearchprogress && mainResultData">
    <div class="columns small-12 searchTitle" *ngIf="!isSequenceSearch">
      <h2 id="resultsTitle">Showing all search results for <strong>{{commonService.decodeURIValue(searchTerm)}}</strong></h2>
      <p class="titleSubtext" *ngIf="loadComplete">{{getResultCountText()}}</p>
    </div>
    <!-- this is for sequence search  -->
    <div class="columns small-12 searchTitle" *ngIf="isSequenceSearch" >
      <h2>Showing all search results for <strong style="word-wrap: break-word;"> {{commonService.decodeURIValue(searchTerm).length > 60 ? commonService.decodeURIValue(searchTerm).slice(0, 60) + "..." : commonService.decodeURIValue(searchTerm)}}</strong></h2>

      <div style="display: flex;">
        <div class="title-tooltip" style="display: inline;">
        <p class="titleSubtext" *ngIf="loadComplete">{{getResultCountText()}} from BLASTP
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 9C1.5 4.86 4.86 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.86 16.5 1.5 13.14 1.5 9ZM9.75 12V13.5H8.25V12H9.75ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM6 7.5C6 5.8425 7.3425 4.5 9 4.5C10.6575 4.5 12 5.8425 12 7.5C12 8.46219 11.4075 8.97999 10.8306 9.48415C10.2833 9.96245 9.75 10.4285 9.75 11.25H8.25C8.25 9.88404 8.95659 9.34244 9.57783 8.86626C10.0652 8.49271 10.5 8.15941 10.5 7.5C10.5 6.675 9.825 6 9 6C8.175 6 7.5 6.675 7.5 7.5H6Z" fill="#54585A"/>
            </svg>
          </span>

          <span class="title-tooltip-text">
            <b>Protein BLAST</b>  <br/>
            Protein sequence identification and similarity search tool. Given a sequence query, it returns a list of the most similar sequences which have corresponding predicted structures in the AlphaFold database.
          </span>
        </p>

      </div>

      </div>


    </div>
  </div>

  <!-- Search Main Section -->
  <div class="row searchMain">

    <!-- Facet / Filters -->
    <div class="columns small-12 medium-3" *ngIf="loadComplete && mainResultData">

      <legend class="vf-form__legend show-for-small-only" style="font-size: 21px; cursor: pointer;"
              (click)="toggleFilterList()" *ngIf="filterData">
        Filter by:

        <svg style="width:24px;height:24px;position: absolute;right: 16px;" viewBox="0 0 24 24">
          <path *ngIf="!isFilterListOpen" fill="currentColor"
                d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
          <path *ngIf="isFilterListOpen" fill="currentColor"
                d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"/>
        </svg>

      </legend>
      <div class="row column filterTitle" style="display: flex; align-items: center; margin-bottom: 20px;">
        <legend class="vf-form__legend hide-for-small-only" style="font-size: 21px;">Filter by:</legend>
        <a *ngIf="areFiltersActives()" (click)="clearFilters()" class="resetLink">Reset filters</a>
      </div>


      <div class="row column filterList">
        <!-- TODO: Extract to components or use ng-for  Split to mange individually -->
        <!--   isReviewed section     -->
        <fieldset class="vf-stack vf-stack--400" *ngIf="existField('isReviewed')">
          <legend class="vf-form__legend">Status</legend>
          <legend class="vf-form__sub_legend">
            {{filterLabels['isReviewed']}}
            <div class="label-tooltip" style="display: inline;">
              <span class="label-tooltip-text hide-for-small-only" style="font-size: 14px; font-weight: 400; text-align: left;">
                This status indicates whether the entry has been manually annotated and reviewed by UniProtKB curators or not.
                In other words, if the entry belongs to the Swiss-Prot section of UniProtKB (reviewed) or to the computer-annotated TrEMBL section (unreviewed).
              </span>
              <span class="msp-icon msp-material-icon" style="cursor: help; position: relative; right: 0; top: 3px;">
                <svg width="15px" height="15px" viewBox="0 0 24 24">
                  <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
                </svg>
              </span>
            </div>
          </legend>
          <div class="facetItemContainer">
            <ul class="vf-list" *ngFor="let facetField of filterData[0].facet_fields['isReviewed']| keyvalue: commonService.keepDefaultOrder; index as facetIndex;">
              <li class="vf-list__item-facet" *ngIf="isFacetActive('isReviewed', facetField.key.toString()); else facetSelected">
                <a class="vf-link-visited"
                   (click)="facetActionLink('isReviewed', facetField.key.toString())">
                  <div style="display: flex; justify-content: space-between;">
                    <span style="width:90%">
                      <img  *ngIf="facetField.key === 'true'" class="badge-svg" src="assets/img/reviewed.svg" />
                      <img *ngIf="facetField.key === 'false'" class="badge-svg" src="assets/img/unreviewed.svg" />
                      {{ facetField.key === 'true' ? 'Reviewed (Swiss-Prot)' : 'Unreviewed (TrEMBL)' }} ({{facetField.value}})
                    </span>
                    <svg style="margin-top:7px;" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_136_12586)">
                        <path d="M7.69679 6.92481C7.64091 6.86908 7.59658 6.80287 7.56633 6.72997C7.53609 6.65708 7.52052 6.57893 7.52052 6.50001C7.52052 6.42109 7.53609 6.34295 7.56633 6.27005C7.59658 6.19716 7.64091 6.13095 7.69679 6.07521L11.736 2.03601C11.895 1.8654 11.9815 1.63975 11.9774 1.40658C11.9733 1.17342 11.8788 0.950961 11.7139 0.786065C11.549 0.621169 11.3266 0.526715 11.0934 0.522601C10.8603 0.518487 10.6346 0.605035 10.464 0.764011L6.42479 4.80321C6.36905 4.85909 6.30284 4.90342 6.22995 4.93367C6.15705 4.96391 6.07891 4.97948 5.99999 4.97948C5.92107 4.97948 5.84292 4.96391 5.77003 4.93367C5.69713 4.90342 5.63092 4.85909 5.57519 4.80321L1.53599 0.764011C1.36538 0.605035 1.13972 0.518487 0.906562 0.522601C0.673401 0.526715 0.450938 0.621169 0.286043 0.786065C0.121147 0.950961 0.0266929 1.17342 0.022579 1.40658C0.0184652 1.63975 0.105013 1.8654 0.263989 2.03601L4.30319 6.07521C4.35906 6.13095 4.4034 6.19716 4.43364 6.27005C4.46389 6.34295 4.47946 6.42109 4.47946 6.50001C4.47946 6.57893 4.46389 6.65708 4.43364 6.72997C4.4034 6.80287 4.35906 6.86908 4.30319 6.92481L0.263989 10.964C0.175565 11.0464 0.104642 11.1458 0.0554518 11.2562C0.00626146 11.3666 -0.0201887 11.4857 -0.0223209 11.6066C-0.024453 11.7274 -0.00222342 11.8475 0.0430418 11.9595C0.088307 12.0716 0.15568 12.1734 0.241143 12.2589C0.326605 12.3443 0.428406 12.4117 0.540472 12.457C0.652538 12.5022 0.772573 12.5245 0.893416 12.5223C1.01426 12.5202 1.13344 12.4937 1.24383 12.4445C1.35423 12.3954 1.45359 12.3244 1.53599 12.236L5.57519 8.19681C5.63092 8.14094 5.69713 8.0966 5.77003 8.06636C5.84292 8.03611 5.92107 8.02054 5.99999 8.02054C6.07891 8.02054 6.15705 8.03611 6.22995 8.06636C6.30284 8.0966 6.36905 8.14094 6.42479 8.19681L10.464 12.236C10.6346 12.395 10.8603 12.4815 11.0934 12.4774C11.3266 12.4733 11.549 12.3789 11.7139 12.214C11.8788 12.0491 11.9733 11.8266 11.9774 11.5934C11.9815 11.3603 11.895 11.1346 11.736 10.964L7.69679 6.92481Z" fill="#563D82"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_136_12586">
                          <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </a>
              </li>
              <ng-template #facetSelected>
                <a class="vf-link"
                (click)="facetActionLink('isReviewed', facetField.key.toString())"
                [routerLinkActive]="">
                <span>
                  <img *ngIf="facetField.key === 'true'" class="badge-svg" src="assets/img/reviewed.svg" />
                  <img *ngIf="facetField.key === 'false'" class="badge-svg" src="assets/img/unreviewed.svg" />
                  {{ facetField.key === 'true' ? 'Reviewed (Swiss-Prot)' : 'Unreviewed (TrEMBL)' }} ({{facetField.value}})
                </span>
                </a>
              </ng-template>
            </ul>
          </div>
        </fieldset>
        <!--   referenceProteom section     -->
        <fieldset class="vf-stack vf-stack--400 reviewFilter" *ngIf="existField('isReferenceProteome')">
          <legend class="vf-form__sub_legend">
            {{filterLabels['isReferenceProteome']}}
          </legend>
          <div class="facetItemContainer facetItemContainer-divider">
            <div class="vf-form__item vf-form__item--checkbox"
                 *ngFor="let facetField of filterData[0].facet_fields['isReferenceProteome']| keyvalue: commonService.keepDefaultOrder; index as facetIndex;">
              <input
                type="checkbox"
                name="{{facetField.value}}"
                id="{{facetField.key}}_{{facetIndex}}"
                value="{{facetIndex}}"
                class="vf-form__checkbox"
                [checked]="isFacetActive('isReferenceProteome', facetField.key.toString())"
                (change)="facetAction('isReferenceProteome', facetField.key.toString(), $event.target.checked)"
                (click)="isReferenceOnclick(facetField.key.toString())">
              <label for="{{facetField.key}}_{{facetIndex}}" class="vf-form__label">Show predictions for sequences found only in UniProt reference proteomes <img class="badge-svg" src="assets/img/reference-proteome.svg" /> ({{facetField.value}})</label>
              <!-- <span><img class="badge-svg" src="assets/img/reference-proteome.svg" /></span> -->

            </div>
          </div>
        </fieldset>

        <!--  pLDDT section      -->
        <fieldset class="vf-form__fieldset vf-stack vf-stack--400">
          <legend class="vf-form__legend">Average pLDDT 
            <br class="mobiletitle" />score  
            <div class="label-tooltip" style="display: inline;">
              <svg style="width:18px;height:18px; margin-bottom:-4px;" viewBox="0 0 24 24"
                  (mouseover)="gaService.eventEmitter('molstar-tooltip', 'tooltip', 'mouseover', accession, undefined)">
                <path fill="#373A36"
                      d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
              </svg>
              <span class="label-tooltip-text-pl" style="font-size: 14px; font-weight: 400; text-align: left;">
                pLDDT is a per-residue measure of local confidence, scaled from 0 to 100. 
Higher scores indicate higher confidence and usually a more accurate prediction.
              </span>
            </div>

          </legend>
          <div class="vf-form__item vf-form__item--checkbox plddtBox">
            <div class="sliderBox">
              <div class="range-slider-container">
                <!-- Background track -->
                <div class="range-slider-track" [style.background]="getSliderBackground()"></div>
              
                <!-- Left thumb -->
                <input
                  type="range"
                  class="range-slider"
                  [(ngModel)]="minplddt"
                  (input)="updateSlider()"
                  min="0"
                  max="100"
                 
                />
                
                <!-- Right thumb -->
                <input
                  type="range"
                  class="range-slider"
                  [(ngModel)]="maxplddt"
                  (input)="updateSlider()"
                  min="0"
                  max="100"
                 
                />
              </div>
                <input  [(ngModel)]="minplddt" name="range-min" class="range-min" (change)="getSliderBackground()"/>
                <input  [(ngModel)]="maxplddt" name="range-max" class="range-max" (change)="getSliderBackground()" />
              
              <div class="plddt-range-values">
                <span>Very low</span>
  
                <span>Very high</span>
              </div>
            </div>
          </div>
          
          <div class="showres">Show results with the <br class="mobiletitle" /> score between</div>

          <div class="facetItemContainer minmax facetItemContainer-dividerpl">
            <div style="display:flex">
              <input class="vf-form__input plddtinput" type="text" name="minplddt" [(ngModel)]="minplddt" value="" placeholder="0" />
              <span class="joint"> and </span>  
              
              <input class="vf-form__input plddtinput" type="text" name="maxplddt"  [(ngModel)]="maxplddt" value=""  placeholder="100"   /> 
            </div>
           
            <a (click)="facetActionPlddt()" class="scorelink">Apply score range</a>
          </div>

        </fieldset>

        <!--  popularOrganisms section      -->
        <fieldset class="vf-form__fieldset vf-stack vf-stack--400" *ngIf="existField('common_organisms')">
          <legend class="vf-form__legend">Organisms</legend>
          <legend class="vf-form__sub_legend">
            {{filterLabels['common_organisms']}}
          </legend>
          <div class="facetItemContainer">
            <ul class="vf-list" *ngFor="let facetField of filterData[0].facet_fields['common_organisms']| keyvalue: commonService.keepDefaultOrder; index as facetIndex;">
              <li class="vf-list__item-facet" *ngIf="isFacetActive('organismScientificName', facetField.key.toString()); else commonLink">
                <a class="vf-link-visited"
                   (click)="facetActionLink('organismScientificName', facetField.key.toString())">
                  <div style="display: flex; justify-content: space-between;">
                    <span style="width:90%">{{ facetField.key }} ({{facetField.value}})</span>
                    <svg style="margin-top: 7px;" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_136_12586)">
                        <path d="M7.69679 6.92481C7.64091 6.86908 7.59658 6.80287 7.56633 6.72997C7.53609 6.65708 7.52052 6.57893 7.52052 6.50001C7.52052 6.42109 7.53609 6.34295 7.56633 6.27005C7.59658 6.19716 7.64091 6.13095 7.69679 6.07521L11.736 2.03601C11.895 1.8654 11.9815 1.63975 11.9774 1.40658C11.9733 1.17342 11.8788 0.950961 11.7139 0.786065C11.549 0.621169 11.3266 0.526715 11.0934 0.522601C10.8603 0.518487 10.6346 0.605035 10.464 0.764011L6.42479 4.80321C6.36905 4.85909 6.30284 4.90342 6.22995 4.93367C6.15705 4.96391 6.07891 4.97948 5.99999 4.97948C5.92107 4.97948 5.84292 4.96391 5.77003 4.93367C5.69713 4.90342 5.63092 4.85909 5.57519 4.80321L1.53599 0.764011C1.36538 0.605035 1.13972 0.518487 0.906562 0.522601C0.673401 0.526715 0.450938 0.621169 0.286043 0.786065C0.121147 0.950961 0.0266929 1.17342 0.022579 1.40658C0.0184652 1.63975 0.105013 1.8654 0.263989 2.03601L4.30319 6.07521C4.35906 6.13095 4.4034 6.19716 4.43364 6.27005C4.46389 6.34295 4.47946 6.42109 4.47946 6.50001C4.47946 6.57893 4.46389 6.65708 4.43364 6.72997C4.4034 6.80287 4.35906 6.86908 4.30319 6.92481L0.263989 10.964C0.175565 11.0464 0.104642 11.1458 0.0554518 11.2562C0.00626146 11.3666 -0.0201887 11.4857 -0.0223209 11.6066C-0.024453 11.7274 -0.00222342 11.8475 0.0430418 11.9595C0.088307 12.0716 0.15568 12.1734 0.241143 12.2589C0.326605 12.3443 0.428406 12.4117 0.540472 12.457C0.652538 12.5022 0.772573 12.5245 0.893416 12.5223C1.01426 12.5202 1.13344 12.4937 1.24383 12.4445C1.35423 12.3954 1.45359 12.3244 1.53599 12.236L5.57519 8.19681C5.63092 8.14094 5.69713 8.0966 5.77003 8.06636C5.84292 8.03611 5.92107 8.02054 5.99999 8.02054C6.07891 8.02054 6.15705 8.03611 6.22995 8.06636C6.30284 8.0966 6.36905 8.14094 6.42479 8.19681L10.464 12.236C10.6346 12.395 10.8603 12.4815 11.0934 12.4774C11.3266 12.4733 11.549 12.3789 11.7139 12.214C11.8788 12.0491 11.9733 11.8266 11.9774 11.5934C11.9815 11.3603 11.895 11.1346 11.736 10.964L7.69679 6.92481Z" fill="#563D82"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_136_12586">
                          <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </a>
              </li>
              <ng-template #commonLink>
                <a class="vf-link"
                   (click)="facetActionLink('organismScientificName', facetField.key.toString())">
                  {{ facetField.key }} ({{facetField.value}})</a>
              </ng-template>
            </ul>
          </div>
        </fieldset>
        <!--  commonOrganisms section      -->
        <fieldset class="vf-form__fieldset vf-stack vf-stack--400" *ngIf="existField('organismScientificName')">
          <legend class="vf-form__sub_legend">
            {{filterLabels['organismScientificName']}}
          </legend>
          <div class="facetItemContainer">
            <ul class="vf-list" *ngFor="let facetField of filterData[0].facet_fields['organismScientificName']| keyvalue: commonService.keepDefaultOrder; index as facetIndex;">
              <li class="vf-list__item-facet" *ngIf="isFacetActive('organismScientificName', facetField.key.toString()); else commonLink">
                <a class="vf-link-visited"
                   (click)="facetActionLink('organismScientificName', facetField.key.toString())">
                  <div style="display: flex; justify-content: space-between;">
                    <span style="width:90%">{{ facetField.key }} ({{facetField.value}})</span>
                    <svg style="margin-top: 7px;" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_136_12586)">
                        <path d="M7.69679 6.92481C7.64091 6.86908 7.59658 6.80287 7.56633 6.72997C7.53609 6.65708 7.52052 6.57893 7.52052 6.50001C7.52052 6.42109 7.53609 6.34295 7.56633 6.27005C7.59658 6.19716 7.64091 6.13095 7.69679 6.07521L11.736 2.03601C11.895 1.8654 11.9815 1.63975 11.9774 1.40658C11.9733 1.17342 11.8788 0.950961 11.7139 0.786065C11.549 0.621169 11.3266 0.526715 11.0934 0.522601C10.8603 0.518487 10.6346 0.605035 10.464 0.764011L6.42479 4.80321C6.36905 4.85909 6.30284 4.90342 6.22995 4.93367C6.15705 4.96391 6.07891 4.97948 5.99999 4.97948C5.92107 4.97948 5.84292 4.96391 5.77003 4.93367C5.69713 4.90342 5.63092 4.85909 5.57519 4.80321L1.53599 0.764011C1.36538 0.605035 1.13972 0.518487 0.906562 0.522601C0.673401 0.526715 0.450938 0.621169 0.286043 0.786065C0.121147 0.950961 0.0266929 1.17342 0.022579 1.40658C0.0184652 1.63975 0.105013 1.8654 0.263989 2.03601L4.30319 6.07521C4.35906 6.13095 4.4034 6.19716 4.43364 6.27005C4.46389 6.34295 4.47946 6.42109 4.47946 6.50001C4.47946 6.57893 4.46389 6.65708 4.43364 6.72997C4.4034 6.80287 4.35906 6.86908 4.30319 6.92481L0.263989 10.964C0.175565 11.0464 0.104642 11.1458 0.0554518 11.2562C0.00626146 11.3666 -0.0201887 11.4857 -0.0223209 11.6066C-0.024453 11.7274 -0.00222342 11.8475 0.0430418 11.9595C0.088307 12.0716 0.15568 12.1734 0.241143 12.2589C0.326605 12.3443 0.428406 12.4117 0.540472 12.457C0.652538 12.5022 0.772573 12.5245 0.893416 12.5223C1.01426 12.5202 1.13344 12.4937 1.24383 12.4445C1.35423 12.3954 1.45359 12.3244 1.53599 12.236L5.57519 8.19681C5.63092 8.14094 5.69713 8.0966 5.77003 8.06636C5.84292 8.03611 5.92107 8.02054 5.99999 8.02054C6.07891 8.02054 6.15705 8.03611 6.22995 8.06636C6.30284 8.0966 6.36905 8.14094 6.42479 8.19681L10.464 12.236C10.6346 12.395 10.8603 12.4815 11.0934 12.4774C11.3266 12.4733 11.549 12.3789 11.7139 12.214C11.8788 12.0491 11.9733 11.8266 11.9774 11.5934C11.9815 11.3603 11.895 11.1346 11.736 10.964L7.69679 6.92481Z" fill="#563D82"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_136_12586">
                          <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </a>
              </li>
              <ng-template #commonLink>
                <a class="vf-link"
                   (click)="facetActionLink('organismScientificName', facetField.key.toString())">
                  {{ facetField.key }} ({{facetField.value}})</a>
              </ng-template>
            </ul>
          </div>
        </fieldset>
      </div>

      <div class="show-for-small-only" style="height: 20px;">&nbsp;</div>

    </div>

    <!-- Search Results -->
    <div class="columns small-12 medium-9" *ngIf="loadComplete && mainResultData">
      
      <!-- Result card -->
      <div class="row column" *ngFor="let mainResult of mainResultData; index as resultIndex;">
        <search-result-card [resultRec]="mainResult" [resultRecIndex]="resultIndex"></search-result-card>
      </div>

      <!-- Lower Pagination -->
      <div class="row column small-centered medium-5 pgSection" style="margin-top: 32px;">
        <search-pagination [(paginationData)]="paginationData" (selectedPage)="paginateTo($event)"
                           (resPerPage)="updatePerPageVal($event)"></search-pagination>
      </div>

    </div>

    <!-- No search result -->
    <div class="columns small-12" *ngIf="loadComplete && !mainResultData && !isSearchprogress && !isSequenceSearch">
      <h3 class="noResultTitle">No results found</h3>
      <p class="progress-suggestions" *ngIf="isRangeFilterResultsEmpty">There are no results in this pLDDT range. <a href="{{getCurrentUrlWithoutQueryParams()}}">Please go back and expand the range.</a></p>
      <p class="progress-suggestions" *ngIf="!isRangeFilterResultsEmpty">Suggestions:</p>
      <ul class="msg_bullets" *ngIf="!isRangeFilterResultsEmpty">
        <li>Check your spelling.</li>
        <li>Try searching by protein or gene name rather than specific UniProt accession.</li>
        <li>If you don't see the sequence you are looking for, try searching for it using the
          <a href="https://www.ebi.ac.uk/Tools/sss/fasta/" target="_blank">EBI Protein Similarity
            Search tool</a> against the sequences in the AlphaFold DB. This may provide a structure prediction
          if the query sequence is already available in AlphaFold DB. If the query sequence is not available,
          a structure prediction with a similar sequence may be available.</li>
        <li>
          Check whether your protein may be excluded for one of the reasons outlined in our <a href="/faq">FAQs</a>.</li>
      </ul>

    </div>


    <!-- No search result for sequence search-->
    <div class="columns small-12 statusContainer" *ngIf="loadComplete && !mainResultData && !isSearchprogress && isSequenceSearch && !isRangeFilterResultsEmpty">
      <h3 class="noResultTitle">No results found</h3>
      <p class="progress-text1" style="word-wrap: break-word;">We couldn’t find any matches for your search  -  {{searchTerm}} </p>

      <p class="progress-suggestions">Suggestions:</p>
      <ul class="msg_bullets">
        <li>Check your spelling.</li>
        <li>Try searching by protein or gene name rather than specific UniProt accession.</li>
        <li>For sequence similarity search, check that your sequence contains at least 20 amino acids and has only standard residues. Note that nucleic acid sequences are not supported.
        </li>
        <li>
          Check whether your protein may be excluded for one of the reasons outlined in our <a href="/faq">FAQs</a>.</li>
      </ul>
    </div>

    <!-- Search in progress -->
    <div class="columns small-12 statusContainer" *ngIf="!mainResultData && isSearchprogress">
      <h2 class="progress_tile">
        Search in progress
        <img src="assets/img/Loader-ALPHA.gif" width="30px" height="30px">
      </h2>

      <div class="progress-text1">Your protein sequence search is currently underway. The process typically requires between 5 to 10 minutes to complete. Please stand by.
      <br/> You can <a (click)="copyLink()">Click to copy the link</a>
      <div class="label-tooltip-link" style="display: inline;">
        <span *ngIf="isCopyLink" class="label-tooltip-link-text">
          Link copied
        </span>
      </div>
      to the results page. This will enable you to revisit and review your search results at a time that is most convenient for you.</div>


      <p class="progress-text4">
        <span>Your query sequence:</span>
        <span class="mobile-view-copy">
          <a (click)="copySequence(searchTerm)">Click to copy sequence </a>
          <div class="label-tooltip-seq" style="display: inline;">
            <span *ngIf="isCopySequence" class="label-tooltip-seq-text">
              Sequence copied
            </span>
          </div>
        </span>
      </p>
      <p class="progress-text3">
          <span>
            {{searchTerm}}
          </span>
      </p>

      <p class="mobile-view-second">
        <a (click)="copySequence(searchTerm)">
          Copy sequence
        </a>
      </p>

      <div class="label-tooltip-seq" style="display: inline;">
        <span *ngIf="isCopySequence" class="label-tooltip-seq-text">
          Sequence copied
        </span>
      </div>

      <!-- <p class="mobile-view-second">
        <a (click)="copyToClipboard(searchTerm)">
        Copy sequence
        <i class="icon icon-common icon-copy"></i>
        </a>
      </p> -->
    </div>
  </div>

</div>
