import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonService } from '../common.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { ConfigService } from '../config.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-training-courses',
  templateUrl: './training-courses.component.html',
  styleUrl: './training-courses.component.css'
})

export class TrainingCoursesComponent {
  hasLiveTrainingData: number;
  hasOndemandTrainingData: number;
  isLiveTrainingLoading: boolean  =  false;
  isOndemandTrainingLoading: boolean  =  false;
  liveTrainingData: any;
  liveTrainingAPIError: boolean = false;
  ondemandTrainingData: any;
  ondemandTrainingAPIError: boolean = false;

  constructor(
    private commonService: CommonService,
    public gaService: GoogleAnalyticsService,
    private configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    ) {
      this.hasLiveTrainingData = -1;
  }

  ngOnInit(): void {
    this.getLiveTrainingData();
    this.getOndemandTrainingData();
  }

  formatDesc(content) {
    if (content) {
      let safeContent1 = content.replace(/(<([^>]+)>)/ig, '');
      const safeContent = safeContent1.replace(/&nbsp;/g, ' ');
      let cleanedDescription = safeContent.length > 150 ? safeContent.slice(0, 150).split(" ").slice(0, -1).join(" ") + "..." : safeContent
     
      
      return cleanedDescription
    }
  }
  
  getLiveTrainingData(){
    const apiUrl = "https://www.ebi.ac.uk/ebisearch/ws/rest/ebiweb_training_events?format=json&query=domain_source:ebiweb_training_events%20AND%20timeframe:upcoming&start=0&size=2&fieldurl=false&fields=title,description,date_time_clean,resource_training_page,type,url,venue,materials,status,timeframe,resource_training_page&facetcount=0&sort=start_date&facets=resource_training_page:AlphaFold%20DB";

    this.isLiveTrainingLoading = true;

    this.commonService.getApiDatawithStatus(apiUrl).subscribe(async (response: HttpResponse<any>) => {
      const code = await Number(response.status);
      
      if(code === 200){
        this.isLiveTrainingLoading = false;
        if(response.body && response.body.hitCount > 0){
          this.hasLiveTrainingData = 1;
          this.liveTrainingData = response.body.entries;
        }else{
          this.hasLiveTrainingData = 0;
        }
      }
      this.changeDetectorRef.detectChanges();
    },
    (error) => {
      const code = error.status;
      this.isLiveTrainingLoading = false;
      if(code === 404 ){
        this.hasLiveTrainingData = -1;
        this.isLiveTrainingLoading = false;
        this.liveTrainingData = null;
        this.changeDetectorRef.detectChanges();
      }
      if(code === 422 || code === 302 || code === 500 || code === 0 || code === 429){
        this.liveTrainingAPIError = true;
        this.isLiveTrainingLoading = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getOndemandTrainingData(){
    const apiUrl = "https://www.ebi.ac.uk/ebisearch/ws/rest/ebiweb_training_online?format=json&query=domain_source:ebiweb_training_online&start=0&size=2&fields=title,subtitle,description,type,url&sort=title&facets=resource_training_page:AlphaFold%20DB";
    
    this.isOndemandTrainingLoading = true;

    this.commonService.getApiDatawithStatus(apiUrl).subscribe(async (response: HttpResponse<any>) => {
      const code = await Number(response.status);
      
      if(code === 200){
        this.isOndemandTrainingLoading = false;
        if(response.body && response.body.hitCount > 0){
          this.hasOndemandTrainingData = 1;
          this.ondemandTrainingData = response.body.entries;
        }else{
          this.hasOndemandTrainingData = 0;
        }
      }
      this.changeDetectorRef.detectChanges();
    },
    (error) => {
      const code = error.status;
      this.isOndemandTrainingLoading = false;
      if(code === 404 ){
        this.hasOndemandTrainingData = -1;
        this.isOndemandTrainingLoading = false;
        this.ondemandTrainingData = null;
        this.changeDetectorRef.detectChanges();
      }
      if(code === 422 || code === 302 || code === 500 || code === 0 || code === 429){
        this.ondemandTrainingAPIError = true;
        this.isOndemandTrainingLoading = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }
}
