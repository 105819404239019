import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { applyAlphaMissenseTooltips, colorByAlphaMissense, resetColoring, resetTooltips } from '../molstar-alpha-missense';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'app-structure-viewer',
  templateUrl: './structure-viewer.component.html',
  styleUrl: './structure-viewer.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StructureViewerComponent {
  @Input() isModalConf: boolean;
  @Input() isAlphaMis: boolean;
  @Input() isShowModelLegendsText: boolean;
  @Input() isShowModelLegends: boolean;
  
  @Input() accession: string;
  @Input() csvAmData: any;
  @Input() pdbeMolstar: any
  @Output() selectedToggle:EventEmitter<Object> = new EventEmitter();

  rangeFilter: any;

  viewerLegend: any = [
    { style: { backgroundColor: '#0053D6'}, label: 'Very high (pLDDT > 90)' },
    { style: { backgroundColor: '#65CBF3'}, label: 'High (90 > pLDDT > 70)' },
    { style: { backgroundColor: '#FFDB13'}, label: 'Low (70 > pLDDT > 50)' },
    { style: { backgroundColor: '#FF7D45'}, label: 'Very low (pLDDT < 50)' }
  ];

  alphamissenseLegends: any = [
    { style: { backgroundColor: '#2166ac'}, label: 'Likely benign' },
    { style: { backgroundColor: '#A8A9AC'}, label: 'Uncertain' },
    { style: { backgroundColor: '#b2182b'}, label: 'Likely pathogenic' },
  ];

  constructor(public gaService: GoogleAnalyticsService) {}

  toggleShowModel() {
    let event_name = "Colour_legend_hidden";
    let event_lable = "hide_colour_legend_click"
    if(!this.isShowModelLegends){
      event_name = "Colour_legend_shown";
      event_lable = "show_colour_legend_click";
    }
    this.gaService.eventEmitter(event_name, '3D_colour_legend', 'click', event_lable, 'Clicks on Show/Hide colour legend link button');
    this.isShowModelLegends =  !this.isShowModelLegends;
    this.isShowModelLegendsText = !this.isShowModelLegendsText;
  }

  applyMolstarColoring(kind: 'plddt' | 'missense') {
    // this.pdbeMolstar.visual.reset({camera: true});
    if (kind === 'missense') {
      
      this.isAlphaMis = true;
      this.isModalConf =  false;
      this.selectedToggle.emit({isAlphaMis : this.isAlphaMis, isModalConf : this.isModalConf});
      // colorByAlphaMissense(this.pdbeMolstar, this.csvAmData);
      if(this.rangeFilter){
        colorByAlphaMissense(this.pdbeMolstar, this.csvAmData, this.rangeFilter);
      }else{
        colorByAlphaMissense(this.pdbeMolstar, this.csvAmData);
      }
      applyAlphaMissenseTooltips(this.pdbeMolstar, this.csvAmData);
      this.gaService.eventEmitter(
        'AM_toggle',
        'AlphaMissense',
        'click',
        'AM_toggle',
        'Toggle between AM and pLDDT colouring on Mol*'
      );
    } else if (kind === 'plddt') {
      this.isAlphaMis = false;
      this.isModalConf =  true;
      this.selectedToggle.emit({isAlphaMis : this.isAlphaMis, isModalConf : this.isModalConf});
      resetColoring(this.pdbeMolstar);
      resetTooltips(this.pdbeMolstar);
    } else {
      console.error('Unknow coloring kind:', kind);
    }
  }
}
