import { Injectable } from '@angular/core';
import { dotenv, IDotenv } from './config/dotenv';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config = dotenv;

  constructor() { }

  public getConfig(): IDotenv  {
    return this.config;
  }
}
