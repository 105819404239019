import {AfterViewInit, Component, Input, Output, EventEmitter, OnInit, ElementRef} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-pae-view',
  templateUrl: './pae-view.component.html',
  styleUrls: ['./pae-view.component.css'],
  host: {
    '(document:keydown)': 'keyPressListener($event)'
  }
})
export class PaeViewComponent implements OnInit, AfterViewInit {

  @Input() accession: string;
  @Input() imageUrl: string;
  @Input() length: number;

  @Output()
  selectedRegion:EventEmitter<Object> = new EventEmitter();

  // DOM Elements
  paeImgContainer: d3.Selection<HTMLElement, unknown, HTMLElement, any>;

  // D3 scales & Axis
  xAxis: d3.Axis<d3.NumberValue>;
  yAxis: d3.Axis<d3.NumberValue>;
  x: d3.ScaleLinear<number, number>;
  y: d3.ScaleLinear<number, number>;
 
  // Globals
  brushCtx: any;
  brushSelection: any;
  domainMax: number;
  width: number;
  height: number;

  constructor(private el:ElementRef) {}

  ngOnInit() {
    this.domainMax = this.length;
  }

  ngAfterViewInit() {
    this.renderPae();
  }

  formatTicks(d: number): any {
    return d > 999 ? d / 1000 + 'k' : d;
  }

  renderPae() {

    // Selection Image container element
    this.paeImgContainer = d3.select('.pae-img-box');

    const borderEle = d3.select('.pae-img-box-border');
    this.paeImgContainer.on('mouseover', () => {
      borderEle.attr("class", "pae-img-box-border active");
    });
    this.paeImgContainer.on('mouseout', () => {
      borderEle.attr("class", "pae-img-box-border");
    });

    // SVG addition
    this.dimensionCalc();
    this.initScales();
    this.updateViewport();

  }

  dimensionCalc() {
    const paeBoxDims = this.paeImgContainer.node().getBoundingClientRect();
    this.width = paeBoxDims.width;
    this.height = paeBoxDims.width;
  }

  initScales() {
    // Scales
    this.x = d3.scaleLinear().domain([0, this.domainMax]).range([0, this.width]); //.nice();
    this.y = d3.scaleLinear().domain([0, this.domainMax]).range([0, this.height]); //.nice();

    // Axis
    this.xAxis = d3.axisBottom(this.x).ticks(6).tickFormat(this.formatTicks).tickSizeOuter(0);
    this.yAxis = d3.axisLeft(this.y).ticks(6).tickFormat(this.formatTicks).tickSizeOuter(0);
  }

  updateViewport() {

    // Create SVGs
    const axisContainer = d3.select('.pae-axis-box');

    const xAxisSvg = axisContainer.append('svg:svg')
    .attr('width', this.width + 7)
    .attr('height', 30)
    .attr('class', 'pae-x-axis')
    .style('z-index', '1')
    .append('g')
    .attr('transform', `translate(5,0)`);

    const yAxisSvg = axisContainer.append('svg:svg')
    .attr('width', 30)
    .attr('height', this.height + 7)
    .attr('class', 'pae-y-axis')
    .style('z-index', '1')
    .append('g')
    .attr('transform', `translate(35,7)`);

    const brushSvg = this.paeImgContainer.append('svg:svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('z-index', '2')
      .append('g')

    // Add Axis
    xAxisSvg.append('g').call(this.xAxis);
    yAxisSvg.append('g').call(this.yAxis);
    
    // Add brush
    this.brushCtx = d3.brush()
      .extent([[0, 0], [this.x(this.domainMax), this.y(this.domainMax)]])
      .keyModifiers(false)
      .on("start", (e) => { this.brushStart(e); })
      .on("end", (e) => { this.brushEnd(e); });
    this.brushSelection = brushSvg.append('g').attr('class', 'brushGroup').call(this.brushCtx);

  }

  brushStart(e: any) {
    this.toggleRemoveIcon(true);
  }

  brushEnd(e: any) {

    let selectedRegion = [{start:0, end:0},{start:0,end:0}];
    
    if(e.selection !== null) {
      
      const start1 = Math.floor(this.x.invert(e.selection[0][0]));
      const end1 = Math.floor(this.y.invert(e.selection[1][0]));
      const start2 = Math.floor(this.x.invert(e.selection[0][1]));
      const end2 = Math.floor(this.y.invert(e.selection[1][1]));

      selectedRegion = [
        {
          start: start1, end: end1 
        },
        {
          start: start2, end: end2 
        }
      ] 
    }

    this.toggleRemoveIcon(false);
    
    this.selectedRegion.emit(selectedRegion);
  }

  toggleRemoveIcon(hide?: boolean) {

    const closeIconElm = this.el.nativeElement.querySelector('.pae-clear-icon');
    if(hide) {
      closeIconElm.style.display = 'none';
    } else {

      const selectionBoxElm = this.el.nativeElement.querySelector('.selection');
      if(!selectionBoxElm || selectionBoxElm.width.baseVal.value === 0) return;

      const leftAdjustVal =  selectionBoxElm.width.baseVal.value > 20 ? 9 : 0;
      let leftMargin = selectionBoxElm.x.baseVal.value + selectionBoxElm.width.baseVal.value - leftAdjustVal;
      let topMargin = selectionBoxElm.y.baseVal.value - 9;

      if(leftMargin >= this.width - 15) {
        leftMargin -= 10;
      }

      if(selectionBoxElm.y.baseVal.value <= 15) {
        topMargin = 2;
      }
      
      closeIconElm.style.marginLeft = leftMargin + 'px';
      closeIconElm.style.marginTop = topMargin + 'px';
      closeIconElm.style.display = 'inline-block';

    }
    
  }

  keyPressListener(event: KeyboardEvent) {
    if (event.key === 'Escape'){
      this.clearSelection();
    }
  }

  clearSelection() {
    this.brushSelection.call(this.brushCtx.move, null);
  }

}
