<div class="vf-dropdown" data-vf-js-dropdown>
  <div class="vf-dropdown__label-container">
    <div class="vf-dropdown__label" style="text-align: left">Download data</div>
    <div>
      <svg class="arrow-icon" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="18" height="19" fill="#F5F5F5"/>
        <g id="AM legend">
        <path d="M-720 -1809C-720 -1810.1 -719.105 -1811 -718 -1811H4721C4722.1 -1811 4723 -1810.1 4723 -1809V10532C4723 10533.1 4722.1 10534 4721 10534H-718C-719.104 10534 -720 10533.1 -720 10532V-1809Z" fill="white"/>
        <path d="M-718 -1810H4721V-1812H-718V-1810ZM4722 -1809V10532H4724V-1809H4722ZM4721 10533H-718V10535H4721V10533ZM-719 10532V-1809H-721V10532H-719ZM-718 10533C-718.552 10533 -719 10532.6 -719 10532H-721C-721 10533.7 -719.657 10535 -718 10535V10533ZM4722 10532C4722 10532.6 4721.55 10533 4721 10533V10535C4722.66 10535 4724 10533.7 4724 10532H4722ZM4721 -1810C4721.55 -1810 4722 -1809.55 4722 -1809H4724C4724 -1810.66 4722.66 -1812 4721 -1812V-1810ZM-718 -1812C-719.657 -1812 -721 -1810.66 -721 -1809H-719C-719 -1809.55 -718.552 -1810 -718 -1810V-1812Z" fill="black" fill-opacity="0.1"/>
        <g id="1440 / Similar structures">
        <g clip-path="url(#clip0_1052_18659)">
        <rect x="-523" y="-1566" width="1440" height="5267" fill="white"/>
        <g id="Page">
        <g id="Row">
        <g id="Table">
        <g id="Frame 220">
        <rect width="1296" height="1212" transform="translate(-451 -735)" fill="white"/>
        <g id="Frame 494">
        <g id="Frame 8454">
        <g id="Frame 8460">
        <g id="Button download">
        <g id="keyboard_arrow_down_24px">
        <path id="ic_keyboard_arrow_down_24px" d="M9.00003 12.8075L3.96753 7.78253L5.03253 6.71753L9.00003 10.6925L12.9675 6.71753L14.0325 7.78253L9.00003 12.8075Z" fill="#3B6FB6"/>
        </g>
        </g>
        </g>
        </g>
        </g>
        </g>
        </g>
        </g>
        </g>
        </g>
        <rect x="-522.5" y="-1565.5" width="1439" height="5266" stroke="#D0D0CE"/>
        </g>
        <g id=".Menu" filter="url(#filter0_d_1052_18659)">
        <rect width="216" height="117" transform="translate(-100 21)" fill="white"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_d_1052_18659" x="-103" y="18" width="224" height="125" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1052_18659"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1052_18659" result="shape"/>
        </filter>
        <clipPath id="clip0_1052_18659">
        <rect x="-523" y="-1566" width="1440" height="5267" fill="white"/>
        </clipPath>
        </defs>
        
      </svg>   
    </div>
  </div>
  <div class="vf-dropdown__menu-container">
    <div class="vf-dropdown-overlay"></div>
    <div class="vf-dropdown__menu">
      <div *ngFor="let data of dropdownOptions" class="vf-dropdown__menu-item">
        <a *ngIf="data.isShow" class="vf-dropdown__menu-item-link" (click)="select(data.value)">{{data.label}}</a>
      </div>
    </div>
  </div>
</div>
