import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  Output
} from '@angular/core';
import { Heatmap } from 'heatmap-component'; 
import * as d3 from 'd3';
import { formatDataItem } from 'heatmap-component/lib/heatmap-component/utils';
import "@nightingale-elements/nightingale-manager";
import "@nightingale-elements/nightingale-navigation";
import "@nightingale-elements/nightingale-sequence";
import { Subject, debounceTime } from 'rxjs';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { AM_COLOR_SCALE } from '../molstar-alpha-missense';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.css'],
})
export class HeatmapComponent implements OnInit {
  @Input() accession: string;
  @Input() csvAmData: any;
  @Input() heatmapSequence: string;
  @Input() amAnnotationsHg19Url: string;
  @Input() amAnnotationsHg38Url: string;
  @Input() csvAMFileUrl: string;
  
  @Output() selectedRegion: EventEmitter<Object> = new EventEmitter();
  @Output() clearRegion: EventEmitter<Object> = new EventEmitter();
  @Output() selectedCategory: EventEmitter<Object> = new EventEmitter();
  
  length: number;
  variantsData: any;
  canvasEl: any;

  isPathogenic: boolean = false;
  isBenign: boolean = false;
  isUncertain: boolean = false;
  isAll: boolean = true;
  isReset: boolean = false;

  isPathogenicDisabled: boolean = true;
  isBenignDisabled: boolean = true;
  isUncertainDisabled: boolean = true;
  colorScale = d3.scaleLinear([0, 0.5, 1], ['#3853A3', '#A8A9AC', '#ED1E24']);
  selectedCategories: string[] = []; // Array to store selected values
  selectedRange: any = '';

  heatmapOne: Heatmap<
    number,
    string,
    { score: number; start: number; variant: string, category:string }
  >;

  xAxis: d3.Axis<d3.NumberValue>;
  gxAxis: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;
  yAxis: any;
  gyAxis: any;

  x: d3.ScaleLinear<number, number>;
  y: d3.ScalePoint<string>;
  data: number[][];

  filterBy: Array<string>;

  previousZoom: [number, number] = [-10, -10]; // Added for Nightingale zooming
  private zoomSubject = new Subject<{id: string, zoom: [number, number]}>();
  private zoomSubject2 = new Subject<{id: string, zoom: [number, number]}>();

  constructor(private changeDetectorRef: ChangeDetectorRef, public gaService: GoogleAnalyticsService) {}

  filterRangeMin: number = 0; // Set initial filter range to 1
  filterRangeMax: number = 1;

  silder1Min: number = 0;
  silder1Max: number = 0.34;

  silder2Min: number = 0.34;
  silder2Max: number = 0.56;

  silder3Min: number = 0.56;
  silder3Max: number = 1;
  
  dropdownOptions: { label: string, value: any, isShow: boolean }[];

  ngOnInit() {

    this.dropdownOptions = [
      { label: 'Heatmap data', value: "heatmapdata" , isShow: this.csvAMFileUrl ? true : false},
      { label: 'HG19 pathogenicity scores', value: "hg19", isShow: this.amAnnotationsHg19Url ? true : false },
      { label: 'HG38 pathogenicity scores', value: "hg38", isShow: this.amAnnotationsHg38Url ? true : false },
    ];

    this.initNavigationObserver(); // Added for Nightingale navigation to trigger zooming
    // subject below calls nightingale zoom events with debouncing if needed
    this.zoomSubject.pipe(debounceTime(1)).subscribe((zoomObject) => {
      const thisElement = document.querySelector('nightingale-navigation');
      thisElement.dispatchEvent(
        new CustomEvent("change", {
          detail: {
            value: zoomObject.zoom[0],
            type: "display-start",
          },
          bubbles: true,
        }),
      );
      thisElement.dispatchEvent(
        new CustomEvent("change", {
          detail: {
            value: zoomObject.zoom[1],
            type: "display-end",
          },
          bubbles: true,
        }),
      );
    });

    this.zoomSubject2.pipe(debounceTime(100)).subscribe((zoomObject) => {
      this.gaService.eventEmitter(
        "AM_heatmap_zoom",
        'AlphaMissense',
        'zoom',
        "heatmap_zoom",
        "Zooming into sequence on heatmat"
      );
    });
    this.hideSequenceAxis();
    this.fixRectSize();
    this.changeDetectorRef.detectChanges();
    this.initHeatMap();
  }

  // Helper taken from: https://stackoverflow.com/questions/5525071/how-to-wait-until-an-element-exists
  async awaitElementIsRendered(querySelectorTxt) {
    return await new Promise(resolve => {
      if (document.querySelector(querySelectorTxt)) {
          return resolve(document.querySelector(querySelectorTxt));
      }

      const observer = new MutationObserver(mutations => {
          if (document.querySelector(querySelectorTxt)) {
              observer.disconnect();
              resolve(document.querySelector(querySelectorTxt));
          }
      });
      // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
    });
  }

  // Added for Nightingale navigation to trigger zooming
  async initNavigationObserver() {
    /**
     * This function listens to display-start / display-end changes in the navigation bar
     * which indicate that zooming has happened
     * */ 

    // First we create an observer with a function to be triggered on zoom events
    const that = this;
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "attributes" && mutation.attributeName.includes("display")) {
          // Here zoom residues are detected
          const displayStart = (mutation.target as HTMLElement).getAttribute("display-start");
          const displayEnd = (mutation.target as HTMLElement).getAttribute("display-end");
          
          const toStart = parseFloat(displayStart);
          const toEnd = parseFloat(displayEnd);
          const toDisplay: [number, number] = [toStart, toEnd];

          if(toStart && toEnd) {
            const selectorRect = document.querySelector("nightingale-navigation > svg > g.brush > rect.selection");
            (selectorRect as SVGRectElement).setAttribute("height", "19");

            // zoom is only called if values are diffent than the previous zoom
            if (that.previousZoom[0] !== toDisplay[0] || that.previousZoom[1] !== toDisplay[1]) {
              that.previousZoom = toDisplay;
              that.zoomSubject.next({"id": "observer", "zoom": toDisplay});
              that.heatmapOne.zoom({ xMin: toStart - 0.5, xMax: toEnd + 0.5 });
            }
          }  
        }
      }
    })

    // We then await until nightingale-navigation is rendered on page
    const myElement = await this.awaitElementIsRendered('nightingale-navigation');

    // Once nightingale-navigation is rendered we set the created observer to it
    observer.observe((myElement as Node), { attributes: true });
  }

  async fixRectSize() {
    // first we wait untl nightingale navigation is rendered
    const myElement = await this.awaitElementIsRendered('nightingale-navigation');

    // we then add height 19 to it
    (myElement as HTMLElement).addEventListener("mousedown", (ev) => {
      (document.querySelector("nightingale-navigation > svg > g.brush > rect.selection") as SVGRectElement).setAttribute("height", "19");
    })
  }

  // Added for hiding Nightingale sequence axis
  async hideSequenceAxis(){
    // first we wait untl nightingale axis is rendered
    const myElement = await this.awaitElementIsRendered('nightingale-sequence > svg > g.x.axis');
    // we then add display none to it
    (myElement as SVGGElement).setAttribute("display", "none");
  }


  handleSelection(value: string) {
    const urlMap = {
      heatmapdata: {url: this.csvAMFileUrl, tag: "AM_heatmap_download", msg: "Clicks on downloading AM heatmap csv"},
      hg19: {url: this.amAnnotationsHg19Url, tag: "AM_hg19_download", msg: "Clicks on downloading AM hg19 csv"},
      hg38: {url: this.amAnnotationsHg38Url, tag: "AM_hg38_download", msg: "Clicks on downloading AM hg38 csv"}
    };
  
    const downloadUrl = urlMap[value].url;
    if (downloadUrl) {
      this.downloadCSVFile(downloadUrl);
    } else {
      alert(`Don't have ${value} csv file url from API`);
    }

    this.gaService.eventEmitter(
      urlMap[value].tag,
      'AlphaMissense',
      'click',
      urlMap[value].tag,
      urlMap[value].msg
    );
    // Do something with the selected value
  }
  
  downloadCSVFile(csvurl) {
    const link = document.createElement('a');
    link.href = csvurl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(csvurl);
  }

  resetFilters() {
    this.isReset =  false;

    this.isPathogenic = false;
    this.isBenign = false;
    this.isUncertain = false;

    this.isPathogenicDisabled = true;
    this.isBenignDisabled = true;
    this.isUncertainDisabled = true;

    this.silder1Min = 0;
    this.silder1Max = 0.34;
  
    this.silder2Min = 0.34;
    this.silder2Max = 0.56;
  
    this.silder3Min = 0.56;
    this.silder3Max = 1;

    this.heatmapOne.setFilter((d, x, y, xIndex, yIndex) => d.category==='wt' || d.score > 0);

    this.gaService.eventEmitter(
      "AM_reset_filter",
      'AlphaMissense',
      'click',
      "AM_reset_filter",
      "Clicks on the resetting filters"
    );
  }

  resetZoom(){
    this.heatmapOne.zoom(undefined);
  }
  clearMolstarSelection(){
    this.clearRegion.emit();
  }

  handleFilters(type?:string, name?:string) {
    this.isReset = true;

    const filterData = [
      {min: this.silder1Min, max: this.silder1Max}, 
      {min: this.silder2Min, max: this.silder2Max}, 
      {min: this.silder3Min, max: this.silder3Max}
    ];

    if(!this.isBenign) {
      this.silder1Min = 0;
      this.silder1Max = 0.34;
      filterData[0] = {min: -2, max: -2};
    }

    if(!this.isUncertain) {
      this.silder2Min = 0.34;
      this.silder2Max = 0.56;
      filterData[1] = {min: -2, max: -2};
    } 

    if(!this.isPathogenic) {
      this.silder3Min = 0.56;
      this.silder3Max = 1;
      filterData[2] = {min: -2, max: -2};
    } 

    if(!this.isBenign && !this.isUncertain && !this.isPathogenic) {
      filterData[0] = {min: 0, max: 0.34};
      filterData[1] = {min: 0.34, max: 0.56};
      filterData[2] = {min: 0.56, max: 1};
      this.isReset =  false;
    }
    let eventLable = '';
    let eventName = '';
    let eventDesc = '';

    if(type === 'category'){
      eventName = 'AM_checkbox';
      eventLable = name === 'benign' ? 'AM_checkbox_benign' : (name === 'pathogenic'? 'AM_checkbox_pathogenic' : 'AM_checkbox_uncertain');
      eventDesc = name === 'benign' ? 'Clicks on the check box for benign category' : 
                  (name === 'pathogenic'? 'Clicks on the check box for pathogenic category' : 'Clicks on the check box for uncertain category');
    }
    if(type === 'range'){
      eventName = 'AM_filter';
      eventLable = name === 'benign' ? 'AM_drag_benign' : (name === 'pathogenic'? 'AM_drag_pathogenic' : 'AM_drag_uncertain');
      eventDesc = name === 'benign' ? 'Clicks and dragging on the filter bar benign' : 
                  (name === 'pathogenic'? 'Clicks and dragging on the filter bar pathogenic' : 'Clicks and dragging on the filter bar uncertain');
    }
    this.gaService.eventEmitter(
      eventName,
      'AlphaMissense',
      'click',
      eventLable,
      eventDesc);

    this.applyFilters(filterData)
  }

  applyFilters(rangeList: {min: number, max: number}[]) {

    const filterFn = (d, x, y, xIndex, yIndex) => {

      // show this data -> score is in min and max && category is available
      if(d.score >= rangeList[0].min && d.score < rangeList[0].max ||
        d.score >= rangeList[1].min && d.score < rangeList[1].max ||
        d.score >= rangeList[2].min && d.score <= rangeList[2].max || d.category==='wt') {
        return true;
      }
    }
    this.heatmapOne.setFilter(filterFn);
  }

  getVariants(csvData2: string, filterBy, rangeFilter) {
    const variants = [];
    const DELIMITER = ',';
    const MUTATION_COLUMN = 0;
    const SCORE_COLUMN = 1;
    const CLASS_COLUMN = 2;
    const N_HEADER_ROWS = 0;

    const lines = csvData2
      .replace(/\r/g, '')
      .split('\n')
      .filter((line) => line.trim() !== '' && !line.trim().startsWith('#'));
    if (N_HEADER_ROWS > 0) lines.splice(0, N_HEADER_ROWS);
    const rows = lines.map((line) => line.split(DELIMITER));
    rows.shift();
  
    for (const row of rows) {
      const mutation = row[MUTATION_COLUMN];
      const score = Number(row[SCORE_COLUMN]);
      const colorclass = row[CLASS_COLUMN];
      const match = mutation.match(/([A-Za-z]+)([0-9]+)([A-Za-z]+)/);
      if (!match)
        throw new Error(
          `FormatError: cannot parse "${mutation}" as a mutation (should look like Y123A)`
        );
      const mutationFrom = match[1];
      const seq_id = match[2];
      const mutationTo = match[3];

      if (filterBy.length > 0) {
        if (!filterBy.includes(colorclass)) continue;
      }

      if (rangeFilter != '') {
        if (score < rangeFilter.min || score > rangeFilter.max) {
          continue; // Score is outside the range
        }
      }

      const colorResidue = this.getColorResidue(colorclass);

      const variant = {
        score: score,
        start: Number(seq_id),
        referenceVariant: mutationFrom,
        variant: mutationTo,
        category: colorclass,
      };

      variants.push(variant);
    }
    this.changeDetectorRef.detectChanges();
    return variants;
  }

  getColorResidue(colorclass) {
    const colors = {
      'LPath':  '#ED1E24',
      'LBen': '#3853A3',
      'Amb': '#A8A9AC'
    };
    return colors[colorclass] || '';
  }

  async getAMData(filterBy, rangeFilter) {
    const csvData2 = this.csvAmData;

    if (!csvData2.includes('<!DOCTYPE html>')) {
      const variants = this.getVariants(csvData2, filterBy, rangeFilter);
      for (let i = 0; i < this.heatmapSequence.length; i++){ 
        variants.push(
          {
            score: -1,
            start: i+1,
            referenceVariant: this.heatmapSequence[i],
            variant: this.heatmapSequence[i],
            category: 'wt',
          }
        );
      }

      const variationData = {
        variants: variants,
      };

      this.variantsData = variationData;
      return variationData;
    }
  }

  async initHeatMap() {
    let amData = await this.getAMData(
      this.selectedCategories,
      this.selectedRange
    );
    this.length = amData.variants.length;

    this.variantsData = amData;

    // const margin = { top: 20, right: 30, bottom: 40, left: 70 };
    const margin = { top: 0, right: 30, bottom: 40, left: 70 }; // for sequence on top
    const outerWidth = 800;
    const outerHeight = 400;
    const width = outerWidth - margin.left - margin.right;
    // const height = outerHeight - margin.top - margin.bottom;
    const height = 300;

    const container = d3.select('.heatmap-container');

    // Init SVG
    const svgChart = container
      .append('svg:svg')
      .attr('width', outerWidth)
      .attr('height', outerHeight)
      .attr('class', 'svg-plot-canvas')
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const x = d3
      .scaleLinear()
      .domain([1, d3.max(amData.variants, (d) => d.start)])
      .range([1, width])
      .nice();
    this.x = x;

    const xAxis = d3.axisBottom(this.x);
    let gxAxis = svgChart
      .append('g')
      .attr('transform', `translate(0, 295)`)
      .call(xAxis);

    const y = d3
      .scalePoint()
      .domain(['G','A','V','L','I','S','T','C','M','D', 'N','E','Q','R','K', 'H', 'F','Y','W','P'])
      // .range([0, outerHeight * 0.74]);
      .range([outerHeight*0.01, outerHeight * 0.72]);
    this.y = y;
    const yAxis = d3.axisLeft(y);
    const gyAxis = svgChart.append('g').call(yAxis);

    const yRight = y.copy();
    const yAxisRight = d3.axisRight(yRight);
    const gyAxisRight = svgChart
      .append('g')
      .attr('transform', `translate(${width}, 0)`)
      .call(yAxisRight);

    // Add labels
    svgChart
      .append('text')
      .attr('x', `-200`)
      .attr('dy', '-2em')
      .attr('transform', 'rotate(-90)')
      .style('font-size', '14px')
      .text('Alternative amino acids');

    svgChart
      .append('text')
      .attr('x', `250`)
      .attr('y', `335`)
      .style('font-size', '14px')
      .text('Residue sequence number');

    const xDomain = d3.range(
      1,
      d3.max(amData.variants, (d) => d.start + 1)
    );
    const hm = Heatmap.create({
      xDomain: xDomain,
      yDomain: ['G','A','V','L','I','S','T','C','M','D', 'N','E','Q','R','K', 'H', 'F','Y','W','P'],
      items: amData.variants,
      x: (d) => {
        const x = d.start;
        return x;
      },
      y: (d) => d.variant,
    });
    
    const colorScale = d3.scaleLinear(AM_COLOR_SCALE.checkpoints, AM_COLOR_SCALE.colors);
    hm.setColor((d) => d.score >= 0 ? colorScale(d.score) : AM_COLOR_SCALE.invalidColor);

    hm.setColor((d) => colorScale(d.score));
    hm.setZooming({ axis: 'x' });
    hm.setTooltip(
      (d, x, y, xIndex, yIndex) => {
        if (d.category === 'wt'){
          return `
          Score: <b>N/A</b><br>
          Variant: <b>${d.referenceVariant}${x} (reference)</b><br>
          `;
        }
        return `
          Score: <b>${formatDataItem(d.score)}</b><br>
          Variant: <b>${d.referenceVariant}${x}${y}</b><br>
        `}
    );

    hm.events.zoom.subscribe((d) => {
      if (!d) return;
      const newX = d3
      .scaleLinear()
      .domain([d.xMin, d.xMax])
      .range([1, width]);
      gxAxis.call(xAxis.scale(newX));

      const toDisplay: [number, number] = [d.xMin + 0.5, d.xMax - 0.5];
      this.zoomSubject.next({"id": "subscribe", "zoom": toDisplay});
      this.zoomSubject2.next({"id": "subscribe", "zoom": toDisplay});
    });

    hm.events.select.subscribe((e) => {
      if (!e) {
        this.clearRegion.emit();
      } else {
        const color = this.getColorResidue(e.datum.category);
        this.selectedRegion.emit({ dataX: e.x, score: e.datum.score});
        this.gaService.eventEmitter(
          "AM_heatmap_click",
          'AlphaMissense',
          'click',
          "heatmap_click",
          "Clicks on a specific amino acid on the heatmap"
        );
      }
    });

    hm.setVisualParams({ xGapPixels: 0, yGapPixels: 0 });
    this.heatmapOne = hm;
    hm.render('heatmap-test');
  }
}
