
import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css']
})
export class FileDownloadComponent {
  @Input() dropdownOptions: { label: string, value: string, isShow: boolean }[];
  @Input() amAnnotationsHg19Url: string;
  @Input() amAnnotationsHg38Url: string;
  @Input() csvAMFileUrl: string;

  @Output() selected = new EventEmitter<any>();
  components = document.querySelectorAll("[data-vf-js-dropdown]");
 
  select(value: any) {
    this.selected.emit(value);
  }
  
  ngOnInit(){
    this.components = document.querySelectorAll("[data-vf-js-dropdown]");
    const component = this.components[0];
    
    component.setAttribute("role", "menubar");
    const innerLabel = component.querySelector(".vf-dropdown__label-container");
    innerLabel.setAttribute("tabindex", "0");
    const links = component.querySelectorAll(".vf-dropdown__menu-container .vf-dropdown__menu-item-link");
    const numOfLinks = links.length;
    let currentPos = null;
    component.setAttribute("aria-expanded", "false");
    
    component.addEventListener("focusout", function() {})

    component.addEventListener("keydown", function() {})

    component.addEventListener("keyup", function() {})

    component.addEventListener("click", function() {
      component.classList.toggle("vf-dropdown--open");
    });
  } 
}