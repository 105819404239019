<div data-sticky-container>
  <div id="masthead" class="masthead" data-sticky data-sticky-on="large" data-top-anchor="main-content-area:top" data-btm-anchor="main-content-area:bottom">
    <div class="masthead-inner row">
      <!-- local-title -->
      <div class="columns small-12 medium-6" id="local-title">
        <a  class="show-for-large" [routerLink]="['/']" title="Back to AlphaFold Protein Structure Database homepage">
          <h1>AlphaFold <span class="afLogoSubtitle">Protein Structure Database</span></h1>
        </a>
        <h1 class="hide-for-large"><a [routerLink]="['/']" title="Back to AlphaFold Protein Structure Database homepage">AlphaFold<br><span class="afLogoSubtitle">Protein Structure Database</span></a></h1>

        <svg class="mobile-menu-icon" viewBox="0 0 24 24" *ngIf="!menuOpen" (click)="toggleMenu()">
          <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
        </svg>

        <svg class="mobile-menu-icon" viewBox="0 0 24 24" *ngIf="menuOpen" (click)="toggleMenu()">
          <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>

      </div>
      <div class="columns medium-6 hide-for-small-only">
        <nav class="master-nav">
          <ul id="local-nav" class="dropdown menu float-left" data-description="navigational">
            <li><a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]= "{exact: true}"
                   (click)="gaService.eventEmitter(
      'link_visit',
      'nav_href_click',
      'click',
      'home',
      '')">Home</a></li>
            <li><a id="about" [routerLink]="['about']" routerLinkActive="active"
                   (click)="gaService.eventEmitter(
      'link_visit',
      'nav_href_click',
      'click',
      'about',
      '')">About</a></li>
            <li><a [routerLink]="['faq']" routerLinkActive="active"
                   (click)="gaService.eventEmitter(
      'link_visit',
      'nav_href_click',
      'click',
      'faq',
      '')">FAQs</a></li>
            <li><a [routerLink]="['download']" routerLink="download" routerLinkActive="active"
                   (click)="gaService.eventEmitter(
      'link_visit',
      'nav_href_click',
      'click',
      'downloads',
      '')">Downloads</a></li>
            <li><a [routerLink]="['api-docs']" routerLink="api-docs" routerLinkActive="active"
                   (click)="gaService.eventEmitter(
      'link_visit',
      'nav_href_click',
      'click',
      'openapi',
      '')">API</a></li>
          </ul>
        </nav>
      </div>
      <div class="columns small-12 mobile-menu" *ngIf="menuOpen">
        <nav>
          <ul id="local-nav" class="dropdown menu" data-description="navigational">
            <li><a [routerLink]="['/']"  routerLinkActive="active" [routerLinkActiveOptions]= "{exact: true}">Home</a></li>
            <li><a id="about" [routerLink]="['about']" routerLinkActive="active">About</a></li>
            <li><a [routerLink]="['faq']" routerLinkActive="active">FAQs</a></li>
            <li><a [routerLink]="['download']" routerLink="download" routerLinkActive="active">Downloads</a></li>
            <li><a [routerLink]="['api-docs']" routerLink="api-docs" routerLinkActive="active">API</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

  <div class="header-search-section home-banner" *ngIf="homePage">
    <div class="row">
      <div class="columns small-12">
        <div class="homeBannerText">
          <h2 class="homeBannerTitle">AlphaFold<br class="hide-for-small-only"> Protein Structure Database</h2>
          <p class="homeBannerSubtitle">Developed by Google DeepMind and EMBL-EBI</p>
        </div>
        <div class="home-banner-search">
          <app-header-search [homePage]="homePage" ></app-header-search>
        </div>
      </div>
    </div>
  </div>

  <div class="header-search-section" *ngIf="!homePage">
    <div class="row">
      <div class="columns small-12">
        <app-header-search [homePage]="homePage" ></app-header-search>
      </div>
    </div>
  </div>
  
</div>
