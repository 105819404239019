import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-entry-status',
  templateUrl: './entry-status.component.html',
  styleUrls: ['./entry-status.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntryStatusComponent {
  @Input() isSummary: boolean;
  @Input() isProteome: boolean;
  @Input() isReviewed: boolean;
  @Input() clusterTable: boolean;
}
