import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { FaqsListComponent } from '../faqs-list/faqs-list.component';
@Component({
  selector: 'app-entry-help',
  templateUrl: './entry-help.component.html',
  styleUrl: './entry-help.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntryHelpComponent {
  @Input() accession: string;
  constructor(public gaService: GoogleAnalyticsService) {}
}
