import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, HostListener} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faqs-list',
  templateUrl: './faqs-list.component.html',
  styleUrls: ['./faqs-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqsListComponent implements OnInit {

  @Input() count: number;
  @Input() idsFilter: string[];
  @Input() paeExplaination: boolean;
  @Input() expandAll: any = {state: false};
  @Input() defaultOpen: number[];
  @Output()
  expandAllState: EventEmitter<boolean> = new EventEmitter();
  infoSectionHeight: any = {maxHeight: '1000px', overflow: 'hidden'};
  faqList: any[];
  openFaqs: number[];

  constructor( private router: Router,) {
  }

  @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
      const proteinExamples = ['Free fatty acid receptor 2','At1g58602','Q5VSL9'];
      const e = event.target as Element;
      if(e.className === "proteinNames" && proteinExamples.includes(e.textContent)){
        this.router.navigate(['search','text', e.textContent]);
      }else{
        event.stopPropagation();
      }
    }
  ngOnInit(): void {
    this.openFaqs = this.defaultOpen ? this.defaultOpen : [];
   
    this.faqList = [
      {
        question: 'How does AlphaFold work?',
        answer: '<p>DeepMind’s 2021 <a href="https://www.nature.com/articles/s41586-021-03819-2" target="_blank">methods paper</a> ' +
          'is the best reference for this. It gives an overview of the most important ideas, and there is a detailed ' +
          'description of all aspects of the system in the Supplementary Information.<br/>Note that the architecture ' +
          'of the system used at CASP14 differs significantly from the version used at CASP13, making it important to ' +
          'refer to the 2021 publication.</p>'+'<p>Visit our <a href="https://www.ebi.ac.uk/training/online/courses/alphafold/" target="_blank">online training course</a> to learn more about AlphaFold.</p>',
        id: 'faq-1'
      },
      {
        question: 'What is AlphaMissense?',
        answer: '<p>AlphaMissense is an AI model that builds on Google DeepMind’s AlphaFold2 to categorise ‘missense’ mutations in different proteins as either ‘likely pathogenic’, ‘likely benign’ or ‘uncertain’, producing a score that estimates the likelihood of a variant being pathogenic. AlphaMissense leverages AlphaFold2’s capability to model protein structure, and its capacity to learn evolutionary constraints from related sequences. The implementation is closely aligned with AlphaFold2, with some architectural differences.<p>'+
        '<p>AlphaMissense was used to classify the effects of all possible 216 million single amino acid sequence substitutions across the 19,233 <a href="https://www.uniprot.org/help/canonical_and_isoforms" target="_blank">canonical</a> human proteins.</p>'+
        '<p>Using an amino acid sequence as an input, AlphaMissense:</p>'+
        '<ul>' +
          '<li>Gives an indication of which mutations are more likely to underlie human diseases - such as rare genetic disorders or developmental diseases - by categorising missense mutations into likely pathogenic or likely benign. Combined with other types of information, it can help to decipher what mutations may be causing a disease.</li>' +
          '<li>Helps to highlight potential functionally important regions of the protein.</li>' +
        '</ul>'+
        '<p><b>Note that AlphaMissense does not predict the change in protein structure, or biophysical properties such as stability, upon mutation. Instead, it uses related protein sequences and protein structure as contextual information to estimate pathogenicity.</b></p>'+
        '<p>For more information about AlphaMissense, please refer to the paper: <a href="https://www.science.org/doi/10.1126/science.adg7492" target="_blank" (click)="gaService.eventEmitter(\n' +
        '      \'faq_am_link_viist\',\n' +
        '      \'Whats_new\',\n' +
        '      \'click\',\n' +
        '      \'News_FAQ_link\',\n' +
        '      \'Click on link to FAQ about AlphaMissense\')">Accurate proteome-wide missense variant effect prediction with AlphaMissense. Science (2023).</a></p>'+
        '<p>AlphaMissense scores for all human missense variants are available on the <a href="https://console.cloud.google.com/storage/browser/dm_alphamissense;tab=objects?prefix=&forceOnObjectsSortingFiltering=false" target="_blank">Google Cloud Public Dataset<a>.</p>',
        id: 'faq-22'
      },
      {
        question: 'What data can I download from AlphaMissense?',
        answer: '<p>We provide downloadable files in csv format to help you explore the data for a specific canonical human protein:<p>'+
        '<ul>' +
          '<li>Heatmap data. This file contains the scores estimating the likelihood of  pathogenicity and classifications for each possible amino acid substitution within the protein. This file is used to visualise the heatmap you see on the entry pages. <br/>' +
          'Column am_class categorises the mutation based on the score:</li>' +
          '<ul><li>LPath: Likely pathogenic</li><li>LBen: Likely benign</li><li>Amb: Ambiguous</li></ul>'+
          '<li>Pathogenicity scores (HG19 and HG38). These files include AlphaMissense scores for all possible missense single nucleotide variants across the human reference genome. Each file corresponds to a specific genome assembly with information on specific genome position.</li>' +
          '<ul><li>HG19. Corresponds to the GRCh37 human genome reference assembly.</li><li>HG38. Corresponds to the GRCh38 human genome reference assembly.</li></ul>'+
        '</ul>'+
        '',
        id: 'faq-23'
      },
      {
        question: 'What information does AlphaFold use from the Protein Data Bank?',
        answer: '<p>AlphaFold is trained on protein chains in the PDB released before 2018-04-30. \n' +
          'Predictions can also make use of up to 4 templates released before 2021-02-15. However, templates are not' +
          ' a critical input for AlphaFold to make an accurate prediction; the model can make a strong prediction ' +
          'based on a multiple sequence alignment alone. </p> <p>Additionally, AlphaFold can ignore templates if they appear ' +
          'unhelpful - it isn’t required to copy their structure. As of this latest release, PDB structures shown to ' +
          'the model are recorded in the prediction mmCIF file.</p>',
        id: 'faq-2'
      },
      {
        question: 'How does AlphaFold compare to other structure prediction tools?',
        answer: '<p>The CASP14 assessment compared leading structure prediction methods in detail; the results are available <a href="https://predictioncenter.org/casp14/zscores_final.cgi" target="_blank">here</a>. AlphaFold was the top-ranked method, with a median GDT (Global Distance Test) score of 92.4 across all targets and 87.0 on the challenging free-modelling category, compared to 72.8 and 61.0 for the next best methods in these categories.</p><p>Structural biologists more often express the similarity between two protein structures by first optimally superposing the structures and then calculating the root-mean-square distance (RMSD) between the Cα atoms of equivalent residues. Taking the median RMSD-Cα on the best predicted 95% of residues reduces the effect of flexible tails and crystal-packing artefacts. On this metric AlphaFold’s CASP14 predictions had a median distance of 0.96 Å to the experimental models, compared to 2.83 Å for the next-best method. </p>',
        id: 'faq-3'
      },
      {
        question: 'Which proteins are included?',
        answer: '<p>AlphaFold DB has grown in several stages: </p>' +
        '<ul>' +
          '<li>July 2021: included 20 model organism proteomes, with sequences taken from the “one sequence per gene” reference proteomes provided in UniProt 2021_02.</li>' +
          '<li>December 2021: added Swiss-Prot, from UniProt 2021_04.</li>' +
          '<li>January 2022: added a collection of proteomes relevant to global health, taken from priority lists compiled by the World Health Organisation. Sequences were again taken from the “one sequence per gene” reference proteomes provided in UniProt 2021_04.</li>' +
          '<li>July 2022: added most of the remaining UniProt 2021_04. As part of this release we have also included an additional tar file on the AFDB download page and FTP, containing predictions in <a href="https://www.ncbi.nlm.nih.gov/refseq/MANE/" target="_blank">MANE</a> select.</li>' +
          '<li>Nov 2022: updated a set of structures affected by a temporary numerical bug (miscompilation) in the previous July release (<a target="_blank" href="https://ftp.ebi.ac.uk/pub/databases/alphafold/v3/v3_affected_accessions.txt">list of affected accessions</a>, N.B. 160 MiB). This temporary issue resulted in low accuracy predictions with correspondingly low pLDDT for ~4% of the total structure predictions available in the database. This release includes:' +
            '<ul class="faq-circle">' +
              '<li class="faq-circle-bullet"><strong>Updated coordinates for affected structures.</strong> You can still access all old coordinates as v3 files, and easily compare v3 and v4 coordinates</li>' +
              '<li><strong>Minor metadata changes in the mmCIF files</strong> for the rest of the structures (these files are released as v4). Please refer to our <a href="https://ftp.ebi.ac.uk/pub/databases/alphafold/CHANGELOG.txt" target="_blank">changelog</a> for more details. Note that as part of this release we’ve also removed predictions with Ca-Ca >10A.</li>' +
            '</ul>' +
          '</li>' +
        '</ul>' +
          '<p>The wider UniProt predictions are the output of a single model, while Swiss-Prot / proteomes entries represent the most confident prediction out of 5 model runs. Internal benchmarking on CASP14 shows that the model used for UniProt (“model_2_ptm”) is insignificantly less accurate (-1 GDT versus five models), and that there is a slight bias toward lower confidence (-1 pLDDT) due to the effect of using one model rather than selecting from 5.</p>' +
          '<p>Not all sequences are covered; the most common reasons for a missing sequence are:</p>' +
          '<ul>' +
            '<li>It is outside our length range. The minimum length is 16 amino acids, while the maximum is 2,700 for proteomes / Swiss-Prot and 1,280 for the rest of UniProt. For the human proteome only, our download includes longer proteins segmented into fragments.</li>' +
            '<li>It contains non-standard amino acids (e.g. X).</li>' +
            '<li>It is not in the UniProt reference proteome “one sequence per gene” Fasta file.</li>' +
            '<li>It has been added or modified by UniProt in a more recent release.</li>' +
            '<li>It is a viral protein. These are currently excluded, pending improved support for polyproteins.</li>' +
          '</ul>' +
          '<p>We plan to continue updating the database. In the meantime, if your sequence(s) aren’t included, you can generate your own AlphaFold predictions using Google DeepMind’s <a href="https://bit.ly/alphafoldcolab" (click)="gaService.eventEmitter(\n' +
          '      \'link_visit\',\n' +
          '      \'home_href_click\',\n' +
          '      \'click\',\n' +
          '      \'colab_code\',\n' +
          '      \'\')" target="_blank">Colab notebook</a> and <a (click)="gaService.eventEmitter(\n' +
          '      \'link_visit\',\n' +
          '      \'home_href_click\',\n' +
          '      \'click\',\n' +
          '      \'source_code\',\n' +
          '      \'\')" href="https://github.com/deepmind/alphafold/" target="_blank">open source code</a>, which also support multimer predictions.</p>',
        id: 'faq-4'
      },
      {
        question: 'How many proteins are there in the database?',
        answer: '<p>There are 214,683,839 structures available on the AlphaFold DB website, including 48 complete proteomes available for bulk download. An additional 3,095 structures are included in the human proteome download, covering sequences longer than our usual length limit split into fragments.</p>',
        id: 'faq-5'
      },
      {
        question: 'What use cases does AlphaFold not support?',
        answer: '<p>AlphaFold DB currently focuses on the use case validated in CASP14: predicting the structure of a single protein chain ' +
        'with a naturally occurring sequence. Many other use cases remain active areas of research, for example:</p><ul><li>The ' +
        'version of AlphaFold used to construct in this database does not output multi-chain predictions (complexes).  In some cases the single-chain ' +
        'prediction may correspond to the structure adopted in complex. In other cases (especially where the chain is structured ' +
        'only on binding to partner molecules) the missing context from surrounding molecules may lead to an uninformative prediction. ' +
        'A separate version of AlphaFold was trained for complex prediction ' +
        '(<a href="https://www.biorxiv.org/content/10.1101/2021.10.04.463034v1.full.pdf" target="_blank">AlphaFold Multimer</a>). You can find the open ' +
        'source code on <a href="https://github.com/deepmind/alphafold" target="_blank">GitHub</a> and make multimer predictions using Google DeepMind’s <a href="https://colab.sandbox.google.com/github/deepmind/alphafold/blob/main/notebooks/AlphaFold.ipynb" target="_blank">Colab</a>.</li><li>For regions that are intrinsically disordered or ' +
        'unstructured in isolation AlphaFold is expected to ' +
        'produce a low-confidence prediction (pLDDT < 50) and the predicted structure will have a ribbon-like appearance. AlphaFold ' +
        'may be of use in identifying such regions, but the prediction makes no statement about the relative likelihood of different ' +
        'conformations (it is not a sample from the <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2366981/" target="_blank">Boltzmann distribution</a>). </li><li>AlphaFold has not been validated for predicting ' +
        'the effect of mutations. In particular, AlphaFold is not expected to produce an unfolded protein structure given a sequence ' +
        // tslint:disable-next-line:max-line-length
        'containing a destabilising point mutation. </li><li>Where a protein is known to have multiple conformations, AlphaFold usually ' +
        'only produces one of them. The output conformation cannot be reliably controlled. </li><li>AlphaFold does not predict the ' +
        'positions of any non-protein components found in experimental structures (such as cofactors, metals, ligands, ions, DNA/RNA, ' +
        'or post-translational modifications). However, AlphaFold is trained to predict the structure of proteins <i>as they might ' +
        'appear in the PDB</i>. Therefore backbone and side chain coordinates are frequently consistent with the expected structure in the ' +
        'presence of ions (e.g. for zinc-binding sites) or cofactors (e.g. side chain geometry consistent with heme binding). </li></ul>',
      id: 'faq-6'
      },
      {
        question: 'How do I search the database',
        answer: '<p>The search bar at the top of the page accepts queries based on protein name ' +
        '(e.g. <a class="proteinNames">Free fatty acid receptor 2</a>), ' +
        'gene name (e.g. <a class="proteinNames"">At1g58602</a>), UniProt accession ' +
        '(e.g. <a class="proteinNames">Q5VSL9</a>), or organism name ' +
        '(e.g. <a href="./search/organismScientificName/Escherichia%20coli%20%28strain%20K12%29">E. coli</a>).' +
        '</p>',
        id: 'faq-7'
      },
      {
        question: 'How does sequence-based search work?',
        answer: '<p>AlphaFold Database sequence-based similarity search is implemented using the Protein Basic Local Alignment Search Tool (BLASTP, see further information here: <a href="https://github.com/ncbi/docker/blob/master/blast/README.md)" target="_blank">https://github.com/ncbi/docker/blob/master/blast/README.md)</a>. This tool compares a protein sequence query to the sequences of the predictions in the AlphaFold Database and returns a list of AlphaFold predictions with similar sequences to the one the user provided. </p>'+

        '<p>The search process can take up to 10 minutes to complete. To revisit, review or share your results, you can copy or bookmark the url to the results page. Note that the query must be at least 20 amino acids long and only standard residues are accepted.</p>' +
        
        '<p>We display a pairwise sequence alignment, where the top row is the input sequence, the middle row is the matching amino acid positions between the input sequence query and the target sequence from the database, and the bottom row shows the target sequence. </p>',
        
        // +'<p>Results can be sorted by HSP score (Local alignment with no gaps that achieves one of the highest alignment scores in a given search), E-value (Number of hits the query sequence is expected to match by random chance with the AlphaFold database sequences), Percent identity, query coverage (Fraction of query sequence covered by a hit sequence), target length (number of amino acids in the target sequence) or align length (number of amino acids aligned between input and target sequences).</p>'
        id: 'faq-8'
      },
      {
        question: 'What if I can’t find the protein I’m interested in?',
        answer: '<p>If you can’t find the structure you’re looking for, here are some suggestions to improve your search results:</p>' +
        '<ul><li>Try searching by protein or gene name rather than specific UniProt accession.</li>' +
        '<li>If running a sequence search, the input query should contain at least 20 amino acids, and only standard amino acids are accepted. </li>' +
        '<li>For human proteins longer than 2,700 amino acids, check the whole proteome <a href="/downlad">download</a>. This contains longer proteins predicted as overlapping fragments. For example, Titin has predicted fragment structures named as Q8WZ42-F1 (residues 1–1400), Q8WZ42-F2 (residues 201–1600), etc. </li>' +
        '<li>Check that the protein isn’t <i>excluded</i> by any of the criteria covered in a <a href="/faq#faq-4">previous FAQ</a>.</li></ul>' +
        '<br>' +
        'The AlphaFold <a href="https://github.com/deepmind/alphafold/" target="_blank">source code</a> ' +
        'and <a href="https://bit.ly/alphafoldcolab" target="_blank">Colab notebook</a> can be ' +
        'used to predict the structures of proteins not in AlphaFold DB. Both resources have been updated to support predicting multimer structures.<br><br>' +
        'If you experience any issues with search, please contact ' +
        '<a href="mailto:afdbhelp@ebi.ac.uk">afdbhelp@ebi.ac.uk</a>.',
        id: 'faq-9'
      },
      {
        question: 'What is included on a structure page?',
        answer: '<p>Structure pages show basic information about the protein (drawn from UniProt), and three separate outputs from AlphaFold.  \n' +
        '<br><br>The first is the 3D coordinates (including side chains if you click on the sequence in the viewer). ' +
        '<br><br>The second output is a per-residue confidence metric called <a href="/faq#faq-12">pLDDT</a>, which is used to colour the residues of the prediction. ' +
        'Note that model confidence can vary greatly along a chain so it is important to consult the confidence estimates when interpreting ' +
        'structural features. The lower confidence bands may be associated with disorder (see our ' +
        '<a href="https://www.nature.com/articles/s41586-021-03828-1" target="_blank">publication</a>).  ' +
        '<br><br>The third output is Predicted Aligned Error, which is necessary to assess confidence in the domain packing and ' +
        'large-scale topology of the protein.  See the FAQ below on <a href="/faq#faq-13">how to ' +
        'interpret relative domain positions</a>.</p>',
        id: 'faq-10'
      },
      {
        question: 'How can I download a structure prediction?',
        answer: '<p>Coordinate files can be downloaded from the menu in the top right of the structure page in mmCIF or PDB format. These formats are widely accepted by 3D structure viewing software, such as PyMOL and Chimera.</p>',
        id: 'faq-11'
      },
      {
        question: 'How confident should I be in a prediction?',
        answer: '<p>AlphaFold produces a per-residue estimate of its confidence on a scale from 0 - 100. ' +
          'This confidence measure is called pLDDT and corresponds to the model’s predicted score on ' +
          'the <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/" target="_blank">lDDT-Cα metric</a>. ' +
          'It is stored in the B-factor fields of the mmCIF and PDB files available for download (although unlike a ' +
          'B-factor, higher pLDDT is better). pLDDT is also used to colour-code the residues of the model in the 3D ' +
          'structure viewer. The following rules of thumb provide guidance on the expected reliability of a given ' +
          'region:</p><ul><li>Regions with pLDDT > 90 are expected to be modelled to high accuracy. These should be ' +
          'suitable for any application that benefits from high accuracy (e.g. characterising binding sites). </li><li>' +
          'Regions with pLDDT between 70 and 90 are expected to be modelled well (a generally good backbone prediction). ' +
          '</li><li>Regions with pLDDT between 50 and 70 are low confidence and should be treated with caution. </li><li>The ' +
          '3D coordinates of regions with pLDDT < 50 often have a ribbon-like appearance and should not be interpreted. ' +
          'We show in our paper that pLDDT < 50 is a reasonably strong predictor of disorder, i.e. it suggests such a ' +
          'region is either unstructured in physiological conditions or only structured as part of a complex. (Note: this relationship ' +
          'has typically been tested in the context of well-studied proteins, which may have more evolutionarily-related sequences ' +
          'available than a randomly chosen UniProt entry.)</li><li>Structured ' +
          'domains with many inter-residue contacts are likely to be more reliable than extended linkers or isolated long helices. ' +
          '</li><li>Unphysical bond lengths and clashes do not usually appear in confident regions. Any part of a structure with ' +
          'several of these should be disregarded.</li></ul><p>Note that the PDB and mmCIF files contain coordinates for all regions, ' +
          'regardless of their pLDDT score. It is up to the user to interpret the model judiciously, in accordance with the ' +
          'guidance above.</p>',
        id: 'faq-12'
      },
      {
        question: 'How should I interpret the relative positions of domains?',
        answer: '<p>Independent of the 3D structure, AlphaFold produces an output called “Predicted Aligned Error” (PAE). ' +
        'This is shown at the bottom of structure pages as an interactive 2D plot.</p><ul><li> The colour at (x, y) ' +
        'indicates AlphaFold’s expected position error at residue x if the predicted and true structures were aligned on ' +
        'residue y. </li><li> If the PAE is generally low for residue pairs x, y from two different ' +
        'domains, it indicates that AlphaFold predicts well-defined relative positions and orientations for them. </li><li> If the PAE ' +
        'is generally high for residue pairs x, y from two different domains, then the relative positions and/or orientations of ' +
        'these domains in the 3D structure are uncertain and should not be interpreted. </li></ul><p> AlphaFold produces a ' +
        'useful inter-domain prediction in some cases. However, in CASP14 intra-domain prediction accuracy was more extensively ' +
        'validated and is therefore expected to be more reliable. </p>',
        id: 'faq-13'
      },
      {
        question: 'How can I download and use the Predicted Aligned Error (PAE) file?',
        answer: '<p>The PAE is displayed as an image for each of the structure predictions. ' +
        'If you need the raw data with PAE for all residue pairs, you can download the PAE as a JSON ' +
        'file using the button at the top of the structure page.</p>' +
        '<p>This file is in a custom format and it isn\'t supported by any existing software – ' +
        'you will have to use Python or another programming language to analyse or plot the information that is contained in it.</p>' +
        'For a protein of length <span class="monospace">num_res</span>, the JSON file has the following structure of arrays format:<br><span class="monospace">' +
        '[<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;{<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"predicted_aligned_error": [[0, 1, 4, 7, 9, ...], ...],  # Shape: (num_res, num_res).<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"max_predicted_aligned_error": 31.75<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;}<br>' +
        ']</span><br><br>' +
        '<p>The fields in the JSON file are:</p>' +
        '<ul>' +
        '<li><span class="monospace">predicted_aligned_error</span>: The PAE value of the residue pair, rounded to the closest integer. For the PAE value at position <i>(i, j), i</i> is the residue on which the structure is aligned, <i>j</i> is the residue on which the error is predicted.</li>' +
        '<li><span class="monospace">max_predicted_aligned_error</span>: A number that denotes the maximum possible value of PAE. The smallest possible value of PAE is 0.</li>' +
        '</ul>' +
        '<p>We updated the PAE JSON file format on 28th July 2022 to reduce file size by 4x. Please ensure you read the 2D matrix of PAE values from the predicted_aligned_error field instead of the removed 1D "distances" field and avoid using the old "residue1" and "residue2" fields.</p>' +
        '<p>If you are using a script or third party tool to read the PAE JSON file programmatically and you are seeing errors (e.g. missing field "distance"), check with the author of the program whether the latest PAE JSON format is supported.</p>',
        id: 'faq-14'
      },
      {
        question: 'How can I bulk download the data?',
        answer: '<p>Predictions for individual proteomes and for Swiss-Prot are available via our <a href="/download">downloads</a> page ' +
        'and via the FTP site:<br>' +
        '<a target="_blank" href="https://ftp.ebi.ac.uk/pub/databases/alphafold">https://ftp.ebi.ac.uk/pub/databases/alphafold</a>.</p>' +
        '<p>The full dataset containing all predictions is available at no cost and under a ' +
        '<a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC-BY-4.0</a> licence ' +
        'from Google Cloud Public Datasets. ' +
        'The size is ~23TiB, and we expect that most users will be better served by downloading only a subset of the files ' +
        'relevant to their use case. Please refer to ' +
        'our <a href="https://github.com/deepmind/alphafold/blob/main/afdb/README.md" target="_blank">readme</a> ' +
        'for more details on working with the full dataset.</p>',
        id: 'faq-15'
      },
      {
        question: 'How can I search for similar structures in the AlphaFold Database?',
        answer: '<p>Foldseek has been integrated into the AlphaFold Database, enabling easy access to similar structures across both experimentally determined structures from the PDB (Protein Data Bank) and predicted structures from the clustered AlphaFold Database (AFDB50).</p>' +
        '<p>Search results are organised into two tabs:' +
        '<ul><li> PDB structures: These are structures determined using experimental methods. Please note that NMR (Nuclear Magnetic Resonance) structures do not have a resolution value associated with them.</li><li> AFDB50 structures: These are predicted structures available from the AlphaFold Database, clustered at 50% sequence identity to reduce redundancy. </li></ul>' +
        '</p>'+'<p> Each row includes: a pairwise sequence alignment, residue range indicating the specific range of amino acids in the alignment, E-value to show the statistical significance of the alignment, sequence identity, resolution (for PDB structures), and average pLDDT (for AlphaFold2 structures).'+'</p><p>You can select which structures to superimpose in the 3D viewer. The structural alignment, along with RMSD (Root Mean Square Deviation) is reported based on the superposition of Cɑ atoms. Once the structures are aligned, you can download the aligned coordinates in mmCIF format.</p>',
        id: 'faq-24'
      },
      {
        question: 'What is Foldseek?',
        answer: '<p>Foldseek is a tool for fast and sensitive protein structure search. It compares protein structures by representing them as sequences over a 3Di alphabet that describes the local tertiary interactions between residues in the structure. This allows Foldseek to efficiently search vast protein structure databases to find similar structures. <br><br>'+
        'For technical details on Foldseek, please refer to the original publication: <a href="https://www.nature.com/articles/s41587-023-01773-0" target="_blank">Fast and accurate protein structure search with Foldseek. Nature (2023)</a>.</p>',
        id: 'faq-25'
      },
      {
        question: 'How do cluster members work?',
        answer: '<p>Collaborating with the <a href="https://steineggerlab.com/en/" target="_blank">Steinegger lab</a>, we grouped structurally similar proteins into clusters using <a href="https://cluster.foldseek.com/" target="_blank">Foldseek Cluster</a>. Our clustering approach was a two-phase process:' +
        '</p><p>The first phase, AFDB50/MMSeqs2, involved clustering the UniProtKB protein sequences from the AlphaFold Database using <a href="https://www.nature.com/articles/nbt.3988" target="_blank">MMseqs2</a>, with a maximum of 50% sequence identity and a 90% sequence overlap. This reduced dataset was called AFDB50.  In the second phase, AFDB/Foldseek, proteins were grouped based on structural similarity. Representatives of each cluster from the first phase were chosen according to the highest pLDDT score. Structural clustering was performed with <a href="https://cluster.foldseek.com/" target="_blank">Foldseek Cluster</a>, using a minimum structural alignment overlap of 90% and an E-value cutoff of 0.01. </p>'+
        '<p>For more details about the clustering process, please refer to the following paper: <a href="https://www.nature.com/articles/s41586-023-06510-w" target="_blank">Clustering predicted structures at the scale of the known protein universe. Nature (2023).</a></p>',
        id: 'faq-21'
      },
      {
        question: 'How should I cite this resource?',
        answer: '<p>EMBL-EBI expects attribution (e.g. in publications, services or products) for any of its online services, ' +
        'databases or software in accordance with good scientific practice.<br><br>' +
        'If you use an AlphaFold DB prediction in your work, please cite the following papers:<br/>' +
        '<a href="https://www.nature.com/articles/s41586-021-03819-2" target="_blank">Jumper, J <i>et al</i>. ' +
        'Highly accurate protein structure prediction with AlphaFold. <i>Nature</i> (2021)</a>.</p>' +
        '<p><a href="https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkab1061/6430488" target="_blank">' +
        'Varadi, M <i>et al</i>. AlphaFold Protein Structure Database: massively expanding the structural coverage of ' +
        'protein-sequence space with high-accuracy models. <i>Nucleic Acids Research </i>(2021)</a>. </p>',
        id: 'faq-16'
      },
      {
        question: 'What licence applies to the predictions?',
        answer: '<p>All of the data provided is freely available for both academic and commercial ' +
        'use under Creative Commons Attribution 4.0 (<a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">' +
        'CC-BY 4.0</a>) licence terms.</p>',
        id: 'faq-17'
      },
      {
        question: 'Where can I deposit AlphaFold structure predictions that are not in the database?',
        answer: '<p>We do not currently have functionality to deposit structure predictions. If you have generated AlphaFold structure predictions that you would like to make available to the community, you can take a look at the <a href="https://rdmkit.elixir-europe.org/structural_bioinformatics.html" target="_blank">Research Data Management kit</a> being developed by the ELIXIR 3D-BioInfo community that describes guidelines on how to make models and the relevant metadata available according to FAIR principles. </p>',
        id: 'faq-18'
      },
      {
        question: 'Who should I contact with enquiries?',
        answer: '<p>For questions and feedback about the AlphaFold DB website, please contact <a href="mailto:afdbhelp@ebi.ac.uk">' +
        'afdbhelp@ebi.ac.uk</a>.</p>' +
        '<p>For sharing feedback on structure predictions or for questions about AlphaFold not directly related to the database, please contact the AlphaFold team at <a href=mailto:alphafold@deepmind.com">alphafold@deepmind.com</a>. We may not be able to respond to every query and there may be some delay before we can get back to you.</p>'+
        '<p>For other questions about AlphaFold not directly related to the database, please contact the AlphaFold team at <a href="mailto:alphafold@deepmind.com">alphafold@deepmind.com</a>. Please do not share anything confidential with Google DeepMind.</p>'+
        '<p>For press enquiries, please contact <a href="mailto:press@deepmind.com">press@deepmind.com</a> ' +
        'or <a href="mailto:comms@ebi.ac.uk">comms@ebi.ac.uk</a>.</p>',
        id: 'faq-19'
      },
      {
        question: 'How can I get in touch about my experience with the AlphaFold DB?',
        answer: '<p>We would love to hear your feedback and understand how the database has ' +
        'been useful in your research. Share your stories with us at ' +
        '<a href="mailto:alphafold@deepmind.com">alphafold@deepmind.com</a>.</p>',
        id: 'faq-20'
      }
    ];

    if (this.paeExplaination) {
      this.faqList = [
        {
          question: 'How to interpret the Predicted Aligned Error',
          answer: ''
        }
      ];
    }
    /**
     * Add in idFilter the faq-ids to filter the original list, if 'count' has a value, it'll be added to the end of the
     * list.
     * There could be duplicate questions if you add an id that was already added when the 'slice' is done, be careful
     */
    if (this.count) {
      const filterdFaqList = this.faqList.filter((item) => this.idsFilter.includes(item.id));
      this.faqList = this.faqList.slice(0, this.count);
      this.faqList.push(...filterdFaqList);
    }


    if (this.expandAll.state) {
      this.toggleAll(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if (changes.expandAll && changes.expandAll.currentValue.expandAllBtn) {
      this.toggleAll(changes.expandAll.currentValue.state);
    }
  }

  toggleAll(open) {
    if (open) {
      this.openFaqs = [...Array(this.faqList.length).keys()];
    } else {
      this.openFaqs = [];
    }
  }

  toggleFaq(fi) {
    const openFaqIndex = this.openFaqs.indexOf(fi);
    if (openFaqIndex > -1) {
      this.openFaqs.splice(openFaqIndex, 1);
    } else {
      this.openFaqs.push(fi);
    }

    // manage show all flag
    this.expandAll.state = false;
    if (this.openFaqs.length === this.faqList.length) {
      this.expandAll.state = true;
    }

    this.expandAllState.emit(this.expandAll.state);
  }

}
