<span *ngIf="!clusterTable">
    <span *ngIf="isReviewed" class="badgeSvgFirst">
        <div class="label-tooltip" style="display: inline;">
            <img [ngClass]="isSummary == true ? 'badgeTitleSvg' : 'badgeSvg'" src="assets/img/reviewed.svg" /> 
            <span class="label-tooltip-text hide-for-small-only">
                Reviewed
            </span>
        </div>
    </span>
   
    <span *ngIf="!isReviewed" class="badgeSvgFirst">
        <div class="label-tooltip" style="display: inline;">
            <img [ngClass]="isSummary == true ? 'badgeTitleSvg' : 'badgeSvg'" class="badgeSvg" src="assets/img/unreviewed.svg" /> 
            <span class="label-tooltip-text hide-for-small-only">
                Unreviewed
            </span>
        </div>
    </span>
    
    <span *ngIf="isProteome" class="badgeSvgFirst">
        <div class="label-tooltip" style="display: inline;">
            <img [ngClass]="isSummary == true ? 'badgeTitleSvg' : 'badgeSvg'" class="badgeSvg" src="assets/img/reference-proteome.svg" />
            <span class="label-tooltip-text hide-for-small-only refPro">
                Reference proteome
            </span>
        </div>
    </span>
</span>

<span *ngIf="clusterTable" style="display:flex;">
    <span *ngIf="isReviewed === 'true'" class="badgeSvgFirst">
        <div class="label-tooltip" style="display: inline;">
            <img [ngClass]="isSummary == true ? 'badgeTitleSvg' : 'badgeSvg'" src="assets/img/reviewed.svg" /> 
            <span class="label-tooltip-text hide-for-small-only refPro">
                Reviewed (Swiss-Prot)
            </span>
        </div>
    </span>
   
    <span *ngIf="isReviewed === 'false'" class="badgeSvgFirst">
        <div class="label-tooltip" style="display: inline;">
            <img [ngClass]="isSummary == true ? 'badgeTitleSvg' : 'badgeSvg'" class="badgeSvg" src="assets/img/unreviewed.svg" /> 
            <span class="label-tooltip-text hide-for-small-only">
                Unreviewed
            </span>
        </div>
    </span>
    
    <span *ngIf="isProteome === 'true'" class="badgeSvgFirst">
        <div class="label-tooltip" style="display: inline;">
            <img [ngClass]="isSummary == true ? 'badgeTitleSvg' : 'badgeSvg'" class="badgeSvg" src="assets/img/reference-proteome.svg" />
            <span class="label-tooltip-text hide-for-small-only refPro">
                Reference proteome
            </span>
        </div>
    </span>
</span>