import { Component, OnInit, ElementRef, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { Router, RoutesRecognized} from '@angular/router';
import { Subject } from 'rxjs';
import { CommonService } from '../common.service';
import { ConfigService } from '../config.service';
import { debounceTime, distinctUntilChanged, filter  } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {GoogleAnalyticsService} from '../google-analytics.service';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.css'],
  host: {
    '(document:click)': 'onClick($event)',
    '(document:keydown)': 'onKeypress($event)'
  }
})
export class HeaderSearchComponent implements OnInit {

  @Input() homePage: boolean;

  @Output()
  itemSelected:EventEmitter<string> = new EventEmitter();

  resultPanelStyle: any = {'max-height': '100%'};
  processMessage = 'Loading..'
  suggestions: any;
  searchTermStream = new Subject<string>();
  panelOpen: boolean;
  events: any;
  showClearIcon: boolean = false;
  routeSubscriber: Subscription;
  seenInfo: string = "true";
  isNewsSeen: string = "false";

  constructor(private router: Router, private commonService: CommonService, private changeDetectorRef:ChangeDetectorRef, private el:ElementRef, private configService: ConfigService, public gaService: GoogleAnalyticsService) {}

  /**
   * Method to close result panel on click outside component
   * @public
   * @param {event} DOM event object.
   * @returns void
   */
  onClick(event: MouseEvent) {
    if (!this.el.nativeElement.contains(event.target) && (event.target as Element).className != 'result-card-item-count-heading' && (event.target as Element).className != 'show-more-link'){
      // this.hideAllPanels();
      // this.searchInputVal = '';
      this.panelOpen = false;
     // this.showClearIcon = false;
      //this.el.nativeElement.querySelector('.pdbeAutoCompleteSearchBox').value = '';
    }
  }

  /**
   * Method to close result panel on Esc key press
   * @public
   * @param {event} DOM event object.
   * @returns void
   */
  onKeypress(event: KeyboardEvent) {
    if (event.key === 'Escape'){
      // this.hideAllPanels();
      // this.searchInputVal = '';
      this.panelOpen = false;
      this.showClearIcon = false;
     this.el.nativeElement.querySelector('.pdbeAutoCompleteSearchBox').value = '';
    }
  }

  /**
   * Method to push a search term into the observable stream.
   * @private
   * @param {strings} search term.
   * @returns void
   */
  search(term: string): void {
    //this.searchInputValChange.emit(term);
    this.changeDetectorRef.detectChanges();
    this.searchTermStream.next(term);
  }

  /**
   * Method to toggle page scroll
   * @public
   * @param {strings} hide/show
   * @returns void
   */
  togglePageScroll(action: string): void{
    this.el.nativeElement.ownerDocument.body.style.overflow = 'auto';
    if(action == 'hide') this.el.nativeElement.ownerDocument.body.style.overflow = 'hidden';
  }

  /**
   * Method to create result item redirection link.
   * @public
   * @param {object}: result item object
   * @returns {string} redirection url
   */
  public resultItemLink(category: string, resultRecord: string, totalResultRecords: number): string {
    let url: string;
    if (category == "uniprotAccession" && totalResultRecords == 1){
        url = "./entry/"+resultRecord;
    }else{
      url = "./search/" + category + '/' + this.commonService.escapeValue(resultRecord);
      if(category === 'suggestions') {
        url = './search/text/' + this.commonService.escapeValue(resultRecord) + '?suggested=true';
      }
    }
    return url;
  }

  /**
   * Method to calculate result panel dimenstions.
   * @private
   * @returns {object} object with css style settings
   */
  resultPanelDimenions() {
    let searchBoxDimension = this.el.nativeElement.querySelector('.pdbeAutoCompleteSearchBox').getBoundingClientRect();
    this.resultPanelStyle['max-height'] = (window.innerHeight - searchBoxDimension.bottom - 30) + 'px';
    this.resultPanelStyle['left'] = searchBoxDimension.left + 'px';

    const scrollWidth= window.innerWidth - document.body.offsetWidth;

    this.resultPanelStyle['width'] = (searchBoxDimension.width + scrollWidth) + 'px';

    return this.resultPanelStyle;
  }

  openSearch(searchTerm: string) {
    if(searchTerm.trim() === '') return;
    const checks = new RegExp("^[0-9ACDEFG HIKLMNPQRSTVYW-]{20,}$");
    if (checks.test(searchTerm) && this.hasMoreThanSevenUniqueChars(searchTerm)) {
      // The loop fulfills the sequence string criteria, therefore, the sequence search will take place here.
      this.router.navigate(['search', 'sequence', searchTerm.replace(/\s+/g, '').replace(/[0-9]+/g, '')]);
    } else{
      this.gaService.eventEmitter('search_term', 'search', 'click', searchTerm, undefined);
      this.router.navigate(['search','text', searchTerm]);
      this.panelOpen = false;
    }
  }

  hasMoreThanSevenUniqueChars(searchTerm: string): boolean {
    const validChars = new Set('ACTGBDHKMNRSVWY');
    const uniqueChars = new Set();

    for (let char of searchTerm) {
      if (validChars.has(char)) {
        uniqueChars.add(char);
      }
    }

    return uniqueChars.size > 7;
  }

  replaceEscapedSlash(str: string) {
    return str.replace(/\\[0-9]{2}/g, "/");
  }

  goToWhatsNew(e) {
    e.preventDefault();
    localStorage.setItem("newsSeen", "true");
    this.isNewsSeen = "true";
    this.gaService.eventEmitter(
      'news_link_visit',
      'Whats_new',
      'click',
      'see_updates',
      'Click on See our updates link on main page');
  }

  ngOnInit(): void {
    this.isNewsSeen = localStorage.getItem("newsSeen");
   
    this.changeDetectorRef.detectChanges();
    //this.seenInfo = localStorage.getItem('sequenceInfoSeen');

    // restore the search input text
    this.routeSubscriber = this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        const currentParams = val.state.root.firstChild.params;
        if(currentParams.term) {
          this.el.nativeElement.querySelector('.pdbeAutoCompleteSearchBox').value = decodeURIComponent(currentParams.term);
          this.showClearIcon = true;
          this.changeDetectorRef.detectChanges();
        }
      }
    });

    this.searchTermStream
    .pipe(debounceTime(300),        // wait for 300ms pause in events
      distinctUntilChanged(),
      filter((query: string) =>  {

        if(query?.length > 0) {
          this.showClearIcon = true;
        } else {
          this.showClearIcon = false;
        }

        if(query?.length > 2) {
          return true;
        } else {
          this.panelOpen = false;
          this.changeDetectorRef.detectChanges();
          return false;
        }
      }))   // ignore if next search term is same as previous
      .subscribe(
        term => {

          const auth = this.configService.getConfig().apiKey ? `&key=${this.configService.getConfig().apiKey}` : ""
          const apiurl = `${this.configService.getConfig().suggestApiUrl}?q=${term}${auth}`;
          this.processMessage = 'Loading...';
          this.panelOpen = term ? true : false;
          return term ?
            this.commonService.getApiData(apiurl, { isInternal: true }).subscribe(
              res => {
                this.processMessage = 'No autocomplete suggestions!';
                if(!res || res[1] === 404 || res[1] === 500) {
                  this.suggestions = undefined;
                  this.processMessage = 'No autocomplete suggestions!';
                } else {
                  this.suggestions = res;
                }
                this.changeDetectorRef.detectChanges();
              },
              err => {
                this.processMessage = 'No autocomplete suggestions!';
                this.suggestions = undefined;
                this.changeDetectorRef.detectChanges();
              }
            ) : this.suggestions = undefined;
    },
    error => {
      this.processMessage = 'No autocomplete suggestions!';
      this.changeDetectorRef.detectChanges();
      return this.suggestions = undefined;
    });

  }

  clearSearch() {
    this.searchTermStream.next('');
    const inputEle = this.el.nativeElement.querySelector('.pdbeAutoCompleteSearchBox');
    inputEle.value = '';
    inputEle.focus();
  }

  dismissModal() {
    // localStorage.setItem("sequenceInfoSeen", "true");
    // this.seenInfo = 'true';
    this.gaService.eventEmitter(
      'search_try_seq_click',
      'search',
      'click',
      'seq_search_example',
      'Clicks on the Try it out button on the new sequence similarity search popup');
  }

  ngOnDestroy() {
    this.searchTermStream.unsubscribe();
    this.routeSubscriber.unsubscribe();
  }

}
